import React, { useState } from 'react';
import { Input } from "@nextui-org/react";
import axios from "axios";

const DocumentCard = ({ documentData, setDocumentData, item, idDocs, applicantId}) => {
  const matchedDoc = idDocs.find(doc => 
    doc.idDocType === item.idDocDef.idDocType &&
    doc.country === item.idDocDef.country
  );
  const [localData, setLocalData] = useState({
    firstName: matchedDoc?.firstName || '',
    lastName: matchedDoc?.lastName || '',
    middleName: matchedDoc?.middleName || '',
    idNumber: matchedDoc?.number || '',
    dob: matchedDoc?.dob || '',
    issueDate: matchedDoc?.issueDate || '',
    expiryDate: matchedDoc?.validUntil || '',
    additionalNumber: matchedDoc?.additionalNumber || '',
    beneficiaryName: matchedDoc?.beneficiaryName || '',
    bankName: matchedDoc?.bankName || '',
  });

  const [inputErrors, setInputErrors] = useState({
    firstName: false,
    lastName: false,
    middleName: false,
    idNumber: false,
    dob: false,
    issueDate: false,
    expiryDate: false,
    additionalNumber: false,
    beneficiaryName: false,
    bankName: false,
  });

  const handleInputChange = (field, value) => {
    setLocalData(prevState => ({
      ...prevState,
      [field]: value
    }));
    setInputErrors(prevState => ({
      ...prevState,
      [field]: value.trim() === ''
    }));
  };

  const handleRemove = async () => {
  const data = {
    idDocType: matchedDoc.idDocType,
    country : matchedDoc.country
  }
    try {
      const accessToken = localStorage.getItem('authToken');
      const response = await axios.post(
        `/applicant/delete-id-docs/${applicantId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('handleRemove success', response.data);
  
    } catch (error) {
      console.error('Upload error', error);
    }
  };
  const nonEmptyFields = (data) => {
    const filteredData = {};
    for (const key in data) {
      if (data[key]) {
        filteredData[key] = data[key];
      }
    }
    return filteredData;
  };
  const saveCardDetails = async () => {
    const updatedDocumentData = {
      ...documentData,
      idDocDef: {
        ...item.idDocDef,
        ...localData
      }
    };
    setDocumentData(prevState => {
      return prevState.map(doc => {
        if (doc.id === updatedDocumentData.id) {
          return updatedDocumentData.idDocDef;
        }
        return doc;
      });
    });
  
    // const formData = new FormData();
    const data = {
      country: updatedDocumentData.idDocDef.country,
      idDocType: updatedDocumentData.idDocDef.idDocType,
      idDocSubType: updatedDocumentData.idDocDef.idDocSubType,
      firstName: updatedDocumentData.idDocDef.firstName,
      lastName: updatedDocumentData.idDocDef.lastName,
      middleName: updatedDocumentData.idDocDef.middleName,
      idNumber: updatedDocumentData.idDocDef.idNumber,
      dob: updatedDocumentData.idDocDef.dob,
      issueDate: updatedDocumentData.idDocDef.issueDate,
      expiryDate: updatedDocumentData.idDocDef.expiryDate,
      additionalNumber: updatedDocumentData.idDocDef.additionalNumber,
      beneficiaryName: updatedDocumentData.idDocDef.beneficiaryName,
      bankName: updatedDocumentData.idDocDef.bankName
    };
    const metadata = nonEmptyFields(data)
    // formData.append('metadata', JSON.stringify(metadata));
    try {
      const accessToken = localStorage.getItem('authToken');
      const response = await axios.post(
        `/applicant/add-id-doc/${applicantId}`,
        metadata,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'warning': true,
          },
        }
      );
          console.log('Upload success', response.data);
        } catch (error) {
          console.error('Upload error', error);
        }
      };
  
  

  const renderFields = () => (
    <div>
      <Input
        key={`${matchedDoc?.idDocType}-firstName`}
        radius="sm"
        type="text"
        label="First Name"
        labelPlacement="outside"
        placeholder="First Name"
        value={localData.firstName}
        onChange={(e) => handleInputChange('firstName', e.target.value)}
        className={`pb-2 ${inputErrors.firstName || !localData.firstName ? 'border-red-500' : ''}`}
      />
      <Input
        key={`${matchedDoc?.idDocType}-lastName`}
        radius="sm"
        type="text"
        label="Last Name"
        labelPlacement="outside"
        placeholder="Last Name"
        value={localData.lastName}
        onChange={(e) => handleInputChange('lastName', e.target.value)}
        className={`pb-2 ${inputErrors.lastName || !localData.lastName ? 'border-red-500' : ''}`}
      />
      <Input
        key={`${matchedDoc?.idDocType}-idNumber`}
        radius="sm"
        type="number"
        label={`${matchedDoc?.idDocType} Number`}
        labelPlacement="outside"
        placeholder="ID Number"
        value={localData.idNumber}
        onChange={(e) => handleInputChange('idNumber', e.target.value)}
        className={`pb-2 ${inputErrors.idNumber || !localData.idNumber ? 'border-red-500' : ''}`}
      />
      <Input
        key={`${matchedDoc?.idDocType}-middleName`}
        radius="sm"
        type="text"
        label="Middle Name"
        labelPlacement="outside"
        placeholder="Middle Name"
        value={localData.middleName}
        onChange={(e) => handleInputChange('middleName', e.target.value)}
        className={`pb-2 ${inputErrors.middleName || !localData.middleName ? 'border-red-500' : ''}`}
      />
      <Input
        key={`${matchedDoc?.idDocType}-bankName`}
        radius="sm"
        type="text"
        label="Bank Name"
        labelPlacement="outside"
        placeholder="Bank Name"
        value={localData.bankName}
        onChange={(e) => handleInputChange('bankName', e.target.value)}
        className={`pb-2 ${inputErrors.bankName || !localData.bankName ? 'border-red-500' : ''}`}
      />
      <Input
        key={`${matchedDoc?.idDocType}-beneficiaryName`}
        radius="sm"
        type="text"
        label="Beneficiary Name"
        labelPlacement="outside"
        placeholder="Beneficiary Name"
        value={localData.beneficiaryName}
        onChange={(e) => handleInputChange('beneficiaryName', e.target.value)}
        className={`pb-2 ${inputErrors.beneficiaryName || !localData.beneficiaryName ? 'border-red-500' : ''}`}
      />
      <Input
        key={`${matchedDoc?.idDocType}-additionalNumber`}
        radius="sm"
        type="number"
        label="Additional Number"
        labelPlacement="outside"
        placeholder="Additional Number"
        value={localData.additionalNumber}
        onChange={(e) => handleInputChange('additionalNumber', e.target.value)}
        className={`pb-2 ${inputErrors.additionalNumber || !localData.additionalNumber ? 'border-red-500' : ''}`}
      />
      <Input
        key={`${matchedDoc?.idDocType}-dob`}
        radius="sm"
        type="date"
        label="Date of Birth"
        labelPlacement="outside"
        placeholder="Date of Birth"
        value={localData.dob}
        onChange={(e) => handleInputChange('dob', e.target.value)}
        className={`pb-2 ${inputErrors.dob || !localData.dob ? 'border-red-500' : ''}`}
      />
      <Input
        key={`${matchedDoc?.idDocType}-expiryDate`}
        radius="sm"
        type="date"
        label="Valid Until"
        labelPlacement="outside"
        placeholder="Valid Until"
        value={localData.expiryDate}
        onChange={(e) => handleInputChange('expiryDate', e.target.value)}
        className={`pb-2 ${inputErrors.expiryDate || !localData.expiryDate ? 'border-red-500' : ''}`}
      />
    </div>
  );

  return (
    <div className="p-4 mb-12">
      {renderFields()}
      <div className="flex gap-5 mt-4">
        <button
          onClick={saveCardDetails}
          className={`py-2 px-4 rounded w-full hover:bg-blue-500 hover:text-white border border-blue-500 text-blue-500`}
        >
          Save
        </button>
        <button
          className="bg-red-500 text-white py-2 px-4 rounded w-full"
          onClick={handleRemove}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DocumentCard;
