import React, { useEffect, useState } from "react";
import { FiClipboard, FiPlus } from "react-icons/fi";
import { MdOutlineEdit, MdInfo } from "react-icons/md";
import { GiBackwardTime } from "react-icons/gi";
import {
  IoPhonePortraitOutline,
  IoPlayOutline,
  IoWarningOutline,
  IoCloseSharp
} from "react-icons/io5";
import { VscSettings } from "react-icons/vsc";
import { FaRegUser, FaCheck } from "react-icons/fa6";
import { RiEditBoxLine, RiFlag2Line, RiDeleteBin7Line, RiMapPinLine } from "react-icons/ri";
import { TbProgressCheck } from "react-icons/tb";
import { BsTag , BsLink} from "react-icons/bs";
import { TiDocumentText } from "react-icons/ti";
import { SlScreenDesktop } from "react-icons/sl";
import { IoIosLink, IoMdRefresh } from "react-icons/io";
import { FaRegQuestionCircle, FaEyeSlash, FaCheckCircle, FaTimes  } from "react-icons/fa";
import { GoDash, GoEye } from "react-icons/go";
import { ImCross } from "react-icons/im";
import DocumentCard from "./DocumentCard"
import 'react-medium-image-zoom/dist/styles.css';
import { GrLinkPrevious, GrLinkNext, GrPowerReset, GrNext } from "react-icons/gr";
import { FcCancel } from "react-icons/fc";
import PointerZoom from 'react-pointer-zoom';
import {
  Accordion,
  AccordionItem,
  Button,
  Input,
  Select,
  SelectItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Snippet,
  Textarea,
  Autocomplete,
  AutocompleteItem,
  AutocompleteInput,
} from "@nextui-org/react";
import { animals, resubmissionRequest, columns, imageRequest } from "./data";
import {Select as AntdSelect, Radio, Row, Spin, Table, Tag, Tooltip, Alert, message, Modal as confirmBox } from "antd";
import CountrySelect from "./CountrySelect";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import 'react-photo-view/dist/react-photo-view.css';

const isObjectEmptyOrNull = (obj) => {
  return Object.values(obj).every(value => value === null || (Array.isArray(value) && value.length === 0));
};

const ApplicantInfo = () => {
    //loaders
  const [loader , setLoader] = useState(false);
  const [riskLoading, setRiskLoading] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [personalInfoLoading, setPersonalInfoLoading] = useState(false);
  //
  const [addresses, setAddresses] = useState([]);
  const [countries, setCountries] = useState([]);
  const [holdReviewStatus, setHoldReviewStatus] = useState('');
  const [options, setOptions] = useState([]);
  const [faceMatch, setFaceMatch] = useState(null);
  const [livenessCheck, setLivenessCheck] = useState(null);
  const [duplicates, setDuplicates] = useState(null);
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    legalName: "",
    gender: "",
    dob: "",
    placeOfBirth: "",
    countryOfBirth: "",
    stateOfBirth: null,
    country: "",
    city: "",
    nationality: "",
    taxResidenceCountry: "",
    addresses: [],
    tin: "",
  });
  const [inputErrors, setInputErrors] = useState({
    firstName: false,
    lastName: false,
    middleName: false,
    number: false,
    dob: false,
    issueDate: false,
    expiryDate: false,
    additionalNumber: false,
    beneficiaryName: false,
    bankName: false,
    passportNumber: false,
  });
  const [documentData, setDocumentData] = useState([
    {
      idDocType: '',
      idDocSubType: '',
      nationality: '',
    },
  ]);
  const [profileData,setProfileData]=useState({
    applicantId: "",
    externalUserId: "",
    inspectionId: "",
    createdAt: "",
    createdFor: "",
    source: "",
    screened: "",
    email: "",
    phone: "",
    language: "",
    entity: "",
  })
  const [summary ,setSummary] =useState({
    reviewDate:"",
    liveness:"",
    match:"",
    reviewCount : "",
    quality: ""
  })
  const accessToken = localStorage.getItem("authToken");
  const [selectedImageCategory, setSelectedImageCategory] = useState(null);
  const [selectedImageItems, setSelectedImageItems] = useState({});
  const [selectedImageMessage, setSelectedImageMessage] = useState("");
  const [reviewStatus, setReviewStatus] =useState()
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedMessage, setSelectedMessage] = useState("");
  const [selectedInternalNote, setSelectedInternalNote] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [link, setLink] = useState();
  const [riskLevel, setRiskLevel] = useState(null);
  const [riskComment, setRiskComment] = useState("");
  // const [image, setImage] = useState();
  const [imageData, setImageData] = useState(null);
  const [activation, setActivation] = useState(true);
  // const [entity, setEntity] = useState("");
  const [events, setEvents] = useState([]);
  const [getRiskLevel, setGetRiskLevel] = useState([]);
  const sortedData = [...getRiskLevel].sort(
    (a, b) => b.timestamp - a.timestamp
  );
  ////////
  const location = useLocation();
  const { state } = location;
  let { applicant } = state;
  const navigate = useNavigate();
  const risk = [
    {
      key: "unknown",
    },
    {
      key: "low",
    },
    {
      key: "medium",
    },
    {
      key: "high",
    },
  ];
  useEffect(() => {
    getSummmary();
    getImages();
    getDuplicates();
    fetchCountries();
    if (applicant.id) {
      getProfileInfo();
      // getRejectionReason();
      getRiskLevels();
    }
    if(profileData.inspectionId){
      handleEvent();
    }
  }, []);

    const [photoFiles, setPhotoFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [imageCountries, setImageCountries] = useState([])
    const [imageType, setImageType] = useState([])
    const handleFileChange = (event, type) => {
      const file = event.target.files[0];
      if (!file) return;
  
      const reader = new FileReader();
      
      reader.onload = () => {
        const newPreviews = [...previews, { url: reader.result, name: file.name, size: file.size }];
        setPreviews(newPreviews);
        setImageCountries([...imageCountries, ""]);
        setImageType([...imageType, ""]);
        setPhotoFiles([...photoFiles, file]);
      };
      
      reader.readAsDataURL(file);
    };
  
    // Update handleUpload function
const handleUpload = async (index) => {
  const file = photoFiles[index];
  if (!file) return;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('metadata', JSON.stringify({ 
    country: imageCountries[index], 
    idDocType: imageType[index] 
  }));
  try {
    const accessToken = localStorage.getItem('authToken');
    const response = await axios.post(
      `/applicant/add-id-docs/${applicant.id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
          'warning': true,
        },
      }
    );
    console.log('Upload success', response.data);

    setPreviews((prev) => prev.filter((_, i) => i !== index));
    setPhotoFiles((prev) => prev.filter((_, i) => i !== index));
    setImageCountries((prev) => prev.filter((_, i) => i !== index));
    setImageType((prev) => prev.filter((_, i) => i !== index));
  } catch (error) {
    console.error('Upload error', error);
  }
};
    const handleCountryChange = (index, value) => {
      const newCountries = [...imageCountries];
      newCountries[index] = value;
      setImageCountries(newCountries);
    };
    const handleTypeChange = (index, value) => {
      const newTypes = [...imageType];
      newTypes[index] = value;
      setImageType(newTypes);
    };
  
    const handleCancel = (index) => {
      const newPreviews = previews.filter((_, i) => i !== index);
      setPreviews(newPreviews);
  
      const newPhotoFiles = photoFiles.filter((_, i) => i !== index);
      setPhotoFiles(newPhotoFiles);
  
      const newImageCountries = imageCountries.filter((_, i) => i !== index);
      setImageCountries(newImageCountries);
  
      const newImageTypes = imageType.filter((_, i) => i !== index);
      setImageType(newImageTypes);
    };
  const handleEvent = async () => {
    try {
      setEventLoading(true);

      const accessToken = localStorage.getItem("authToken");
      const getEvents = await axios.get(
        `/applicant/${applicant.id}/get-events/${profileData.inspectionId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setEvents(getEvents.data);
      const deviceName = navigator.userAgent;
      const regex = /\(([^;]+); ([^)]+)\)/;
      const matches = deviceName.match(regex);
      const deviceInfo =
        matches && matches.length === 3 ? `${matches[1]} ${matches[2]}` : null;
    } catch (error) {
    } finally {
      setEventLoading(false);
    }
  };

  const refreshEvents = () => {
    handleEvent();
  };

  const getRiskLevels = async () => {
    try {
      const accessToken = localStorage.getItem("authToken");
      const response = await axios.get(
        `/applicant/risk-level/${applicant.id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setGetRiskLevel(response.data);
      // console.log("getRiskLevel", getRiskLevel)
    } catch (error) {}
  }; 
  const handleReset = async () => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(
        `applicant/reset-profile/${applicant.id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Reset Profile Done");
    } catch (error) {}
  };
  const handleInactive = async (status) => {
    // setStatusLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.patch(
        `applicant/activation-switch/${applicant.id}`,
        { status: status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setStatusLoading(false);
      setActivation(!response.data?.deleted);
    } catch (error) {}
  };
  const handleRiskLevel = async () => {
    setRiskLoading(true);
    try {
      const accessToken = localStorage.getItem("authToken");
      await axios.post(
        `/applicant/risk-level/${applicant.id}`,
        { level: riskLevel, comment: riskComment },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setRiskLoading(false)
      setRiskLevel("")
      setRiskComment("");
    } catch (error) { }
  };
  const fetchCountries = async () => {
    try {
      const response = await fetch("https://restcountries.com/v3.1/all");
      const data = await response.json();
      const countriesData = data?.map((country) => ({
        label: country.name.common,
        key: country.cca3,
      }));
      const filteredCountries = countriesData.filter(
        (country) => !animals.find((animal) => animal.label === country.label)
      );
      setCountries(filteredCountries);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const getProfileInfo = async () => {
    try {
      const accessToken = localStorage.getItem("authToken");
      const profile = await axios.get(
        `/applicant/get-applicant/${applicant.externalId}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (profile.data?.deleted) {
        setActivation(profile.date?.deleted);
      }
      setProfileLoading(profile.data ? false : '')
      setPersonalInfoLoading(profile.data ? false : '')
      const response = profile.data?.info;
      console.log("res", profile.data);
      setHoldReviewStatus(profile.data.review.reviewStatus)
      setPersonalInfo({
        firstName: response.firstName,
        lastName: response.lastName,
        middleName: response.middleName,
        legalName: response.legalName,
        gender: response.gender,
        dob: response.dob,
        phone: response.phone,
        placeOfBirth: response.placeOfBirth,
        countryOfBirth: response.countryOfBirth,
        stateOfBirth: response.stateOfBirth,
        country: response.country,
        taxResidenceCountry: response.taxResidenceCountry,
        nationality: response.nationality,
        addresses: response.addresses,
        tin: response.tin,
        idDocs: response.idDocs,
      });
      if (response.idDocs) {
        const updatedDocumentData = response.idDocs
        setDocumentData(updatedDocumentData);
      }
      if(response.addresses){
        console.log("response.addresses",response.addresses)
        setAddresses(response.addresses);
      }
      const responseData =profile.data
      setReviewStatus(responseData.review)
      setProfileData({
        applicantId: responseData?.id,
        externalUserId: responseData?.externalUserId,
        inspectionId: responseData?.inspectionId,
        createdAt: responseData?.createdAt,
        createdFor: responseData?.clientId,
        source: responseData?.source,
        screened: responseData?.review?.createDate,
        email: responseData?.email,
        phone: responseData?.phone,
        language: responseData?.lang,
        entity: responseData?.type,
      })
      setOptions(responseData?.tags)
      } catch (error) {}
  };
  const removeNullValues = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== null)
    );
  };

  const handlePersonalInfo = async () => {
    setPersonalInfoLoading(true)
    try {
      const config = removeNullValues(personalInfo);

      const accessToken = localStorage.getItem("authToken");
      await axios.patch(`/applicant/update-info/${applicant.id}`, config, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      setPersonalInfoLoading(false);
    } catch (error) {}
  };


  const handleGenerateLink = async () => {
    console.log(`${process.env.REACT_APP_BK_BASE_URL}`);

    const sdkLink = `${process.env.REACT_APP_SDK_LINK}/${applicant.externalId}/${applicant.level}`;
    setLink(sdkLink);
    onOpen();
  };
  const handlApplicantSummary = () => {
    navigate("/summary", { state: { applicant, events } });
  };
  const handleSdkPreview = () => {
    const sdkLink = `${process.env.REACT_APP_SDK_LINK}/${applicant.externalId}/${applicant.level}`;
    window.open(sdkLink, '_blank');
  };
  const handleProfileData = async () => {
    setProfileLoading(true);
    try {
      const accessToken = localStorage.getItem("authToken");

      await axios.patch(
        `/applicant/top-level-info/${applicant.id}`,
        { email :profileData.email, phone :profileData.phone, lang: profileData.language },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setProfileLoading(false);
    } catch (error) {}
  };
  const handleGenderChange = (e) => {
    setPersonalInfo({ ...personalInfo, gender: e.target.value });
  };
  const addAddress = () => {
    setAddresses([...addresses, { street: "", streetEn: "", city: "", state: "", stateEn: "", country: "" }]);
  };
  const testData = () => {
  console.log("TestData Link Clicked")
  };
  const handlePersonalInfoChange = (e, type) => {
    const value = e.target.value;
    setPersonalInfo({ ...personalInfo, [type]: value });
    if (value) {
      setInputErrors({ ...inputErrors, [type]: false });
    }
  };
  const handleAddressChange = (index, field, value) => {
    const newAddresses = [...addresses];
    newAddresses[index][field] = value;
    setAddresses(newAddresses);
  }; 
  const showMap =(index)=>{
    console.log(`show Map at ${index} index Clicked`)
  }
  const removeAddress = (index) => {
    confirmBox.confirm({
      title: 'Confirm Deletion',
      content: 'Are you sure you want to delete this address?',
      okText: 'OK',
      okButtonProps: { style: { color: 'black' } },
      cancelText: 'Cancel',
      cancelButtonProps: { style: { color: 'black' } },
      onOk() {
        const newAddresses = [...addresses];
        newAddresses.splice(index, 1);
        setAddresses(newAddresses);
        apiRemoveAddress(newAddresses); 
      },
      onCancel() {
        console.log('Deletion canceled.');
      },
    });
  };
  const apiRemoveAddress = async (updatedAddresses) => {
    try {
      const payload = {
        firstName: applicant.firstName,
        lastName: applicant.lastName,
        middleName: applicant.middleName,
        addresses: updatedAddresses,
      };
      const response = await axios.patch(
        `/applicant/change-applicant-info/${applicant.id}`,
        payload,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("removeAddress --> response", response);
    } catch (error) {
      console.error("Error removing address:", error);
    }
  };

  const handleDocumentTypeChange = (value, index) => {
    const updatedImages = [...imageData];
    updatedImages[index].idDocDef.idDocType = value;
    setImageData(updatedImages);
  };

  const handleDocumentSideChange = (value, index) => {
    const updatedImages = [...imageData];
    updatedImages[index].idDocDef.idDocSubType = value;
    setImageData(updatedImages);
  }; 
  const handleImageCountryChange = (value, index) => {
    const updatedImages = [...imageData];
    updatedImages[index].idDocDef.country = value;
    setImageData(updatedImages);
  };
  const handleNationalityChange = (value, index) => {
    const updatedImages = [...imageData];
    updatedImages[index].idDocDef.country = value;
    setImageData(updatedImages);
  };
  const handleProfileDataChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };
  const handleLanguageChange = (value) => {
    setProfileData({
      ...profileData,
      language: value,
    });
  };
  const manualCheck = async ()=>{
   try{
    const accessToken = localStorage.getItem("authToken");

      const response = await axios.post(
        `/applicant/holdReviewStatus/${applicant.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
     if(response.status ===200 ){
      setHoldReviewStatus('onHold');
     }

   }catch (error){

   }
  }    
  const declineReview = async (selectedKeys,selectedMessage,selectedInternalNote)=>{
   try{
    const accessToken = localStorage.getItem("authToken");
    let type = "RETRY"; 
    const hasRejectType = Object.keys(selectedItems).some(category => resubmissionRequest[category].type === "FINAL");
    if (hasRejectType) {
      type = "FINAL";
    }
    const data= {
      reviewAnswer: 'RED',
      reviewRejectType: type,
      rejectLabels: selectedKeys,
      clientComment : selectedMessage,
      moderationComment: selectedInternalNote
    }
      const response = await axios.post(
        `/applicant//declineReviewStatus/${profileData.inspectionId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
     if(response.status ===200 ){
      setHoldReviewStatus('completed');
     }

   }catch (error){

   }
  }  
  const ApproveReview = async ()=>{
   try{
    const data = { reviewAnswer:"GREEN" }
    const accessToken = localStorage.getItem("authToken");

      const response = await axios.post(
        `/applicant/approveReviewstatus/${applicant.externalId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
     if(response.status ===200 ){
      setHoldReviewStatus('completed');
     }

   }catch (error){

   }
  }
  const saveDocument = async (index, imageId) => {
    const documentToSave = {
      imageId: imageData[index].imageId,
      idDocType: imageData[index].idDocDef.idDocType,
      idDocSubType: imageData[index].idDocDef.idDocSubType,
      country: imageData[index].idDocDef.country,
    };
  
    try {
      const response = await axios.post(
        `/applicant/${applicant.externalId}/id-doc-def/${imageId}`,
        documentToSave,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const savedDocType = response.data.data;
      setSavedDocTypes((prev) => [...prev, savedDocType]);
    } catch (error) {
      console.error("Error saving document", error);
    }
  };
  
  const [savedDocTypes, setSavedDocTypes] = useState([]);
  const compareSelfie = async (index ,imageId) => {
    const response = await axios.post(
      `/applicant/${applicant.externalId}/face-match-with-selfie/${imageId}`,
      null,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
      if (response.status === 200) {
        message.success('Selfie comparison successful');
      } else {
        message.error('Selfie comparison failed');
      }
  }; 
  const flipImage = async (index ,imageId, modificationType, rotationDegree) => {
      const data ={
        modificationType : modificationType,
        rotationDegree : rotationDegree
       }
    await axios.post(
      `/applicant/${applicant.externalId}/modify-image/${imageId}`,
     data,
     {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
    );
      getImages()
  };  
  const saveAddress = async (index) => {
    const updatedAddresses = [...addresses];
    const updatedAddress = updatedAddresses[index];
    updatedAddress.stateEn = `${updatedAddress.street}`; 
    updatedAddress.subStreetEn = `${updatedAddress.subStreet}`; 
    updatedAddress.townEn = `${updatedAddress.town}`; 
    updatedAddress.formattedAddress = `${updatedAddress.street}, ${updatedAddress.subStreet}, ${updatedAddress.town}, ${updatedAddress.state}, ${updatedAddress.country}, ${updatedAddress.postCode}`; 
    updatedAddresses[index] = updatedAddress;
    try {
      const payload = {
        firstName: applicant.firstName,
        lastName: applicant.lastName,
        middleName: applicant.middleName,
        addresses: updatedAddresses,
      };
      const response = await axios.patch(
        `/applicant/change-applicant-info/${applicant.id}`,
        payload,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("saveAddress --> response", response);
    } catch (error) {
      console.error("Error saving address:", error);
    }
  };
  const revertStatus = async (index, imageId, revert) => {
    try {
      await axios.delete(
        `/applicant/${imageId}/reset-revert/${profileData.inspectionId}`,
        {
          params: {
            revert: revert ? "true" : "false" 
          }
        },
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.error("Error reverting status:", error);
    }
  };

  const getSummmary = async () => {
    try {
      const summaryData = await axios.get(`/applicant/summary/${applicant.id}`,{
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      setSummary({
        reviewCount: summaryData.data.inspectionReview.attemptCnt,
        liveness: summaryData.data.checks.FACE_LIVELINESS.answer,
        match: summaryData.data.checks.FACE_MATCH.answer,
        reviewDate: summaryData.data.inspectionReview.createDate,
        quality: summaryData.data.checks.AUTO_CHECK.answer
      })
    } catch (error) {}
  };
  const getImages = async () => {
    setImageLoading(true);
    try {
      const response = await axios.get(
        `/applicant/get-images/${applicant.externalId}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setFaceMatch(response.data.checks.faceMatch)
      const liveness = response.data.checks.liveness[0];
      setLivenessCheck(liveness);      
      setImageData(response.data.images)
    } catch (error) {
      console.error("error getImages",error)
    }
    setImageLoading(false);

  };  
  const getDuplicates = async () => {
    try {
      const response = await axios.get(
        `/applicant/get-duplicates/${applicant.id}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setDuplicates(response.data.similarApplicants)
    } catch (error) {
      console.error("error getDuplicates",error)
    }
  };
  const redirectToPage = (applicant) => {
    window.open("/applicantinfo", "_blank");
  };
  const [previewImage, setPreviewImage] = useState(null);
  const handleClosePreview = () => {
    setPreviewImage(null);
    console.log("handleClosePreview")

    setPreviewImageIndex(null);
  };
  const [previewImageIndex, setPreviewImageIndex] = useState(null);

  const handleImageClick = (image,index) => {
    console.log("handleImageClick",index,image)

    setPreviewImage(image);
    setPreviewImageIndex(index);
  };

  const handleNextImage = () => {
    console.log("handleNextImage",previewImageIndex)
    if (previewImageIndex !== null && previewImageIndex < imageData.length - 1) {
      setPreviewImageIndex(previewImageIndex + 1);
    }
  };

  const handlePreviousImage = () => {
    console.log("handlePreviousImage",previewImageIndex)

    if (previewImageIndex !== null && previewImageIndex > 0) {
      setPreviewImageIndex(previewImageIndex - 1);
    }
  };
  
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleItemSelect = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const categoryItems = prevSelectedItems[selectedCategory] || [];
      let newSelectedItems;

      if (categoryItems.includes(item)) {
        // Deselect item
        newSelectedItems = {
          ...prevSelectedItems,
          [selectedCategory]: categoryItems.filter((i) => i !== item),
        };

        // Remove item's message from selectedMessage and internalMessage from selectedInternalNote
        const categoryObject = resubmissionRequest[selectedCategory];
        if (categoryObject) {
          const selectedItem = categoryObject.items.find((i) => i.label === item);
          if (selectedItem) {
            setSelectedMessage((prevMessage) =>
              prevMessage.replace(selectedItem.message || `Enter Your ${selectedItem.label}`, '')
            );
            setSelectedInternalNote((prevInternalNote) =>
              prevInternalNote.replace(
                selectedItem.internalMessage || `${selectedItem.label} is required in internal message`,
                ''
              )
            );
          }
        }
      } else {
        // Select item
        newSelectedItems = {
          ...prevSelectedItems,
          [selectedCategory]: [...categoryItems, item],
        };

        // Add item's message to selectedMessage and internalMessage to selectedInternalNote
        const categoryObject = resubmissionRequest[selectedCategory];
        if (categoryObject) {
          const selectedItem = categoryObject.items.find((i) => i.label === item);
          if (selectedItem) {
            setSelectedMessage((prevMessage) =>
              prevMessage
                ? prevMessage + `, ${selectedItem.message || `Enter Your ${selectedItem.label}`}`
                : selectedItem.message || `Enter Your ${selectedItem.label}`
            );
            setSelectedInternalNote((prevInternalNote) =>
              prevInternalNote
                ? prevInternalNote +
                  `, ${selectedItem.internalMessage || `${selectedItem.label} is required in internal message`}`
                : selectedItem.internalMessage || `${selectedItem.label} is required in internal message`
            );
          }
        }
      }

      return newSelectedItems;
    });
  };
  
  
  const [hasSelectedItems, setHasSelectedItems] = useState(Object.keys(selectedItems).length === 0);

  useEffect(() => {
    setHasSelectedItems(Object.keys(selectedItems).length === 0);
  }, [selectedItems]);

  const handleBackToCategories = () => {
    if (isObjectEmptyOrNull(selectedItems)) {
      setSelectedItems({});
    }
    const currentMessage = selectedMessage;
    const currentInternalNote = selectedInternalNote;
    setSelectedMessage(currentMessage);
    setSelectedInternalNote(currentInternalNote);
    setSelectedCategory(null);
  };

  const handleInputChange = (e, type) => {
    if (type === "message") {
      setSelectedMessage(e.target.value);
    } else if (type === "internalNote") {
      setSelectedInternalNote(e.target.value);
    }
  };

  const handleActionClick = () => {
    const selectedKeys = Object.keys(selectedItems).map(category => resubmissionRequest[category].key);
    if (holdReviewStatus === "completed") {
      manualCheck()
    } else if (holdReviewStatus === 'onHold' && hasSelectedItems) {
      ApproveReview()
    } else {
      declineReview(selectedKeys,selectedMessage,selectedInternalNote)
      console.log("selectedKeys",selectedKeys)
    }
  };
  const getRejectionReason = async () => {
    try {
      const response = await axios.get(
        `/verification/rejection-reason/${applicant.id}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("getRejectionReason", response.data)
    } catch (error) {
      console.error("error getRejectionReason",error)
    }
  };
  const handleImageActionClick = (index) => {
    // const selectedKeys = Object.keys(selectedItems).map(category => resubmissionRequest[category].key);
    // if (holdReviewStatus === 'onHold' && hasSelectedItems) {
      const data = {
        category: selectedImageCategory[index],
        items: selectedImageItems[index][selectedImageCategory[index]],
        message: selectedImageMessage
      };
      console.log('Action Data:', data);
    // } else {
      // declineReview(selectedKeys,selectedMessage,selectedInternalNote)
      // console.log("selectedKeys",selectedKeys)
    // }
  };
  const handleImageCategorySelect = (index, category) => {
    setSelectedImageCategory(prev => ({ ...prev, [index]: category }));
  };

  const handleImageItemSelect = (index, item) => {
    setSelectedImageItems(prev => ({
      ...prev,
      [index]: {
        ...prev[index],
        [selectedImageCategory[index]]: prev[index]?.[selectedImageCategory[index]]?.includes(item)
          ? prev[index][selectedImageCategory[index]].filter(i => i !== item)
          : [...(prev[index]?.[selectedImageCategory[index]] || []), item]
      }
    }));
  };

  const handleImageBackToCategories = (index) => {
    setSelectedImageCategory(prev => ({ ...prev, [index]: null }));
  };

  const handleImageInputChange = (e, field) => {
    setSelectedImageMessage(e.target.value);
  };
  
  return (
    <>
      {loader ? (
        <div className="flex items-center justify-center py-16">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className="p-6 bg-white rounded-lg">
            <div className="flex max-md:flex-col items-center gap-3">
              <h1 className="text-xl max-md:text-base font-bold">
              {(personalInfo.firstName || personalInfo.lastName) && (
                  <div className="">
                    {personalInfo.firstName} {personalInfo.lastName}
                  </div>
               )}
                {(!personalInfo.firstName && !personalInfo.lastName )&& 
              <div> ID: <span>{applicant.id}</span></div>                
                }
              </h1>
              <span className="p-1 px-2 bg-[#E1E5EC] rounded-lg text-xs mb-3">
                Documents requested (a day)
              </span>
            </div>
            <p className="text-[#586073] max-md:text-center">
              {applicant.clientId || "ignition_innovation.com_76517"}
            </p>
          </div>
          <div className="py-5 flex items-center px-4 gap-6">
            {/* <Link to='/summary'> */}
            <div
              className="text-[#19BC9B] flex gap-2 items-center font-bold cursor-pointer"
              onClick={handlApplicantSummary}
            >
              <FiClipboard />
              <span>Applicant summary</span>
            </div>
            <div
              className="text-[#19BC9B] flex gap-2 items-center font-bold cursor-pointer"
              onClick={handleSdkPreview}
            >
              <GoEye />
              <span>WebSDK Preview</span>
            </div>
            {/* </Link> */}
            <button
              onClick={handleGenerateLink}
              className="text-[#19BC9B] flex gap-2 items-center font-bold cursor-pointer"
            >
              <BsLink />
              <span>Generate applicant link</span>
            </button>
          </div>
          <Modal
            className="max-w-xl"
            placement="top"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
          >
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    WebSDK permalink
                  </ModalHeader>
                  <ModalBody>
                    <Snippet className="snippet-scroll">{link}</Snippet>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" variant="light" onPress={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
          <div className="bg-[#902dc8] text-white flex justify-between p-5 rounded-2xl">
            <div>
              <h2 className="mb-5 font-semibold">
                Applicant level: {applicant.level}
              </h2>
              <div className="flex max-md:flex-col gap-10 w-4/5">
                <div className="grid gap-2">
                  <div className="flex gap-2 items-center">
                    <div className="w-3 h-3 bg-white rounded-full"></div>
                    <span>Identity document</span>
                  </div>
                  <p className="text-[#ccced1]">
                    ID card, Passport, Residence permit, Driver's license
                  </p>
                </div>
                <div className="grid gap-2">
                  <div className="flex gap-2 items-center">
                    <div className="w-3 h-3 bg-white rounded-full"></div>
                    <span>Selfie</span>
                  </div>
                  <p className="text-[#ccced1]">Selfie</p>
                </div>
              </div>
            </div>
            <div className="bg-white bg-opacity-20 p-2 h-fit rounded-full cursor-pointer">
              <MdOutlineEdit className="text-2xl" />
            </div>
          </div>
          <div className="m-4">
            <span className="mt-5 border-b-2 border-[#19BC9B] text-lg font-semibold">
              Applicant Data
            </span>
          </div>
          <div className="flex max-md:flex-col gap-10">
            <div className="w-3/5 max-md:w-full ">
              <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion defaultExpandedKeys={["Events"]}>
                  <AccordionItem
                    key="1"
                    aria-label="events"
                    startContent={
                      <GiBackwardTime className="text-black text-2xl" />
                    }
                    title="Events"
                  >
                   <div className="">
                   <div
                      className="flex justify-end mb-5 px-2 cursor-pointer"
                      onClick={refreshEvents}
                    >
                      <IoMdRefresh className="text-lg" />
                    </div>
                    {eventLoading ? (
                      <Spin size={"large"}  className="flex w-full items-center justify-center mb-6"/>
                    ) : 
                        <>
                          {events.items ? (
                            events.items?.map((event, index) => {
                              event.status =
                                event.status === "activated"
                                  ? "Document data Added"
                                  : event.status;
                              event.status =
                                event.status === "deactivated"
                                  ? "Document data Deleted"
                                  : event.status;
                              event.status =
                                event.status === "init"
                                  ? "Documents requested"
                                  : event.status;
                              return (
                              <div className={`grid grid-cols-3 gap-8 items-start justify-between px-10 pb-8 ${event.status ? '' : 'hidden'} ${event.imageBase64 ? 'p-2 border border-black' : ''}`}>
                                <div className="flex gap-2 items-start">
                                  <div className="rounded-full">
                                    { event.imageBase64 &&   
                                   <img className="w-16 cursor-pointer" src={`data:image/jpeg;base64,${event.imageBase64}`} alt="eventImage"></img>   
                                    } 
                                  </div>
                                 { !event.imageBase64 && <div className="flex flex-col">
                                  <span className="font-semibold">
                                    {index === 0 && <div className="text-[14px] text-[#6b758b99]">Current Status</div>}  {event.status}
                                    </span>
                                    {index !== 0 && <p className="text-[#6b758b99] text-[14px]">
                                      {event.idDocType}<br/>
                                      {event.country}
                                    </p>}
                                  </div>}
                                  {event.imageBase64 && 
                                  <div className="flex flex-col">
                                  <div className="font-semibold">
                                    {event.idDocType}
                                  </div>
                                      <div className="text-[#6b758b99] text-[14px]">
                                        {event.imageId}
                                      </div>
                                      </div>
                                    }
                                </div>
                                <div className="text-[#6b758b99] text-[14px]">
                                  <p>{event.subjectName}</p>
                                  <p>{event.ts}</p>
                                </div>
                                <div className="flex gap-2">
                                  {event.userAgentInfo.uaPlatform !== 'Unknown' && 
                                  <div className="flex gap-2">
                                    <SlScreenDesktop className=" mt-1" />
                                    <div>
                                      <p>{event.userAgentInfo?.uaPlatform}-{event.userAgentInfo?.uaBrowser}</p>
                                      <p>{event.ipInfo?.countryCode2}{event.ipInfo?.countryCode3},{event.ipInfo?.city}</p>
                                      <p>{event.ipInfo?.ip}</p>
                                    </div>
                                  </div>
                                    }
                                </div>
                              </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </>
                    }
                   </div>
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion defaultExpandedKeys={["1"]}>
                  <AccordionItem
                    key="1"
                    aria-label="data"
                    startContent={<FaRegUser className="text-black text-2xl" />}
                    title="Profile Data"
                  >
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                      <Input
                        name="applicantId"
                        key="1"
                        radius="sm"
                        type="text"
                        label="ID"
                        labelPlacement="outside"
                        placeholder="Enter ID"
                        value={profileData.applicantId}
                        onChange={handleProfileDataChange}
                      />
                      <Input
                        name="createdFor"
                        key="2"
                        radius="sm"
                        type="text"
                        label="Created for"
                        labelPlacement="outside"
                        placeholder="Created for"
                        value={profileData.createdFor}
                        onChange={handleProfileDataChange}
                      />
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                      <Input
                        name="inspectionId"
                        key="3"
                        radius="sm"
                        type="text"
                        label="Inspection ID"
                        labelPlacement="outside"
                        placeholder="Enter Inspection ID"
                        value={profileData.inspectionId}
                        onChange={handleProfileDataChange}
                      />
                      <Input
                        name="externalUserId"
                        key="4"
                        radius="sm"
                        type="text"
                        label="External user ID"
                        labelPlacement="outside"
                        placeholder="Externel User ID"
                        value={profileData.externalUserId}
                        onChange={handleProfileDataChange}
                      />
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                      <Input
                        name="createdAt"
                        key="5"
                        radius="sm"
                        type="text"
                        label="Date of creation"
                        labelPlacement="outside"
                        placeholder="Date of creation"
                        value={profileData.createdAt}
                        onChange={handleProfileDataChange}
                      />
                      <Input
                        name="screened"
                        key="6"
                        radius="sm"
                        type="text"
                        label="Last screened"
                        labelPlacement="outside"
                        placeholder="Last Screened"
                        value={profileData.screened}
                        onChange={handleProfileDataChange}
                      />
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                      <Input
                        name="entity"
                        key="7"
                        radius="sm"
                        type="text"
                        label="Entity type"
                        labelPlacement="outside"
                        placeholder="Entity Type"
                        value={profileData.entity}
                        onChange={handleProfileDataChange}
                      />
                      <Input
                        name="source"
                        key="8"
                        radius="sm"
                        type="text"
                        labelPlacement="outside"
                        label="Source key from client"
                        placeholder="Select options"
                        value={profileData.source}
                        onChange={handleProfileDataChange}
                      />
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                      <Input
                        name="email"
                        key="9"
                        radius="sm"
                        type="email"
                        label="Email"
                        labelPlacement="outside"
                        placeholder="Enter Email"
                        value={profileData.email}
                        onChange={handleProfileDataChange}
                      />
                      <Input
                        name="phone"
                        key="10"
                        radius="sm"
                        type="phone"
                        label="Phone"
                        labelPlacement="outside"
                        placeholder="Enter Phone no."
                        value={profileData.phone}
                        onChange={handleProfileDataChange}
                      />
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-8">
                      <AntdSelect
                        label="Language"
                        placeholder="Search Language"
                        labelPlacement="outside"
                        className="mb-3 w-full"
                        value={profileData.language}
                        onChange={(value) => handleLanguageChange(value)}
                      >
                        {animals?.map((animal) => (
                          <option value={animal.value}>{animal.label}</option>
                        ))}
                      </AntdSelect>
                    </div>
                    {profileLoading ? (
                      <div className="flex justify-center py-4">
                        <Spin />
                      </div>
                    ) : (
                      <Button
                        onClick={handleProfileData}
                        className="w-full bg-[#8103CE] text-white rounded mb-10"
                        color="#8103CE"
                      >
                        Save
                      </Button>
                    )}
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion>
                  <AccordionItem
                    key="1"
                    aria-label="risk"
                    startContent={
                      <VscSettings className="text-black text-2xl" />
                    }
                    title="Risk Event Adjustment"
                  >
                    <label className="pb-3">
                      Adjust Risk Level for the profile
                    </label>
                    <div>
                      {sortedData.length === 0 ? (
                        <></>
                      ) : (
                        <div className="mt-3">
                          <Table
                            dataSource={sortedData}
                            columns={columns}
                            pagination={{ pageSize: 3 }}
                          />
                        </div>
                      )}
                    </div>
                    <Textarea
                      label=" "
                      placeholder="Describe here a reason..."
                      variant="bordered"
                      className="max-w-full bg-white mb-3"
                      value={riskComment}
                      onChange={(e) => setRiskComment(e.target.value)}
                      isRequired
                    />
                    <Select
                      key="21"
                      labelPlacement="Select"
                      placeholder="Unknown"
                      className="my-3"
                      radius="sm"
                      onChange={(e) => setRiskLevel(e.target.value)}
                    >
                      {risk?.map((risk) => (
                        <SelectItem key={risk.key} value={risk.key}>
                          {risk.key}
                        </SelectItem>
                      ))}
                    </Select>
                    {riskLoading ? (
                      <div className="p-4">
                        <Spin />
                      </div>
                    ) : (
                      <Button
                        onClick={handleRiskLevel}
                        radius="sm"
                        color="secondary"
                        className="mb-3 mt-2"
                      >
                        Submit
                      </Button>
                    )}
                  </AccordionItem>
                </Accordion>
              </div>

              <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion>
                  <AccordionItem
                    key="1"
                    aria-label="risk"
                    startContent={
                      <RiEditBoxLine className="text-black text-2xl" />
                    }
                    title="Add Documents and Details"
                  >
                    <div>
                      <div className="bg-[#f6f7f9] flex gap-2 p-4">
                        <MdInfo className="!text-3xl "/>
                        <p>Use this <span className="text-[#62a0ea] hover:underline cursor-pointer"onClick={testData}> test applicant data</span> to generate a test applicant and see how it works</p>
                      </div>
                      <div className="grid grid-cols-3 max-md:grid-cols-1 gap-5 mb-5 mt-8">
                        <div className="relative">
                          <button className="flex cursor-pointer items-center py-2 justify-center w-full bg-[#8103CE] text-white gap-2 font-bold rounded">
                            <FiPlus className="text-2xl" /> Add Photos
                          </button>
                          <input
                            type="file"
                            className="absolute inset-0 opacity-0"
                            onChange={(event) =>
                              handleFileChange(event, "photo")
                            }
                          />
                        </div>
                        <div className="relative">
                          <button className="flex items-center py-2 justify-center w-full bg-[#8103CE] text-white gap-2 font-bold rounded">
                            <FiPlus className="text-2xl" /> Add Documents
                          </button>
                        </div>
                        <div className="relative">
                          <button className="flex items-center py-2 justify-center w-full bg-[#8103CE] text-white gap-2 font-bold rounded" onClick={addAddress}>
                            <FiPlus className="text-2xl"/> Add Address
                          </button>
                        </div>
                      </div>
                      {previews.some(Boolean) && (
                        <div className="px-5">
                          {previews?.map((preview, index) => (
                            <div key={index} className="flex">
                              {preview && (
                                <div className="w-full mb-2 ml-2">
                                  <PointerZoom
                                      className="lg:w-48 cursor-pointer"
                                      size={400}
                                      image={{
                                          src: `${preview.url}`,   
                                      }}
                                      zoomImage={{
                                          src: `${preview.url}`,
                                          width: 1024,
                                          height: 1024
                                      }}
                                      placeholderZoomImageSrc= {`Preview  ${index + 1}`}
                                   />
                                <div className="flex flex-col"><span>File Name: {preview.name}</span><span>Size: ({Math.round(preview?.size / 1024)}) KB</span></div>
                                </div>
                              )}
                              {preview && (
                                <div className="px-4">
                                  <div>
                                  <Input
                                    key={`country-${index}`}
                                    radius="sm"
                                    type="text"
                                    label="Country"
                                    className="mb-5"
                                    labelPlacement="outside"
                                    placeholder="Country"
                                    value={imageCountries[index]}
                                    onChange={(e) => handleCountryChange(index, e.target.value)}
                                  />
                                  <Input
                                    key={`type-${index}`}
                                    radius="sm"
                                    type="text"
                                    label="Type"
                                    className="mb-5"
                                    labelPlacement="outside"
                                    placeholder="Type"
                                    value={imageType[index]}
                                    onChange={(e) => handleTypeChange(index, e.target.value)}
                                  />
                                  </div>
                                  <div className="flex flex-row gap-4 my-2 w-full">
                                  <button onClick={() => handleCancel(index)} type="primary" className="bg-danger px-5 text-white py-2 w-full rounded">
                                    Cancel
                                  </button>
                                  <button onClick={() => handleUpload(index)} className="bg-primary py-2 px-5 w-full text-white rounded">
                                    Upload
                                  </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion>
                  <AccordionItem
                    key="1"
                    aria-label="info"
                    startContent={<FaRegUser className="text-black text-2xl" />}
                    title="Personal Info"
                  >
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                      <Input
                        radius="sm"
                        type="text"
                        label="First name"
                        labelPlacement="outside"
                        placeholder="First name"
                        value={personalInfo.firstName}
                        onChange={(e) => handlePersonalInfoChange(e, 'firstName')}
                      />
                       <AntdSelect
                        label="Select Country"
                        placeholder="Search Country"
                        labelPlacement="outside"
                        className="mb-3 w-full"
                        value={personalInfo.country}
                        onChange={(value) => handleImageCountryChange(value)}
                      >
                        {countries?.map((country) => (
                          <option value={country.key}>{country.label}</option>
                        ))}
                      </AntdSelect>
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                      <Input
                        radius="sm"
                        type="text"
                        label="Last name"
                        labelPlacement="outside"
                        placeholder="Last name"
                        value={personalInfo.lastName}
                        onChange={(e) => handlePersonalInfoChange(e, 'lastName')}
                      />
                       <AntdSelect
                        label="Select Country Of Birth"
                        placeholder="Search Country"
                        labelPlacement="outside"
                        className="mb-3 w-full"
                        value={personalInfo.countryOfBirth}
                        onChange={(value) => handleImageCountryChange(value)}
                      >
                        {countries?.map((country) => (
                          <option value={country.key}>{country.label}</option>
                        ))}
                      </AntdSelect>
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                      <Input
                        radius="sm"
                        type="text"
                        label="Middle name"
                        labelPlacement="outside"
                        placeholder="Middle name"
                        value={personalInfo.middleName}
                        onChange={(e) => handlePersonalInfoChange(e, 'middleName')}
                      />
                      <Input
                        radius="sm"
                        type="text"
                        label="State of Birth"
                        labelPlacement="outside"
                        placeholder="State"
                        value={personalInfo.stateOfBirth}
                        onChange={(e) => handlePersonalInfoChange(e, 'stateOfBirth')}
                      />
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                      <Input
                        radius="sm"
                        type="text"
                        label="Legal name"
                        labelPlacement="outside"
                        placeholder="Legal name"
                        value={personalInfo.legalName}
                        onChange={(e) => handlePersonalInfoChange(e, 'legalName')}
                      />
                      <Input
                        radius="sm"
                        type="text"
                        label="Place of Birth"
                        labelPlacement="outside"
                        placeholder="Place of Birth"
                        value={personalInfo.placeOfBirth}
                        onChange={(e) => handlePersonalInfoChange(e, 'placeOfBirth')}
                      />
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                      <Input
                        radius="sm"
                        type="date"
                        label="Date of Birth"
                        labelPlacement="outside"
                        placeholder="Date of Birth"
                        value={personalInfo.dob}
                        onChange={(e) => handlePersonalInfoChange(e, 'dob')}
                      />
                      <AntdSelect
                        label="Select Nationality"
                        placeholder="Search Country"
                        labelPlacement="outside"
                        className="mb-3 w-full"
                        value={personalInfo.nationality}
                        onChange={(value) => handlePersonalInfoChange(value ,"nationality")}
                      >
                        {countries?.map((country) => (
                          <option value={country.key}>{country.label}</option>
                        ))}
                      </AntdSelect>
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-8">
                      <Input
                        radius="sm"
                        type="phone"
                        label="Phone"
                        labelPlacement="outside"
                        placeholder="Enter Phone no."
                        value={personalInfo.phone}
                        onChange={(e) => handlePersonalInfoChange(e, 'phone')}
                      />
                      <div>
                        <p className="mb-2">Gender</p>
                        <div>
                          <Radio.Group
                            name="radiogroup"
                            defaultValue={personalInfo.gender}
                            onChange={handleGenderChange}
                          >
                            <Radio value="M">Male</Radio>
                            <Radio value="F">Female</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 items-center mb-5">
                    <AntdSelect
                        label="Tax Residence Country"
                        placeholder="Search Tax Residence Country"
                        labelPlacement="outside"
                        className="mb-3 w-full"
                        value={personalInfo.taxResidenceCountry}
                        onChange={(value) => handlePersonalInfoChange(value ,"taxResidenceCountry")}
                      >
                        {countries?.map((country) => (
                          <option value={country.key}>{country.label}</option>
                        ))}
                      </AntdSelect>
                      <Input
                        radius="sm"
                        type="text"
                        label="TIN"
                        labelPlacement="outside"
                        placeholder="TIN"
                        value={personalInfo.tin}
                        onChange={(e) => handlePersonalInfoChange(e, 'tin')}
                      />
                    </div>
                    {personalInfoLoading ? (
                      <div className="flex justify-center py-4">
                        <Spin />
                      </div>
                    ) : (
                      <Button
                        onClick={handlePersonalInfo}
                        className="w-full bg-[#8103CE] text-white rounded mb-10"
                        color="#8103CE"
                      >
                        Save
                      </Button>
                    )} 
                  </AccordionItem>
                </Accordion>
              </div>
              {personalInfoLoading ? (
                      <div className="flex justify-center py-4">
                        <Spin />
                      </div>
                    ) : (
                <div  className={addresses ? '' : 'hidden'}>
                  {addresses?.map((address, index ) => (
                    <div className="mb-6 bg-white rounded-2xl px-2">
                        <Accordion>
                        <AccordionItem
                              key="1"
                              aria-label="info"
                              startContent={<RiMapPinLine className="text-black text-2xl" />}
                              title="Address Info"
                            >
                              <div key={index} className="rounded-2xl p-4">
                                <div>
                                <AntdSelect
                                    label="Select Nationality"
                                    placeholder="Search Country"
                                    labelPlacement="outside"
                                    className="mb-3 w-full"
                                    value={address.country}
                                    onChange={(value) => handleAddressChange(index, "country", value)}
                                  >
                                    {countries?.map((country) => (
                                      <AntdSelect.Option key={country.key} value={country.key}>
                                        {country.label}
                                      </AntdSelect.Option>
                                    ))}
                                  </AntdSelect>
                                </div>
                                <div className="grid grid-cols-2 gap-5 mb-5">
                                  <Input
                                    key={`street-${index}`}
                                    radius="sm"
                                    type="text"
                                    label="Street"
                                    labelPlacement="outside"
                                    placeholder="Street"
                                    value={address.street}
                                    onChange={(e) => handleAddressChange(index, "street", e.target.value)}
                                  />
                                  <Input
                                    key={`subStreet-${index}`}
                                    radius="sm"
                                    type="text"
                                    label="Street (line 2)"
                                    labelPlacement="outside"
                                    placeholder="Street (line 2)"
                                    value={address.subStreet}
                                    onChange={(e) => handleAddressChange(index, "subStreet", e.target.value)}
                                  />
                                </div>
                                <div className="grid grid-cols-2 gap-5 mb-5">
                                  <Input
                                    key={`town-${index}`}
                                    radius="sm"
                                    type="text"
                                    label="town"
                                    labelPlacement="outside"
                                    placeholder="town"
                                    value={address.town}
                                    onChange={(e) => handleAddressChange(index, "town", e.target.value)}
                                  />
                                  <Input
                                    key={`state-${index}`}
                                    radius="sm"
                                    type="text"
                                    label="State"
                                    labelPlacement="outside"
                                    placeholder="State"
                                    value={address.state}
                                    onChange={(e) => handleAddressChange(index, "state", e.target.value)}
                                  />
                                </div>
                                <div className="grid grid-cols-2 gap-5 mb-5">
                                  <Input
                                    key={`postCode-${index}`}
                                    radius="sm"
                                    type="text"
                                    label="Postal/ZIP code"
                                    labelPlacement="outside"
                                    placeholder="Postal/ZIP code"
                                    value={address.postCode}
                                    onChange={(e) => handleAddressChange(index, "postCode", e.target.value)}
                                  />
                                  <Input
                                    key={`country-${index}`}
                                    radius="sm"
                                    type="text"
                                    label="country"
                                    labelPlacement="outside"
                                    placeholder="country"
                                    value={address.country}
                                    onChange={(e) => handleAddressChange(index, "country", e.target.value)}
                                  />
                                </div>
                                <div className="lg:grid lg:grid-cols-2 flex flex-col gap-5 mb-5">
                                  <div className=" w-full flex flex-row lg:flex-row md:flex-col gap-3">
                                  <button onClick={() => saveAddress(index)} className="bg-green-500 text-white py-2 px-4 rounded w-full lg:w-24 xl:w-full">
                                    Save
                                  </button>
                                  <button onClick={() => showMap(index)} className="bg-blue-500 text-white py-2 lg:px-2 px-4 rounded w-full">
                                    Show Map
                                  </button>
                                  </div>
                                  <button className="bg-red-500 text-white py-2 px-4 rounded w-full" onClick={() => removeAddress(index)}>
                                    Remove Address
                                  </button>
                                </div>
                              </div>
                            
                        </AccordionItem>
                        </Accordion>
                    </div>
                    ))}
                  </div>
                    )} 
                <div className={ imageData && savedDocTypes && savedDocTypes.length > 0 ? '' : 'hidden'}>
                  {imageData && savedDocTypes && (
                    <div className="mb-6 bg-white rounded-2xl px-2">
                      <Accordion className="pb-4">
                        {savedDocTypes.map((savedDocType, index) => (
                          <AccordionItem
                            key={index}
                            aria-label="info"
                            startContent={<IoIosLink className="text-black text-2xl" />}
                            title={`${savedDocType?.idDocType} (${savedDocType.country ? savedDocType.country : ''})`}
                          >
                            {imageData
                              .filter(item =>
                                savedDocType.country === item.idDocDef.country &&
                                savedDocType.idDocSubType === item.idDocDef.idDocSubType &&
                                savedDocType.idDocType === item.idDocDef.idDocType
                              )
                              .map((item, idx) => (
                                <DocumentCard
                                  key={idx}
                                  item={item}
                                  idDocs={personalInfo?.idDocs}
                                  applicantId={applicant?.id}
                                  documentData={documentData}
                                  setDocumentData={setDocumentData}
                                />
                              ))}
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  )}
              </div>
            </div>
            <div className="w-2/5 max-md:w-full">
          { reviewStatus?.reviewResult.reviewRejectType && reviewStatus?.reviewResult.rejectLabels &&<div className="bg-red-100 text-red-400 rounded-md px-2 pb-4 mb-4 relative">
              <div className="px-8 mb-2 mt-4">
                <div className="text-2xl">RESUBMISSION</div>
              <div className="">
                <label>Message for the applicant: </label><br />
                <span>{reviewStatus?.reviewResult.moderationComment}</span>
              </div>
              <div className="my-2">
                <label>Internal Note: </label><br/><span>{reviewStatus?.reviewResult?.clientComment}</span>
              </div>
              <div className={`text-white px-2 my-2 py-1 rounded-md text-center ${reviewStatus?.reviewResult.reviewRejectType === 'RETRY' ? 'bg-blue-500' : 'bg-red-500'}`}>
                {reviewStatus?.reviewResult.reviewRejectType === 'RETRY' ? 'Temporary rejection' : 'Final rejection'}
              </div>
              <div className="my-2">
                {reviewStatus?.reviewResult.rejectLabels && reviewStatus?.reviewResult.rejectLabels.map((label, index) => (
                  <div key={index} className="bg-red-500 text-white px-2 py-1 rounded-md text-center mb-2">{label}</div>
                ))}
              </div>
              </div>
            </div>}

            <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion>
                  <AccordionItem
                    key="0"
                    aria-label="reviewPanel"
                    startContent={<RiFlag2Line className="text-black text-2xl" />}
                    title={`Review Panel`}
                  >
                    <div className="bg-white rounded-2xl mb-6">
                      {((selectedInternalNote || selectedMessage) && holdReviewStatus !== 'completed') && 
                        <div className="my-2">
                          <p>
                            {selectedCategory === "Resubmission Request"
                              ? "This applicant will be rejected with a temporary rejection status. The applicant will be able to resubmit the required documents to pass verification again."
                              : "This applicant will be rejected with final rejection status. They won't be able to resubmit their documents for verification."}
                          </p>
                        </div>
                      }
                      <div
                        className={`w-full flex items-center justify-center p-2 text-white cursor-pointer rounded-md ${
                          holdReviewStatus === 'completed'
                            ? "bg-blue-500 "
                            : (holdReviewStatus === 'onHold' && hasSelectedItems )
                            ? "bg-[#2e4b1b]"
                            : "bg-red-500"
                        }`}
                        onClick={handleActionClick}
                      >
                        {holdReviewStatus === 'completed' ? (
                          <>
                            <IoPlayOutline />
                            <span > Start manual check</span>
                          </>
                        ) : holdReviewStatus === 'onHold' && hasSelectedItems ? (
                          <>
                            <FaCheck />
                           <span> Approve </span>
                          </>
                        ) : (
                          <>
                            <FcCancel className="text-white"/>
                            <span> Decline </span>
                          </>
                        )}
                      </div>
                    { holdReviewStatus !== 'completed' && <div>
                     <div className="my-2 flex gap-4 items-start">
                        <li className="text-blue-500">Resumbission Request</li>
                        <li className="text-red-500">Reject</li>
                      </div>
                      {!selectedCategory ? (
                      <div className="">
                      {Object.keys(resubmissionRequest).map((category, index) => (
                        <div
                          key={index}
                          className={`cursor-pointer gap-2 mx-2 py-2 px-4 mb-2 border inline-flex max-w-max ${
                          resubmissionRequest[category].type === "RETRY"
                                  ? `text-blue-500 border-blue-500`
                                  : `text-red-500 border-red-500`
                                  }`}
                          onClick={() => handleCategorySelect(category)}
                        >
                          {category} <GrNext />
                          {selectedItems[category] && selectedItems[category].length > 0 && (
                            <span className="ml-2 text-xs bg-gray-200 rounded-lg px-2">
                              {selectedItems[category].length} selected
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                    
                      ) : (
                        <>
                          {selectedCategory && (
                        <div className="mt-4">
                          {resubmissionRequest[selectedCategory].items.map((item, index) => (
                            <div
                              key={index}
                              className={`cursor-pointer py-2 px-4 mb-2 border ${
                                selectedItems[selectedCategory]?.includes(item.label)
                                  ? `bg-${resubmissionRequest[selectedCategory].type === 'RETRY' ? 'blue' : 'red'}-500 text-white`
                                  : `text-${resubmissionRequest[selectedCategory].type === 'RETRY' ? 'blue' : 'red'}-500`
                              }`}
                              style={{
                                borderColor: selectedItems[selectedCategory]?.includes(item.label)
                                  ? `var(--ring-${resubmissionRequest[selectedCategory].type === 'RETRY' ? 'blue' : 'red'}-500)`
                                  : `var(--ring-${resubmissionRequest[selectedCategory].type === 'RETRY' ? 'blue' : 'red'}-500)`,
                              }}
                              onClick={() => handleItemSelect(item.label)}
                            >
                              {item.label}
                            </div>
                          ))}
                          <div className="cursor-pointer text-blue-500" onClick={handleBackToCategories}>
                                  Back to categories
                            </div>
                          </div>
                        )}
                        </>
                      )}
                      {(selectedInternalNote || selectedMessage ) && (
                          <div className="mt-4 w-full">
                            <div className="flex flex-col gap-2">
                              <label>Message for applicant</label>
                              <textarea
                                className="border rounded-md p-2 mb-2 w-full"
                                placeholder="Message for applicant"
                                value={selectedMessage}
                                onChange={(e) => handleInputChange(e, "message")}
                              ></textarea>
                              <label>Internal note</label>
                              <textarea
                                className="border rounded-md p-2 w-full"
                                placeholder="Internal note"
                                value={selectedInternalNote}
                                onChange={(e) => handleInputChange(e, "internalNote")}
                              ></textarea>
                            </div>
                          </div>
                      )}
                     </div>}
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>

              <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion>
                  <AccordionItem
                     key="0"
                     aria-label="actions"
                     startContent={<BsTag className="text-black text-2xl" />}
                     title={`Actions`}
                  >
                  <div className="bg-white rounded-2xl mb-6">
                    <div>
                        {activation ? (
                        <button
                          onClick={() => handleInactive("deactivated")}
                          className="cursor-pointer flex items-center text-[#EF5656] gap-3 ml-2 mb-2 text-sm"
                        >
                          <RiDeleteBin7Line /> Mark as inactive
                        </button>
                      ) : (
                        <button
                          onClick={() => handleInactive("activated")}
                          className="cursor-pointer flex items-center text-[#EF5656] gap-3 ml-2 mb-2 text-sm"
                        >
                          <RiDeleteBin7Line />
                          Revert inactive
                        </button>
                      )}
                      <button
                        onClick={() => handleReset()}
                        className="cursor-pointer flex items-center text-[#19BC9B] gap-3 ml-2 mb-5 text-sm"
                      >
                        <GrPowerReset /> Reset
                      </button>

                      <span>Applicant tags</span>
                      <div className="mt-2">
                        <AntdSelect
                        mode="multiple"
                        allowClear
                            labelPlacement="outside"
                            className="mb-3 w-full"
                          >
                            {options?.map((option) => (
                              <option value={option}>{option}</option>
                            ))}
                          </AntdSelect>
                      </div>
                      <p className="text-[#586073] mt-3">
                        Define your own tags and use them to categorize, identify or
                        bookmark content in Dashboard
                      </p>
                    </div>
                  </div>
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion>
                  <AccordionItem
                    key="1"
                    aria-label="risk"
                    startContent={
                      <TiDocumentText className="text-black text-2xl" />
                    }
                    title="Summary"
                  >
                    <div className="grid grid-cols-3 items-center gap-3 py-3">
                      <span className="w-auto whitespace-nowrap text-sm font-light col-span-2">
                        Review Count
                      </span>
                      {summary.reviewCount ? summary.reviewCount : <GoDash />}
                    </div>
                   <div className="grid grid-cols-3 items-center gap-3 pb-3">
                      <span className="w-auto whitespace-nowrap text-sm font-light col-span-2">
                        Review Date
                      </span>
                      {summary.reviewDate ? summary.reviewDate : <GoDash />}
                    </div>
                    <div className="grid grid-cols-3 items-center gap-3 py-3">
                      <span className="w-auto whitespace-nowrap text-sm font-light col-span-2">
                        Face Liveness
                      </span>
                      {summary.liveness === 'GREEN' ? <FaCheck className="text-green-600"/> : summary.liveness === 'YELLOW' ?  <FaRegQuestionCircle   className="text-orange-400"/> : summary.liveness === 'ERROR' ? <ImCross /> :  <GoDash />}
                      
                    </div> 
                    <div className="grid grid-cols-3 items-center gap-3 py-3">
                      <span className="w-auto whitespace-nowrap text-sm font-light col-span-2">
                        Face Match
                      </span>
                      {summary.match === 'GREEN' ? <FaCheck className="text-green-600"/> : summary.match === 'YELLOW' ?  <FaRegQuestionCircle   className="text-orange-400"/> : summary.match === 'ERROR' ? <ImCross /> :  <GoDash />}

                    </div>
                    <div className="grid grid-cols-3 items-center gap-3 py-3">
                      <span className="w-auto whitespace-nowrap text-sm font-light col-span-2">
                        Image Integrity Check
                      </span>
                      {summary.quality === 'GREEN' ? <FaCheck className="text-green-600"/> : summary.quality === 'YELLOW' ?  <FaRegQuestionCircle   className="text-orange-400"/> : summary.quality === 'ERROR' ? <ImCross /> :  <GoDash />}

                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion>
                  <AccordionItem
                    key="1"
                    aria-label="risk"
                    startContent={
                      <IoWarningOutline className="text-black text-2xl" />
                    }
                    title="Risk Labels"
                  >
                    <div className=" pb-3">
                      <p className="w-auto text-sm font-light text-center">
                        The risk labels found will be shown here.
                      </p>
                      <p className="w-auto text-sm font-light text-center pb-4">
                        <span className="text-[#1764FF] cursor-pointer">
                          Learn more{" "}
                        </span>{" "}
                        about how risk labels might help you to understand
                        applicant risk level.
                      </p>
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
              {duplicates && <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion>
                  <AccordionItem
                    key="1"
                    aria-label="risk"
                    startContent={<FaRegUser className="text-black text-2xl" />}
                    title={`Duplicates (${duplicates.length})`}
                  >
                    {duplicates && duplicates.map((item, index) => (
                      <div key={index} className="bg-[#ffeaea] rounded-md p-4 mb-4 shadow-md">
                        {item.applicant.review.reviewStatus && (
                          <div className="flex items-center mb-2">
                            <span className="font-semibold mr-2">Status:</span>
                            <span className={`${item.applicant.review.reviewStatus === 'completed' ? 'bg-[#19bc9b]' : 'bg-[#eea70d]'} rounded-md p-2 shadow-md text-white w-full text-center`}>{item.applicant.review.reviewStatus ==='completed' ? 'Approved' : item.applicant.review.reviewStatus}</span>
                          </div>
                        )}
                        {item.applicant.createdAt && (
                          <div className="flex items-center mb-2">
                            <span className="font-semibold mr-2">Creation date:</span>
                            <span>{item.applicant.createdAt}</span>
                          </div>
                        )}
                        {(item.applicant.info.firstName || item.applicant.info.lastName) && (
                          <div className="flex items-center mb-2">
                            <span className="font-semibold mr-2">Full name:</span>
                            <span>{item.applicant.info.firstName} {item.applicant.info.lastName}</span>
                          </div>
                        )}
                        {item.applicant.info.dob && (
                          <div className="flex items-center mb-2">
                            <span className="font-semibold mr-2">Date of birth:</span>
                            <span>{item.applicant.info.dob}</span>
                          </div>
                        )}
                        {item.applicant.email && (
                          <div className="flex items-center mb-2">
                            <span className="font-semibold mr-2">Email:</span>
                            <span>{item.applicant.email}</span>
                          </div>
                        )}
                        {item.applicant.phone && (
                          <div className="flex items-center mb-2">
                            <span className="font-semibold mr-2">Phone:</span>
                            <span>{item.applicant.phone}</span>
                          </div>
                        )}
                        {item.applicant.info.idDocs?.map((doc, index) => {
                          if (doc.idDocType === 'ID_CARD') {
                            return (
                              <div className="flex items-center mb-2" key={index}>
                                <span className="font-semibold mr-2">ID card:</span>
                                <span>{doc.number}</span>
                              </div>
                            );
                          }
                          return null;
                        })}
                       <button onClick={() => redirectToPage(item.applicant)} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">View Details... ({item.applicant.id})</button>
                      </div>
                    ))}
                    {duplicates.length === 0 && 
                    <div className="my-4 text-center">
                      No Duplicate Found
                    </div>}
                  </AccordionItem>
                </Accordion>
              </div> }
              <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion>
                  <AccordionItem
                    key="1"
                    aria-label="risk"
                    startContent={<FaRegUser className="text-black text-2xl" />}
                    title="Face Match"
                  >
                    {faceMatch && faceMatch?.map((item, index) => (
                      <div key={index} className="pb-3 grid grid-cols-3 gap-4">
                        {item?.image?.map((item, index) => (
                       <div key={index} className="col-span-1 gap-2"> 
                        <PointerZoom
                          className="lg:w-48 cursor-pointer"
                          size={600}
                          image={{
                              src:`${`data:image/jpeg;base64,${item}`}`,   
                          }}
                          zoomImage={{
                              src: `${`data:image/jpeg;base64,${item}`}`,
                              width: 1024,
                              height: 1024
                          }}
                          placeholderZoomImageSrc= {`item  ${index + 1}`}
                        />
                       </div>
                        ))}
                       <div className="flex flex-col gap-2">Check Date: {item.createdAt}</div>
                        {item?.imageIds?.map((item, index) => (
                       <div key={index} className="col-span-1"> {item}</div>
                        ))}
                         <div className="flex gap-1"><span className="">Match Result:</span><span className="flex items-center justify-center"> {item.answer === 'GREEN' ? <FaCheck className="text-green-600"/> : item.answer === 'YELLOW' ? <FaRegQuestionCircle className="text-orange-400"/> : item.answer === 'ERROR' ? <ImCross /> : <GoDash />}</span></div>
                      </div>
                    ))}
                  </AccordionItem>
                </Accordion>
              </div> 
              <div className="mb-6 bg-white rounded-2xl px-2">
                <Accordion>
                  <AccordionItem
                    key="1"
                    aria-label="risk"
                    startContent={<FaRegUser className="text-2xl" />}
                    title="Liveness Check"
                  >
                   { livenessCheck?.livenessInfo?.livenessData?.images && 
                    <div className="pb-3 flex flex-col">
                        <li className=""><span className="text-black">{livenessCheck?.createdAt}</span></li>
                        <p className="text-blue-400 py-2 flex rounded-lg items-center justify-center cursor-pointer hover:bg-blue-100">
                        <FaEyeSlash /> Video Snippet
                        </p>
                        <div className="flex flex-wrap">
                          {livenessCheck?.livenessInfo?.livenessData?.images.map((item, index) => (
                            <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-2 mx-2">
                              <div className="flex items-center justify-center">
                              <PointerZoom
                                className="lg:w-48 cursor-pointer"
                                size={600}
                                image={{
                                    src:`${`data:image/jpeg;base64,${item.image}`}`,   
                                }}
                                zoomImage={{
                                    src: `${`data:image/jpeg;base64,${item.image}`}`,
                                    width: 1024,
                                    height: 1024
                                }}
                                placeholderZoomImageSrc= {`Image  ${index + 1}`}
                              />
                              </div>
                              <div className="text-center">{item.ts}</div>
                            </div>
                          ))}
                        </div>
                    </div>
                    }
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
          {/* <pre>{JSON.stringify(imageData, null, 2)}</pre> */}
          <div className="mb-6 bg-white rounded-2xl px-2">
            <Accordion defaultExpandedKeys={["1"]}>
              <AccordionItem
                key="1"
                aria-label="document"
                startContent={
                  <TbProgressCheck className="text-black text-2xl" />
                }
                title="Documents"
              > {imageLoading ? (
                <div className="flex items-center justify-center py-16">
                  <Spin size="large" />
                </div>
              ):(
                <div className="relative">
                {imageData && imageData.map((image, index) => (
                  <div key={index} className="lg:grid lg:grid-cols-3 flex flex-col mb-3 gap-4 justify-between">
                    <div className="bg-[#f2f3f9] p-5 rounded-lg md:grid md:grid-cols-2 sm:flex sm:flex-col lg:flex lg:flex-col gap-4 relative" onClick={() => handleImageClick(image, index)} >
                      <div className="cursor-pointer w-[130px] items-center justify-center flex">
                      <PointerZoom
                       className="cursor-pointer"
                          size={400}
                          image={{
                              src: `${`data:image/jpeg;base64,${image.image}`}`,   
                          }}
                          zoomImage={{
                              src: `${`data:image/jpeg;base64,${image.image}`}`,
                              width: 1024,
                              height: 1024
                          }}
                          placeholderZoomImageSrc= {`${index}`}
                      />
                      </div>
                      <div className="">
                      <div className="flex items-center text-sm gap-1">
                        <span>ID:</span> <span>{image.imageId}</span>
                      </div>
                      <div>
                        <span>{applicant.id}</span>
                      </div>
                      <div className={`flex text-sm gap-1 ${image.type === 'selfie' ? 'grid grid-cols-4' : ''}`}>
                        <span className="">File Name:</span>
                        {image.imageFileName}
                      </div>
                      <div className="flex items-center text-sm gap-1">
                        <span>Size:</span> <span>{((image.fileSize)/1000).toFixed(0)} KB</span>
                      </div>
                      <div className="flex items-center text-sm gap-1">
                      <span>Resolution:</span> {image?.actualResolution?.width }&#10006;{image?.actualResolution?.height}
                      </div>
                      <div className="flex items-center text-sm gap-1">
                        <span>Image Type:</span> <span>{image.mimeType}</span>
                      </div>
                      <div className="flex items-center text-sm gap-1">
                        <span>Added Date:</span><span>{image.addedDate}</span>
                      </div>
                      <div className="flex items-center text-sm gap-1">
                        <span>Added From:</span>{image.creatorClientId}
                      </div>
                      <div className="flex items-center text-sm gap-1">
                        <span>Modification Date:</span><span>{!image.modificationData ?  'Missing' : image.modificationData}</span>
                      </div>
                      {image?.extractedInfo?.problems && image?.extractedInfo?.problems && image?.extractedInfo?.problems && image?.extractedInfo?.problems?.map((problem, index) => (
                        <div className="flex items-center text-sm gap-1 text-red-500">
                            <span key={index}>Problems : {problem}</span>
                        </div>
                      ))}
                      </div>
                    </div>
                    {previewImageIndex !== null && previewImage && (
                          <div className="fixed top-0 right-0 bottom-0 flex items-start mt-12 justify-end z-50 p-5">
                            <div className="relative w-1/2 mt-12" style={{maxWidth: '66.66%'}} >
                              <PointerZoom
                                className="lg:w-48 cursor-pointer"
                                    size={400}
                                    image={{
                                        src: `${`data:image/jpeg;base64,${imageData[previewImageIndex].image}`}`,   
                                    }}
                                    zoomImage={{
                                        src: `${`data:image/jpeg;base64,${imageData[previewImageIndex].image}`}`,
                                        width: 2048,
                                        height: 1024
                                    }}
                                    placeholderZoomImageSrc= {`${previewImageIndex}`}
                                />
                              <div className="absolute top-0 flex flex-col -left-12 p-2 bg-gray-300 rounded-l-xl">
                              <button 
                                  onClick={handleClosePreview} 
                                  className="p-2 text-red-500 font-bold rounded-full text-xl"
                                ><IoCloseSharp />
                                </button>
                                <button 
                                  onClick={handleNextImage} 
                                  className="p-2 rounded-full text-xl"
                                ><GrLinkNext/>
                                </button>
                                <button 
                                  onClick={handlePreviousImage} 
                                  className="p-2 rounded-full text-xl"
                                ><GrLinkPrevious/>
                                </button>
                                
                              
                              </div>
                            </div>
                          </div>
                       )}
                    <div className="grid grid-cols-2 w-full md:col-span-2 gap-4">
                      <div>
                        <div className="mb-2">
                          <AntdSelect
                            key={index}
                            id="documentType"
                            value={image?.idDocDef.idDocType || ''}
                            onChange={(value) => handleDocumentTypeChange(value, index)}
                            className="w-full my-4 h-full"
                          >
                            <option  value="PASSPORT" >Passport</option>
                            <option  value="ID_CARD" >ID Card</option>
                            <option  value="DRIVERS" >Driver's License</option>
                            <option  value="RESIDENCE_PERMIT	" >Residential Permit</option>
                            <option  value="UTILITY_BILL">Utility Bill</option>
                            <option  value="SELFIE">Selfie</option>
                            <option  value="VIDEO_SELFIE">VIDEO_SELFIE</option>
                            <option  value="PROOF_OF_ADDRESS">Proof of Address</option>
                            <option  value="2ND_PROOF_OF_ADDRESS">2nd Proof of Address</option>
                            <option  value="PAYMENT_METHOD">Payment Method</option>
                            <option  value="INCOME_SOURCE">Proof of Payment</option>
                            <option  value="BANK_CARD">Bank Card</option>
                            <option  value="BANK_STATEMENT" >Bank Statement</option>
                            <option  value="LEGAL_ENTITY" >Legal Entity</option>
                            <option  value="2ND_SELFIE" >2nd Selfie</option>
                            <option  value="PAYMENT_SELFIE" >Payment Selfie</option>
                            <option  value="STUDENT_ID" >Student ID</option>
                            <option  value="WORK_PERMIT" >Work Permit</option>
                            <option  value="WORK_PATENT" >Work Patent</option>
                            <option  value="EMPLOYMENT_CERTIFICATE" >Employment Certificate</option>
                            <option  value="PROFILE_IMAGE" >Profile Image</option>
                            <option  value="PROFILE_SCREENSHOT" >Profile Screenshot</option>
                          </AntdSelect>
                          {(image?.idDocDef.idDocType !== 'Selfie' && image?.idDocDef.idDocType !== 'Profile Image') && (
                          <AntdSelect 
                            key={index}
                            id="documentType"
                            value={image?.idDocDef?.idDocSubType || '' }
                            onChange={(value) => handleDocumentSideChange(value, index)}
                            className="w-full mb-4"
                          >
                            <option value="FRONT_SIDE">Front Side</option>
                            <option value="BACK_SIDE" >Back Side</option>
                          </AntdSelect>
                          )}
                          {(image?.idDocDef.idDocTyp !== 'SELFIE' && image?.idDocDef.idDocTyp !== 'Profile Image') && (
                          <AntdSelect
                          labelPlacement="outside"
                          className="mb-3 w-full"
                          value={image?.idDocDef.country || '' }
                          onChange={(value) => handleImageCountryChange(value, index)}
                        >
                          {countries && countries.map((country) => (
                            <option value={country.key}>{country.label}</option>
                          ))}
                        </AntdSelect>
                          )}
                        </div>
                        <button className="bg-[#4c9ae4] w-full text-center text-white p-2 mb-2 rounded" onClick={() => saveDocument(index , image.imageId)}>
                          Save
                        </button>
                        <button className="bg-[#4c9ae4] w-full text-center text-white p-2 mb-2 rounded" onClick={()=>compareSelfie(index , image.imageId)}>
                          Compare with Selfie
                        </button>
                        <button className="bg-[#4c9ae4] w-full text-center text-white p-2 mb-2 rounded" onClick={()=>flipImage(index , image.imageId , 'FLOP')}>
                          Flip Image
                        </button>
                        <button className={`w-full text-center p-2 mb-2 border rounded ${image.deleted ? "text-white bg-[#4c9ae4] border-[#4c9ae4] " : "bg-[#dc615c] text-white border-[#dc615c]"}`}     onClick={()=>revertStatus(index , image.imageId , image.deleted)} >
                          {image.deleted ? "Revert Inactive" : "Mark as Inactive"}
                        </button>
                      </div>
                    <div key={index} className={`bg-[#e3f3ee] rounded-3xl ${image.reviewResult ? 'text-5xl flex items-center justify-center ' : 'my-4'}`}>
                          {image.reviewResult ? (
                            <div>
                        {image.answer === 'GREEN' ? <FaCheck className="text-green-600"/> : image.answer === 'YELLOW' ?  <FaRegQuestionCircle   className="text-orange-400"/> : image.answer === 'ERROR' ? <ImCross /> :  <GoDash />}
                    </div>
                          ) : (
                            <div>
                              <div className="mt-6 px-4">
                              <AntdSelect
                                labelPlacement="outside"
                                className="mb-3 w-full"
                                disbaled
                                value={image?.idDocDef.country || '' }
                              >
                                {countries && countries.map((country) => (
                                  <option value={country.key}>{country.label}</option>
                                ))}
                              </AntdSelect></div>
                              {image.deleted === false &&
                              <div className="px-4 ">
                                <div className="rounded-2xl mb-6">
                                  <textarea
                                    className="border rounded-md p-2 w-full"
                                    placeholder="Message"
                                    value={selectedImageMessage}
                                    onChange={(e) => handleImageInputChange(e, "internalNote")}
                                  ></textarea>
                                  <div
                                    className={`w-full flex items-center justify-center p-2 text-white cursor-pointer rounded-md ${
                                      image.deleted === false
                                        ? "bg-[#2e4b1b]"
                                        : "bg-red-500"
                                    }`}
                                    onClick={handleImageActionClick}
                                  >
                                    { image.deleted === false ? (
                                      <>
                                        <FaCheck />
                                      <span> Accept </span>
                                      </>
                                    ) : (
                                      <>
                                        <FcCancel className="text-white"/>
                                        <span> Reject </span>
                                      </>
                                    )}</div>
                                   <div>
                                    <div className="my-2">
                                      <li className="text-blue-500">Resumbission Request</li>
                                      <li className="text-red-500">Reject</li></div>
                {!selectedImageCategory ? (
                <div className="">
                {Object.keys(imageRequest).map((category, index) => (
                  <div className={`cursor-pointer gap-2 mx-2 py-2 px-4 mb-2 border inline-flex max-w-max ${
                      imageRequest[category].type === "RETRY"
                            ? `text-blue-500 border-blue-500`
                            : `text-red-500 border-red-500`
                            }`}
                    onClick={() => handleImageCategorySelect(category)}
                  >
                    {category} <GrNext />
                    {selectedImageItems[category] && selectedImageItems[category].length > 0 && (
                      <span className="ml-2 text-xs bg-gray-200 rounded-lg px-2">
                        {selectedImageItems[category].length} selected
                      </span>
                    )}</div>
                ))}</div>
              
                ) : (
                  <>
                    {selectedImageCategory && (
                  <div className="mt-4">
                    {imageRequest[selectedImageCategory]?.items.map((item, index) => (
                      <div
                        key={index}
                        className={`cursor-pointer py-2 px-4 mb-2 border ${
                          selectedImageItems[selectedImageCategory]?.includes(item.label)
                            ? `bg-${imageRequest[selectedImageCategory].type === 'RETRY' ? 'blue' : 'red'}-500 text-white`
                            : `text-${imageRequest[selectedImageCategory].type === 'RETRY' ? 'blue' : 'red'}-500`
                        }`}
                        style={{
                          borderColor: selectedImageItems[selectedImageCategory]?.includes(item.label)
                            ? `var(--ring-${imageRequest[selectedImageCategory].type === 'RETRY' ? 'blue' : 'red'}-500)`
                            : `var(--ring-${imageRequest[selectedImageCategory].type === 'RETRY' ? 'blue' : 'red'}-500)`,
                        }}
                        onClick={() => handleImageItemSelect(item.label)}
                      >
                        {item.label}
                      </div>
                    ))}
                    <div className="cursor-pointer text-blue-500" onClick={handleImageBackToCategories}>
                            Back
                      </div>
                    </div>
                  )}
                  </>
                )}
              </div>
              </div>
                              </div>}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
                </div>
              )}
                </AccordionItem>
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicantInfo;
