import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const FunctionalApexChart = ({ candidates}) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      stroke: {
        curve: 'smooth'
      },
      colors: ['#8103CE'],
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        categories: ['Total', 'Rejected', 'Accepted', 'Pending', 'Initial', 'onHold'],
      },
    }
  });

  useEffect(() => {
    const seriesData = [
      {
        name: "Applicants",
        data: [candidates.totalCount, candidates.RED, candidates.GREEN, candidates.pending, candidates.init, candidates.onHold]
      }
    ];

    setChartData(prevState => ({
      ...prevState,
      series: seriesData
    }));
  }, [candidates.totalCount, candidates.RED, candidates.GREEN, candidates.pending, candidates.init, candidates.onHold]);
  return (
    <div className='bg-white rounded-2xl w-full' id="chart">
      <div className='flex justify-between px-5 pt-5 mb-5 items-center'>
        <h1 className='text-lg'>Line Chart of Appliants Detail</h1>
      </div>
      {candidates.totalCount > 0 && <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350} className='p-5' />}
    </div>
  );
};

export default FunctionalApexChart;
