import React, { useState } from "react";
import Background from "../../images/background.png";
import Logo from "../../images/Logo.png";
import Banner from "../../images/login-banner.png";
import { LuUser, LuLock } from "react-icons/lu";
import { FaIndustry } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { TbPassword } from "react-icons/tb";
import { IoMailOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import MetaMask from "../../images/metamasktrumeid.png";

import { useDispatch } from "react-redux";
import { isLogin } from "../../Redux/reducers/slices/loginSlice";
import axios from "axios";
import { Spin, message } from "antd";

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("working");
    if (formData.password !== formData.confirmPassword) {
      errorMsg("Password not match");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post("/auth/signup", formData);
      setLoading(false);

      if (response?.data) {
        navigate("/");
      }
    } catch (error) {
      console.error("Signup failed:", error.response);
      setLoading(false);
      if (error.response) {
        message.error(error.response.data);
      } else {
        message.error("Internal server error");
      }
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 max-sm:grid-cols-1 max-md:grid-cols-1 h-screen relative">
        <img
          src={MetaMask}
          alt="metamask"
          className="h-24 max-sm:h-16 max-md:h-16 absolute top-10 left-10 max-sm:top-5 max-md:top-5 max-sm:left-5 max-md:left-5"
        />
        <div className="flex justify-center items-center py-32">
          <form className="w-1/2 max-sm:w-[80%]" onSubmit={handleSubmit}>
            <h1 className="text-3xl font-extrabold mb-2">Sign Up</h1>
            <p className="text-[#525252] mb-10">
              Already have an account?{" "}
              <Link to="/">
                <span className="text-blue-600 underline cursor-pointer">
                  Login
                </span>
              </Link>
            </p>
            <div className="bg-[#F0EDFFCC] flex gap-4 items-center px-5 rounded-lg mb-5 cursor-pointer">
              <LuUser className="text-xl" />
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full legal name"
                className="bg-[#F0EDFFCC] outline-none placeholder:text-black py-3 w-full"
                required
              />
            </div>
            <div className="bg-[#F0EDFFCC] flex gap-4 items-center px-5 rounded-lg mb-5 cursor-pointer">
              <IoMailOutline className="text-xl" />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Work Email"
                className="bg-[#F0EDFFCC] outline-none placeholder:text-black py-3 w-full"
                required
              />
            </div>
            <div className="bg-[#F0EDFFCC] flex gap-4 items-center px-5 rounded-lg mb-5 cursor-pointer">
              <FaIndustry className="text-xl" />
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="Full company name"
                className="bg-[#F0EDFFCC] outline-none placeholder:text-black py-3 w-full"
                required
              />
            </div>
            <div className="bg-[#F0EDFFCC] flex gap-4 items-center px-5 rounded-lg mb-5 cursor-pointer">
              <TbPassword className="text-xl" />
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                className="bg-[#F0EDFFCC] outline-none placeholder:text-black py-3 w-full"
                required
              />
            </div>
            <div className="bg-[#F0EDFFCC] flex gap-4 items-center px-5 rounded-lg mb-5 cursor-pointer">
              <MdOutlinePassword className="text-xl" />
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm Password"
                className="bg-[#F0EDFFCC] outline-none placeholder:text-black py-3 w-full"
                required
              />
            </div>
            {loading ? (
              <div className="flex justify-center mb-5">
                <Spin />
              </div>
            ) : (
              <input
                type="submit"
                value={"Continue"}
                className="bg-gradient-to-r from-[#9F90FA] to-[#8103CE] cursor-pointer flex items-center justify-center font-bold py-3 text-white w-full rounded-lg mb-4"
              />
            )}
            <p className="text-sm text-gray-400">
              I hereby warrant and represent that I am duly authorized to
              proceed with the registration on behalf of the legal entity
              specified above {"Company"}. By clicking "Continue" below, I
              confirm that the company fully acknowledges, accepts the consents
              to TrumeiD{" "}
              <Link to="/terms" className="text-blue-600 underline">
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link to="/privacy" className="text-blue-600 underline">
                Privacy Notice
              </Link>
              .
            </p>
          </form>
        </div>
        <div
          style={{ backgroundImage: `url(${Background})` }}
          className="h-full bg-cover flex justify-center items-center max-sm:hidden max-md:hidden"
        >
          <div className="flex flex-col items-center">
            <img src={Logo} alt=".." className="mb-5" />
            <img src={Banner} alt=".." />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
