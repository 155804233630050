import { useEffect, useState } from "react"


export const useDebouce= (value, delay=500)=>{
    const [debouceValue, setDebounceValue]= useState(value)


    useEffect(()=>{
        const timeout=setTimeout(()=>{
            setDebounceValue(value)
        },delay)
        return ()=>clearTimeout(timeout)
    },[value])
    return debouceValue
}
