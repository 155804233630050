import React, { useEffect, useState } from "react";
import Background from "../../images/background.png";
import Logo from "../../images/Logo.png";
import Banner from "../../images/login-banner.png";
import { LuUser, LuLock } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Input, Spin, message } from "antd";
import { useDispatch } from "react-redux";
import { isLogin } from "../../Redux/reducers/slices/loginSlice";
import Verification from "../../components/Verification/Verification";
import MetaMask from "../../images/metamasktrumeid.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Loading, setLoading] = useState(false);

  const [verifyTwoFactor, setVerifyTwoFactor] = useState(false);

  const [speakeasySecret, setspeakeasySecret] = useState("");
  const [qrUrl, setqrUrl] = useState("");

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const sucessMsg = (msg) => {
    messageApi.open({
      type: "sucess",
      content: msg,
    });
  };
  const handleLogin = async (email, password) => {
    setLoading(true);
    try {
      const response = await axios.post("/auth/login", {
        email,
        password,
      });

      if (response.data) {
        setspeakeasySecret(response?.data?.secret);
        if (response?.data?.qrUrl) {
          setqrUrl(response?.data?.qrUrl);
        }

        sucessMsg(response.data.message);
        setLoading(false);
        setVerifyTwoFactor(true);
      }
    } catch (error) {
      let err = error.response.data;
      errorMsg(err.message ? err.message : "Login Failed");
      setLoading(false);
    }
  };
  useEffect(() => {
    axios
      .get("/auth/get-admin-credentials")
      .then((response) => {
        const adminCredentials = response.data;
      })
      .catch((error) => {
        console.error("Error fetching admin credentials:", error);
      });
  }, []);

  return (
    <>
      {contextHolder}
      <div className="grid grid-cols-2 max-sm:grid-cols-1 max-md:grid-cols-1 h-screen relative">
        <img
          src={MetaMask}
          alt="metamask"
          className="h-24 max-sm:h-16 max-md:h-16 absolute top-10 left-10 max-sm:top-5 max-md:top-5 max-sm:left-5 max-md:left-5"
        />
        <div className="flex justify-center items-center">
          {verifyTwoFactor ? (
            <Verification
              secret={speakeasySecret}
              qrUrl={qrUrl}
              email={email}
              setVerifyTwoFactor={setVerifyTwoFactor}
            />
          ) : (
            <form
              className="w-1/2 max-sm:w-[70%]"
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin(email, password);
              }}
            >
              <h1 className="text-3xl font-extrabold mb-2">Login</h1>
              <p className="text-[#525252] mb-10">
                Need to Register?{" "}
                <Link to="/signup">
                  <span className="text-blue-600 underline cursor-pointer">
                    Sign Up
                  </span>
                </Link>
              </p>
              <div className="bg-[#F0EDFFCC] flex gap-4 items-center px-5 rounded-lg mb-5 cursor-pointer">
                <LuUser className="text-xl" />
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="email"
                  placeholder="Email"
                  className="bg-[#F0EDFFCC] outline-none placeholder:text-black py-3 w-full"
                  required
                />
              </div>
              <div className="bg-[#F0EDFFCC] flex gap-4 items-center px-5 rounded-lg mb-5 cursor-pointer">
                <LuLock className="text-xl" />
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  type="password"
                  placeholder="Password"
                  className="bg-[#F0EDFFCC] outline-none placeholder:text-black py-3 w-full"
                  required
                />
              </div>
              {Loading ? (
                <div className="flex justify-center">
                  <Spin />
                </div>
              ) : (
                <input
                  type="submit"
                  value={"Login"}
                  className="bg-gradient-to-r from-[#9F90FA] to-[#8103CE] cursor-pointer flex items-center justify-center font-bold py-3 text-white w-full rounded-lg mb-4"
                />
              )}
            </form>
          )}
        </div>
        <div
          style={{ backgroundImage: `url(${Background})` }}
          className="h-full bg-cover flex justify-center items-center max-sm:hidden max-md:hidden"
        >
          <div className="flex flex-col items-center">
            <img src={Logo} alt=".." className="mb-5" />
            <img src={Banner} alt=".." />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
