import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   setLogin: false,
//   setAccessToken: ''
// };
const initialState= false

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    isLogin: (state, action) => {
      state = action.payload;
      return state;
    },
    // accessToken: (state, action) => {
    //   state.setAccessToken = action.payload;
    //   return state.setAccessToken;
    // }
  }
});

export const { isLogin } = loginSlice.actions;
export default loginSlice.reducer;
