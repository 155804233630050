import React, { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { PiSquaresFourBold } from 'react-icons/pi';
import { LuUsers2 } from 'react-icons/lu';
import { IoSettingsOutline } from 'react-icons/io5';
import { useNavigate, useLocation } from 'react-router-dom';

const SidebarNav = () => {
    const [activeItem, setActiveItem] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split('/')[1]; 
        setActiveItem(path);
    }, [location.pathname]);

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        navigate(`/${itemName.toLowerCase()}`);
    };

    const menuItemData = [
        { id: '', icon: <PiSquaresFourBold className='w-6 h-6' />, color: '#8103CE' },
        { id: 'Applicant', icon: <LuUsers2 className='w-6 h-6' />, color: '#8103CE' },
        { id: 'Settings', icon: <IoSettingsOutline className='w-6 h-6' />, color: '#8103CE' },
    ];

    return (
        <div className='max-sm:hidden max-md:hidden'>
            <Sidebar backgroundColor='white' collapsed collapsedWidth={'fit-content'}>
                <Menu className='py-3'>
                    {menuItemData.map((item) => (
                        <MenuItem
                            key={item.id}
                            active={activeItem.toLowerCase() === item.id.toLowerCase()}
                            onClick={() => handleItemClick(item.id)}
                            className={activeItem.toLowerCase() === item.id.toLowerCase() ? 'active-item' : ''}
                        >
                            {React.cloneElement(item.icon, {
                                color: activeItem.toLowerCase() === item.id.toLowerCase() ? item.color : 'black',
                            })}
                        </MenuItem>
                    ))}
                </Menu>
                <style jsx>{`
                .active-item {
                    border-left: 4px solid #8103CE;
                }
            `}</style>
            </Sidebar>
        </div>
    );
};

export default SidebarNav;
