import { Divider, Input, Select, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { CiGlobe } from "react-icons/ci";
import { FiLogOut } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { isLogin } from '../../Redux/reducers/slices/loginSlice';
import { useNavigate } from 'react-router-dom';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import axios from 'axios';

const { Option } = Select;

const Account = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingPassword, setIsEditingPassword] = useState(false);
    const [name, setName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [user, setUser]= useState('')
    useEffect(()=>{
        const user= localStorage.getItem('user')
        getAdminCredentials(user)
        setUser(user)
        setName( localStorage.getItem('name'))
    },[])
    const handleLogout = () => {
        localStorage.clear();
        dispatch(isLogin(false));
        navigate('/');
    };
    const getAdminCredentials=async(user)=>{
        try {
            const response= await axios.get(`/auth/get-admin-credentials/${user}`)
            setName(response.data.name)
            setNewPassword(response.data.password)
        } catch (error) {
            console.log(error)
        }
    }
    const handleNameChange = () => {
        setIsEditingName(true);
    };

    const handlePasswordChange = () => {
        setIsEditingPassword(true);
    };

    const handleNameApply = () => {
        axios.patch(`/auth/update-admin/${user}`, { name })
        .then(response => {
            console.log('Name updated successfully:', response.data);
            setIsEditingName(false);
        })
        .catch(error => {
            console.error('Error updating name:', error);
        });
        setIsEditingName(false)
        localStorage.setItem('name', name)
    };

    const handlePasswordApply = () => {
        setIsEditingPassword(false)
        axios.patch(`/auth/update-admin/${user}`, { password:newPassword })
            .then(response => {
                setIsEditingPassword(false);
            })
            .catch(error => {
                console.error('Error updating password:', error);
            });
    };
    const [isHidden, setIsHidden] = useState(true);

    const toggleHidden = () => {
        setIsHidden(!isHidden);
    };

    return (
        <div className='bg-white p-5 min-h-full relative'>
            <div className='w-2/5 max-sm:w-full max-md:w-full'>
                <h1 className='text-lg'>TruMeiD Developer</h1>
                <p className='text-sm text-[#373D4D] font-normal'>
                    {isEditingName ? (
                        <Input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className='border-b-1 w-full p-2 outline-none my-2'
                        />
                    ) :
                        <div className='py-1'>
                            {name || 'Change Name'}
                        </div>
                    }
                </p>
                {isEditingName ? (
                    <button className='p-1 px-3 bg-[#8103CE] text-white rounded' type="primary" onClick={handleNameApply}>Apply</button>
                ) : (
                    <span className='text-[#8103CE] cursor-pointer mb-5' onClick={handleNameChange}>Change name</span>
                )}
                <Divider />
            </div>
            <div className='w-2/5 max-sm:w-full max-md:w-full'>
                <p className='text-sm  font-normal'>Two-factor authentication</p>
                <span className='text-[#8103CE] cursor-pointer mb-5'>Change</span>
                <Divider />
            </div>
            <div className='w-2/5 max-sm:w-full max-md:w-full'>
                <p className='text-sm text-[#373D4D] font-normal'>Password</p>
                    <Input.Password
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className='border-b-1 w-full p-2 outline-none my-2'
                    />
                {isEditingPassword ? (
                    <button className='p-1 px-3 bg-[#8103CE] text-white rounded' type="primary" onClick={handlePasswordApply}>Apply</button>
                ) : (
                    <span className='text-[#8103CE] cursor-pointer mb-5' onClick={handlePasswordChange}>Change Password</span>
                )}
                <Divider />
            </div>
            <div className='w-2/5 max-sm:w-full max-md:w-full mb-32'>
                <div className='flex items-center gap-2 mb-3'>
                    <CiGlobe />
                    <span>Dashboard language</span>
                </div>
                <Select
                    defaultValue="English"
                    style={{
                        width: '100%',
                    }}
                    options={[
                        {
                            value: 'english',
                            label: 'English',
                        },
                        {
                            value: 'french',
                            label: 'French',
                        },
                        {
                            value: 'russian',
                            label: 'Russian',
                        },
                    ]}
                />
            </div>
            <button onClick={handleLogout} className='flex absolute bottom-5 left-5 items-center py-3 px-5 bg-[#FFE5EE] text-[#AA0000] gap-2 font-bold text-sm rounded-lg hover:bg-[#AA0000] hover:text-white transition-colors duration-300'>
                <FiLogOut className='text-lg' /> Logout
            </button>
        </div>
    );
};

export default Account;
