import { Checkbox } from "@nextui-org/react";
import { DatePicker, Input } from "antd";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { IoSearchOutline, IoReload } from "react-icons/io5";
import { FaBars } from "react-icons/fa";
import Table from "./Table";
import axios from "axios";
import { useDebouce } from "./customHook";
import { Dropdown as AntdDropdown } from 'antd'
import { MdFilterListAlt } from "react-icons/md";
import { useLocation } from 'react-router-dom';

const statusOptions = [
  {
    key: "All",
  },
  {
    key: "init",
    value: "Document Requested"
  },
  {
    key: "pending",
     value: "Pending"
  },
  {
    key: "prechecked",
     value: "Pre-Checked"
  },
  {
    key: "queued",
     value: "Queued"
  },
  {
    key: "completed",
     value: "Completed"
  },
  {
    key: "onHold",
     value: "Requires Action"
  },
];
const documentOptions = [
  {
    key: "All",
  },
  {
    key: "identity",
  },
  {
    key: "selfie",
  },
  {
    key: "phone verification",
  },
  {
    key: "email verification",
  },
  {
    key: "company",
  },
];
const completeStatusOptions = [
  { key: "All" },
  { key: "Accepted" },
  { key: "Rejected"},
];
const Individual = ({ applicID, levelValue }) => {
  const location = useLocation();
  const statusVal = location.state?.status;
  const [search, setSearch] = useState("");
  const [levelOptions, setLevelOptions] = useState([]);
  /////////
  const [level, setLevel] = useState("All");
  const [status, setStatus] = useState("All");
  const [document, setDocument] = useState("All");
  const [date, setDate] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [combineFilter, setCombineFilter] = useState(false);
  const debounceSearch = useDebouce(search);
  ///////
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount]= useState(0);
  const [completeStatus, setCompleteStatus] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const getPageInfo = (val) => {
    setCurrentPage(val);
  };

  const handleApplicantClick = (applicant) => {
    applicID(applicant);
  };

  useEffect(() => {
    if (levelValue) {
      const item = levelValue.map((data) => ({
        key: `${data}`,
      }));
      setLevelOptions([{ key: "All" }, ...item]);
    }
  }, [levelValue]);

  useEffect(() => {
    setLoading(true);
     const queryParams = new URLSearchParams(window.location.search);
     const statusParam = queryParams.get('status');
     const subStatusType = queryParams.get('subtype');
     setCompleteStatus(subStatusType)
     if (statusParam) {
       setStatus(statusParam);
     }
 
      filter({  status: statusParam || status, level, document, date, debounceSearch, combineFilter, completeStatus : subStatusType ||  completeStatus});
  }, [
    status,
    debounceSearch,
    level,
    combineFilter,
    document,
    date,
    currentPage,
    completeStatus 
  ]);

  const filter = async (obj) => {
    try {
      const accessToken = localStorage.getItem("authToken");
      const response = await axios.post(
        `/applicant/filter-applicants?page=${currentPage}`,
        {
          status: obj.status,
          level: obj.level,
          document: obj.document,
          date: obj.date,
          search: obj.debounceSearch,
          combine: obj.combineFilter,
          completeStatus: obj.completeStatus
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response.data.data);
      setFilterData(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotalCount(response.data.totalCount);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  const combineButton = () => {
    setCombineFilter(!combineFilter);
    if (!combineFilter) {
      setLevel("All");
      setStatus("All");
      setDocument("All");
      setDate("");
    }
  };
  const handleClearFilter = () => {
    setLevel("All");
    setStatus("All");
    setDocument("All");
    setDate("");
    setSearch("");
    filter();
  };
  const handlePeriod = (date, dateString) => {
    setDate(dateString);
  };
  const handleRefreshButton = async () => {
    setLoading(true);
    const accessToken = localStorage.getItem("authToken");
    await filter(accessToken);
    setLoading(false);
  };
  const items = [
    {
      label: <span
        className="text-[#7f8285] flex gap-2 items-center cursor-pointer hover:text-[#8103CE]"
        onClick={handleRefreshButton}
      >
        Refresh
      </span>,
      key: '0',
    },
    {
      label: <span
        onClick={handleClearFilter}
        className="text-[#7f8285] flex gap-2 items-center cursor-pointer hover:text-[#8103CE]"
      >
        Clear filters
      </span>,
      key: '1',
    },
    {
      label: <span className="text-[#7f8285] flex gap-2 items-center cursor-pointer hover:text-[#8103CE]">
        CSV export
      </span>,
      key: '3',
    },
  ];
  const content = [
    {
      label: 'hello',
      key: '0',
    },
    {
      label: 'Hello 2',
      key: '1',
    },
    {
      label: <span className="text-[#7f8285] flex gap-2 items-center cursor-pointer hover:text-[#8103CE]">
        CSV export
      </span>,
      key: '3',
    },
  ];
  useEffect(() => {
    if (statusVal) {
      setStatus(statusVal);
    }
  }, [statusVal]);
  
  return (
    <div className="pt-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-5 max-md:gap-2">
          <Input
            placeholder="Enter Applicant ID"
            size="large"
            prefix={
              <IoSearchOutline className="site-form-item-icon text-[#D5D9DD]" />
            }
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            required
          />
          <Checkbox
            size="md"
            onChange={() => combineButton()}
            defaultSelected={combineFilter}
          >
            <p className="whitespace-nowrap max-md:text-sm">Combine with filters</p>
          </Checkbox>
        </div>

        <div className="hidden max-md:block">
          <AntdDropdown
            menu={{
              items,
            }}
            trigger={['click']}
          >
            <a className="text-xl mr-5 flex items-center ml-5" onClick={(e) => e.preventDefault()}>
              <FaBars />
            </a>
          </AntdDropdown>
        </div>
        <div className="flex gap-6 max-md:hidden">
          <span
            className="text-[#7f8285] flex gap-2 items-center cursor-pointer hover:text-[#8103CE]"
            onClick={handleRefreshButton}
          >
            <IoReload />
            Refresh
          </span>
          <span
            onClick={handleClearFilter}
            className="text-[#7f8285] flex gap-2 items-center cursor-pointer hover:text-[#8103CE]"
          >
            Clear filters
          </span>
          <span className="text-[#7f8285] flex gap-2 items-center cursor-pointer hover:text-[#8103CE]">
            CSV export
          </span>
        </div>
      </div>
      <div className="py-5 max-md:justify-between flex gap-5 items-center">
        <span>
          Found: <span>{totalCount}</span>
        </span>

        <div className="flex gap-5 items-center">
          <div className="hidden max-md:hidden">
            <AntdDropdown
              menu={{
                content,
              }}
              trigger={['click']}
            >
              <a className="text-xl mr-5 flex items-center" onClick={(e) => e.preventDefault()}>
                <MdFilterListAlt /> Filters
              </a>
            </AntdDropdown>
          </div>

          <DatePicker
            className="bg-[#f1f2f6] border-0  placeholder:text-black shadow-none cursor-pointer MyDatePicker"
            placeholder="Period: All Time"
            onChange={handlePeriod}
          />

          <Dropdown>
            <DropdownTrigger>
              <span className=" flex gap-2 items-center cursor-pointer">
                Document Type: {document}
                <FaAngleDown className="text-sm" />
              </span>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Action event example"
              onAction={(key) => setDocument(key)}
            >
              {documentOptions.map((data) => {
                return <DropdownItem key={data.key}>{data.key}</DropdownItem>;
              })}
            </DropdownMenu>
          </Dropdown>

          <Dropdown>
            <DropdownTrigger>
              <span className="flex  gap-2 items-center cursor-pointer">
                Levels: {level}
                <FaAngleDown className="text-sm" />
              </span>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Action event example"
              onAction={(key) => setLevel(key)}
            >
              {levelOptions.map((data) => {
                return <DropdownItem key={data.key}>{data.key}</DropdownItem>;
              })}
            </DropdownMenu>
          </Dropdown>

          <Dropdown>
            <DropdownTrigger>
              <span className="flex gap-2 items-center cursor-pointer ">
                Applicant Status: {status}
                <FaAngleDown className="text-sm" />
              </span>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Action event example"
              onAction={(key) => setStatus(key)}
            >
              {statusOptions.map((data) => {
                return <DropdownItem key={data.key}>{data.value ? data.value : data.key}</DropdownItem>;
              })}
            </DropdownMenu>
          </Dropdown>
          {status === "completed" && (
            <Dropdown>
              <DropdownTrigger>
                <span className="flex gap-2 items-center cursor-pointer">
                  Complete Status: {completeStatus}
                  <FaAngleDown className="text-sm" />
                </span>
              </DropdownTrigger>
              <DropdownMenu aria-label="Complete Status Filter" onAction={(key) => setCompleteStatus(key)}>
                {completeStatusOptions.map((data) => (
                  <DropdownItem key={data.key}>{data.key}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </div>
      <div className="">
        <Table
          onApplicantClick={handleApplicantClick}
          applicantData={filterData}
          totalPages={totalPages}
          getPageInfo={getPageInfo}
          getLoading={loading}
        // setGetLoading={setGetLoading}
        />
      </div>
    </div>
  );
};

export default Individual;
