// import { Divider } from 'antd';
// import React, { useState } from 'react';
// import { FaCheck } from 'react-icons/fa';
// import { IoClose } from 'react-icons/io5';

// const Business = () => {
//   const [showEdit, setShowEdit] = useState(false);



//   const [showBusiness, setShowBusiness] = useState(false);
//   const [showRegulations, setShowRegulations] = useState(false);
//   const [business, setBusiness] = useState('TrumeiD');
//   const [regulations, setRegulations] = useState('Company is not regulated');
//   const [bottomInput, setBottomInput] = useState('')
//   const [regulationInput, setRegulationInput] = useState('')
//   const handleBusinessInputChange = (e) => {
//     setBottomInput(e.target.value);
//   };
//   const handleBusinessCheckClick = () => {
//     setBusiness(bottomInput);
//     setBottomInput('');
//   };
//   const handleRegulationsInputChange = (e) => {
//     setRegulationInput(e.target.value);
//   };
//   const handleRegulationsCheckClick = () => {
//     setRegulations(regulationInput);
//     setRegulationInput('');
//   };

//   const EditableField = ({ label, defaultValue }) => {
//     const [value, setValue] = useState(defaultValue);
//     const [inputValue, setInputValue] = useState('');

//     const handleInputChange = (e) => {
//       setInputValue(e.target.value);
//     };

//     const handleCheckClick = () => {
//       setValue(inputValue);
//       setInputValue('');
//     };

//     return (
//       <div className='mb-4'>
//         <p className='mb-2 text-[#212736] font-semibold'>{label}</p>
//         <div
//         // className='flex gap-5 items-center'
//         >
//           {
//             showEdit ?
//             <div className='flex gap-4 items-center'>
//               <input
//                 type='text'
//                 className='border-b-1 px-2 py-1 text-sm min-w-[300px]'
//                 placeholder={`Edit ${label}`}
//                 value={value}
//                 onChange={handleInputChange}
//               />
//               <FaCheck
//                 className='p-2 w-8 h-8 rounded-full bg-green-300 text-green-700 cursor-pointer'
//                 onClick={handleCheckClick}
//               />
//               <IoClose onClick={() => setInputValue('')} className='p-2 w-8 h-8 rounded-full bg-red-300 text-red-700 cursor-pointer' />
//             </div>
//             :
//               <p className='mb-2 text-[#212736]'>{value}</p>
//           }
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div className='bg-white p-5'>
//       <p className='mb-4 text-[#212736]'>Client ID: ignition_innovation.com_76517</p>
//       <EditableField label='Company name' defaultValue='TruMeiD Trust Inc.' />
//       <EditableField label='Legal email' defaultValue='ceo@ignition-innovation.com' />
//       <EditableField label='Registration number' defaultValue='---' />
//       <EditableField label='Business website' defaultValue='www-trumeid.com' />
//       <EditableField label='Business address' defaultValue='United States of America' />
//       <button onClick={() => setShowEdit(!showEdit)} style={showEdit ? { background: 'red' } : {}} className='bg-[#8103CE] py-1 px-3 rounded text-white mb-4'>{showEdit ? 'Cancel' : 'Edit'}</button>
//       <Divider className='mb-6' />
//       <div className='mb-6'>
//         <p className='mb-2 text-[#212736] font-semibold'>Public business name</p>
//         <div className='mb-2'>
//           <div
//           // className='flex gap-5 items-center'
//           >
//             <p className='mb-2 text-[#212736]'>{business}</p>
//             {
//               showBusiness &&
//               <div className='flex gap-4 items-center'>
//                 <input
//                   type='text'
//                   className='border rounded-lg px-2 py-1 text-sm min-w-[300px]'
//                   placeholder="Change Business"
//                   value={bottomInput}
//                   onChange={handleBusinessInputChange}
//                 />
//                 <FaCheck
//                   className='p-2 w-8 h-8 rounded-full bg-green-300 text-green-700 cursor-pointer'
//                   onClick={handleBusinessCheckClick}
//                 />
//                 <IoClose onClick={() => setBottomInput('')} className='p-2 w-8 h-8 rounded-full bg-red-300 text-red-700 cursor-pointer' />
//               </div>
//             }
//           </div>
//         </div>
//         <p onClick={() => setShowBusiness(!showBusiness)} className='mb-2 text-[#8103CE] cursor-pointer' style={showBusiness ? { color: 'red' } : {}} >{showBusiness ? 'Cancel' : 'Change'}</p>
//       </div>
//       <Divider className='mb-6' />
//       <div className='mb-6'>
//         <p className='mb-2 text-[#212736] font-semibold'>Company regulations</p>
//         <div className='mb-2'>
//           <div
//           // className='flex gap-5 items-center'
//           >
//             <p className='mb-2 text-[#212736]'>{regulations}</p>
//             {
//               showRegulations &&
//               <div className='flex gap-4 items-center'>
//                 <input
//                   type='text'
//                   className='border rounded-lg px-2 py-1 text-sm min-w-[300px]'
//                   placeholder="Change Business"
//                   value={regulationInput}
//                   onChange={handleRegulationsInputChange}
//                 />
//                 <FaCheck
//                   className='p-2 w-8 h-8 rounded-full bg-green-300 text-green-700 cursor-pointer'
//                   onClick={handleRegulationsCheckClick}
//                 />
//                 <IoClose onClick={() => setRegulationInput('')} className='p-2 w-8 h-8 rounded-full bg-red-300 text-red-700 cursor-pointer' />
//               </div>
//             }
//           </div>
//         </div>
//         <p onClick={() => setShowRegulations(!showRegulations)} className='mb-2 text-[#8103CE] cursor-pointer'style={showRegulations ? { color: 'red' } : {}} >{showRegulations ? 'Cancel' : 'Change'}</p>
//       </div>
//     </div>
//   );
// };

// export default Business;


import { Divider } from 'antd'
import React, { useState } from 'react'
const Field = ({ label, value, isEditing, onChange, fieldName }) => {
  return (
    <div className='mb-4'>
      <p className='mb-2 text-[#212736] font-semibold'>{label}</p>
      {isEditing ? (
        <input
          type='text'
          name={fieldName}
          className='border-b-1 px-2 py-1 text-md min-w-[300px] outline-none'
          value={value}
          onChange={onChange}
        />
      ) : (
        <p className='mb-2 text-[#212736]'>{value}</p>
      )}
    </div>
  );
};
const Business = () => {
  const initialData = {
    companyName: 'TruMeiD Trust Inc.',
    legalEmail: 'ceo@ignition-innovation.com',
    registrationNumber: '---',
    businessWebsite: 'www-trumeid.com',
    businessAddress: 'United States of America',
  };

  const [data, setData] = useState(initialData);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({ ...initialData });
  const initialState = {
    publicBusinessName: 'TruemiD',
    companyRegulations: 'Company is not regulated',
  };

  const [data2, setData2] = useState(initialState);
  const [editedData2, setEditedData2] = useState({ ...initialState });
  const [isEditing2, setIsEditing2] = useState(false);
  const [isEditing3, setIsEditing3] = useState(false);

  const handleInputChange3 = (e) => {
    const { name, value } = e.target;
    setEditedData2({ ...editedData2, [name]: value });
  };

  const handleApply3 = () => {
    setData2({ ...editedData2 });
    setIsEditing3(false);
  };

  const handleCancel3 = () => {
    setEditedData2({ ...data2 });
    setIsEditing3(false);
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setEditedData2({ ...editedData2, [name]: value });
  };

  const handleApply2 = () => {
    setData2({ ...editedData2 });
    setIsEditing2(false);
  };

  const handleCancel2 = () => {
    setEditedData2({ ...data2 });
    setIsEditing2(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleApply = () => {
    setData({ ...editedData });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedData({ ...data });
  };

  const renderFields = () => {
    return Object.keys(initialData).map((fieldName) => (
      <div key={fieldName} className='mb-6'>
        <Field
          label={fieldName}
          value={isEditing ? editedData[fieldName] : data[fieldName]}
          isEditing={isEditing}
          onChange={handleInputChange}
          fieldName={fieldName}
        />
      </div>
    ));
  };
  return (
    <div className='bg-white p-5'>
      <p className='mb-4 text-[#212736]'>Client ID: ignition_innovation.com_76517</p>
      {renderFields()}
      {isEditing ? (
        <div>
          <button onClick={handleApply} className='bg-[#8103CE] py-1 px-3 rounded text-white mb-4 mr-2'>
            Apply
          </button>
          <button onClick={handleCancel} className='bg-[#8103CE] py-1 px-3 rounded text-white mb-4'>
            Cancel
          </button>
        </div>
      ) : (
        <button onClick={handleEdit} className='bg-[#8103CE] py-1 px-3 rounded text-white mb-4'>
          Edit
        </button>
      )}
      <Divider className='mb-6' />
      <div>
        <p className='mb-2 text-[#212736] font-semibold'>Company regulations</p>
        {isEditing3 ? (
          <React.Fragment>
            <input
              type='text'
              name='publicBusinessName'
              className='border-b-1 px-2 py-1 text-md min-w-[300px] outline-none mb-4'
              value={editedData2.publicBusinessName}
              onChange={handleInputChange3}
            />
            <div>
              <button onClick={handleApply3} className='bg-[#8103CE] py-1 px-3 rounded text-white mb-4 mr-2'>
                Apply
              </button>
              <button onClick={handleCancel3} className='bg-[#8103CE] py-1 px-3 rounded text-white mb-4'>
                Cancel
              </button>
            </div>
          </React.Fragment>
        ) : (
          <p className='mb-2 text-[#212736]'>{data2.publicBusinessName}</p>
        )}
        {isEditing3 || (
          <p className='mb-2 text-[#8103CE] cursor-pointer' onClick={() => setIsEditing3(true)}>
            Change
          </p>
        )}
      </div>
      <Divider className='mb-6' />
      <div>
        <p className='mb-2 text-[#212736] font-semibold'>Company regulations</p>
        {isEditing2 ? (
          <React.Fragment>
            <input
              type='text'
              name='companyRegulations'
              className='border-b-1 px-2 py-1 text-md min-w-[300px] outline-none mb-4'
              value={editedData2.companyRegulations}
              onChange={handleInputChange2}
            />
            <div>
              <button onClick={handleApply2} className='bg-[#8103CE] py-1 px-3 rounded text-white mb-4 mr-2'>
                Apply
              </button>
              <button onClick={handleCancel2} className='bg-[#8103CE] py-1 px-3 rounded text-white mb-4'>
                Cancel
              </button>
            </div>
          </React.Fragment>
        ) : (
          <p className='mb-2 text-[#212736]'>{data2.companyRegulations}</p>
        )}
        {isEditing2 || (
          <p className='mb-2 text-[#8103CE] cursor-pointer' onClick={() => setIsEditing2(true)}>
            Change
          </p>
        )}
      </div>
    </div>
  )
}

export default Business
