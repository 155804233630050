import React, { useState } from 'react';
import Account from './Account';
import Business from './Business';
import Legal from './Legal';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router';
import { FaAngleDown } from "react-icons/fa6";

const Settings = () => {
    const [activeTab, setActiveTab] = useState('Account');
    const [menuVisible, setMenuVisible] = useState(false);
    const navigate = useNavigate();
    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };


    const renderComponent = () => {
        switch (activeTab) {
            case 'Business':
                return <Business />;
            case 'Legal':
                return <Legal />;
            default:
                return <Account />;
        }
    };
    const items = [
        {
            key: '1',
            label: (
                <span className='text-lg' onClick={() => handleTabChange('Account')}>Account</span>
            ),
        },
        {
            key: '2',
            label: (
                <span className='text-lg' onClick={() => handleTabChange('Business')}>Business</span>
            ),
        },
        {
            key: '3',
            label: (
                <span className='text-lg' onClick={() => handleTabChange('Legal')}>Legal</span>
            ),
        },
    ];
    return (
        <div className="flex max-sm:flex-col max-md:flex-col gap-6">
            <div className="w-1/5 max-sm:w-full max-md:w-full h-[70vh] max-sm:hidden max-md:hidden bg-white p-4 py-12">
                <h1 className='text-xl font-bold mb-5'>Settings</h1>
                <ul className="sidebar-menu ">
                    <li
                        className={activeTab === 'Account' ? 'active' : ''}
                        onClick={() => handleTabChange('Account')}
                    >
                        Account
                    </li>
                    <li
                        className={activeTab === 'Business' ? 'active' : ''}
                        onClick={() => handleTabChange('Business')}
                    >
                        Business Information
                    </li>
                    <li
                        className={activeTab === 'Legal' ? 'active' : ''}
                        onClick={() => handleTabChange('Legal')}
                    >
                        Legal Information
                    </li>
                </ul>
            </div>
            <div className='hidden max-sm:block max-md:block'>
                <div className='flex justify-between relative'>
                    <h1 className='text-xl font-bold mb-5'>Settings</h1>
                    <Dropdown
                        menu={{
                            items,
                        }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                {activeTab} <FaAngleDown />
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>

            <div className="w-full">{renderComponent()}</div>

            <style jsx>{`
                .sidebar-menu li {
                    padding: 5px 10px ;
                    cursor: pointer;
                    transition: all 0.3s ease;
                }

                .sidebar-menu li:hover {
                    padding: 5px 10px;
                    font-size: 1.1em;
                }
                
                .sidebar-menu li.active {
                    padding: 5px 10px;
                    font-weight: bold;
                    margin: 20px 0;
                    border-left: 2px solid #8103CE;
                    color: #8103CE;
                }
            `}</style>
        </div>
    );
};

export default Settings;
