import React from "react";
import {Tag, Tooltip, } from "antd";
export const animals = [
  { label: "Select", value: "select" },
  { label: "Afrikaans", value: "af" },
  { label: "Albanian", value: "sq" },
  { label: "Amharic", value: "am" },
  { label: "Arabic", value: "ar" },
  { label: "Armenian", value: "hy" },
  { label: "Azerbaijani", value: "az" },
  { label: "Basque", value: "eu" },
  { label: "Belarusian", value: "be" },
  { label: "Bengali", value: "bn" },
  { label: "Bosnian", value: "bs" },
  { label: "Bulgarian", value: "bg" },
  { label: "Catalan", value: "ca" },
  { label: "Cebuano", value: "ceb" },
  { label: "Chinese (Simplified)", value: "zh" },
  { label: "Chinese (Traditional)", value: "zh-TW" },
  { label: "Corsican", value: "co" },
  { label: "Croatian", value: "hr" },
  { label: "Czech", value: "cs" },
  { label: "Danish", value: "da" },
  { label: "Dutch", value: "nl" },
  { label: "English", value: "en" },
  { label: "Esperanto", value: "eo" },
  { label: "Estonian", value: "et" },
  { label: "Finnish", value: "fi" },
  { label: "French", value: "fr" },
  { label: "Galician", value: "gl" },
  { label: "Georgian", value: "ka" },
  { label: "German", value: "de" },
  { label: "Greek", value: "el" },
  { label: "Gujarati", value: "gu" },
  { label: "Haitian Creole", value: "ht" },
  { label: "Hausa", value: "ha" },
  { label: "Hawaiian", value: "haw" },
  { label: "Hebrew", value: "he" },
  { label: "Hindi", value: "hi" },
  { label: "Hmong", value: "hmn" },
  { label: "Hungarian", value: "hu" },
  { label: "Icelandic", value: "is" },
  { label: "Igbo", value: "ig" },
  { label: "Indonesian", value: "id" },
  { label: "Irish", value: "ga" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Javanese", value: "jv" },
  { label: "Kannada", value: "kn" },
  { label: "Kazakh", value: "kk" },
  { label: "Khmer", value: "km" },
  { label: "Korean", value: "ko" },
  { label: "Kurdish", value: "ku" },
  { label: "Kyrgyz", value: "ky" },
  { label: "Lao", value: "lo" },
  { label: "Latin", value: "la" },
  { label: "Latvian", value: "lv" },
  { label: "Lithuanian", value: "lt" },
  { label: "Luxembourgish", value: "lb" },
  { label: "Macedonian", value: "mk" },
  { label: "Malagasy", value: "mg" },
  { label: "Malay", value: "ms" },
  { label: "Malayalam", value: "ml" },
  { label: "Maltese", value: "mt" },
  { label: "Maori", value: "mi" },
  { label: "Marathi", value: "mr" },
  { label: "Mongolian", value: "mn" },
  { label: "Myanmar (Burmese)", value: "my" },
  { label: "Nepali", value: "ne" },
  { label: "Norwegian", value: "no" },
  { label: "Nyanja (Chichewa)", value: "ny" },
  { label: "Pashto", value: "ps" },
  { label: "Persian", value: "fa" },
  { label: "Polish", value: "pl" },
  { label: "Portuguese", value: "pt" },
  { label: "Punjabi", value: "pa" },
  { label: "Romanian", value: "ro" },
  { label: "Russian", value: "ru" },
  { label: "Samoan", value: "sm" },
  { label: "Scots Gaelic", value: "gd" },
  { label: "Serbian", value: "sr" },
  { label: "Sesotho", value: "st" },
  { label: "Shona", value: "sn" },
  { label: "Sindhi", value: "sd" },
  { label: "Sinhala (Sinhalese)", value: "si" },
  { label: "Slovak", value: "sk" },
  { label: "Slovenian", value: "sl" },
  { label: "Somali", value: "so" },
  { label: "Spanish", value: "es" },
  { label: "Sundanese", value: "su" },
  { label: "Swahili", value: "sw" },
  { label: "Swedish", value: "sv" },
  { label: "Tajik", value: "tg" },
  { label: "Tamil", value: "ta" },
  { label: "Telugu", value: "te" },
  { label: "Thai", value: "th" },
  { label: "Turkish", value: "tr" },
  { label: "Ukrainian", value: "uk" },
  { label: "Urdu", value: "ur" },
  { label: "Uzbek", value: "uz" },
  { label: "Vietnamese", value: "vi" },
  { label: "Welsh", value: "cy" },
  { label: "Xhosa", value: "xh" },
  { label: "Yiddish", value: "yi" },
  { label: "Yoruba", value: "yo" },
  { label: "Zulu", value: "zu" },
  { label: "Other", value: "other" },
];

export const columns = [
  {
    title: "Comments",
    dataIndex: "comment",
    key: "comment",
    render: (text) => (
      <>
        <Tooltip title={text}>
          <div className="whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[300px]">
            {text}
          </div>
        </Tooltip>
      </>
    ),
  },
  {
    title: "Risk Level",
    dataIndex: "level",
    key: "level",
    render: (riskLevel) => (
      <>
        {riskLevel === "low" && <Tag color="green">Low</Tag>}
        {riskLevel === "medium" && <Tag color="yellow">Medium</Tag>}
        {riskLevel === "high" && <Tag color="red">High</Tag>}
      </>
    ),
  },
];

export const rejectionResaons =
[
    {
        "Button": "Spam Issues",
        "GroupID": "spam",
        "ButtonID": "spam",
        "AssociatedRejectionLabel": "spam",
        "Description": "The applicant uploaded irrelevant images or failed to provide the required documents 6 or more times."
    },
    {
        "Button": "Red Crypto Issues",
        "GroupID": "redCrypto",
        "ButtonID": "redCrypto",
        "AssociatedRejectionLabel": "redCrypto",
        "Description": "The provided e-wallet has a high risk score."
    },
    {
        "Button": "Black Crypto Issues",
        "GroupID": "blackCrypto",
        "ButtonID": "blackCrypto",
        "AssociatedRejectionLabel": "blackCrypto",
        "Description": "The crypto wallet address is incorrect."
    },
    {
        "Button": "Different Docs Issues",
        "GroupID": "differentDocs",
        "ButtonID": "differentDocs",
        "AssociatedRejectionLabel": "differentDocs",
        "Description": "The documents or means of payment submitted by the applicant belong to different people."
    },
    {
        "Button": "Payments Issues",
        "GroupID": "paymentsIssues",
        "ButtonID": "paymentsIssues",
        "AssociatedRejectionLabel": "paymentsIssues",
        "Description": "The provided payment method was rejected due to high risk."
    },
    {
        "Button": "Regulations Violations - Age",
        "GroupID": "regulationsViolations",
        "ButtonID": "age",
        "AssociatedRejectionLabel": "age",
        "Description": "The applicant does not meet the client's age requirements."
    },
    {
        "Button": "Regulations Violations - Duplicate",
        "GroupID": "regulationsViolations",
        "ButtonID": "duplicate",
        "AssociatedRejectionLabel": "duplicate",
        "Description": "By default, one applicant can have only one active account."
    },
    {
        "Button": "Regulations Violations - Wrong Region",
        "GroupID": "regulationsViolations",
        "ButtonID": "wrongRegion",
        "AssociatedRejectionLabel": "wrongRegion",
        "Description": "The applicant is from a region or a country that is not supported by the client."
    },
    {
        "Button": "Regulations Violations - Inconsistency",
        "GroupID": "regulationsViolations",
        "ButtonID": "inconsistency",
        "AssociatedRejectionLabel": "inconsistency",
        "Description": "The bank card holder name does not match the name on the applicant ID."
    },
    {
        "Button": "Regulations Violations - Countries Mismatch",
        "GroupID": "regulationsViolations",
        "ButtonID": "countriesMismatch",
        "AssociatedRejectionLabel": "countriesMismatch",
        "Description": "The applicant country of residence does not match the bank card registration country."
    },
    {
        "Button": "Regulations Violations - Risk Bank Card",
        "GroupID": "regulationsViolations",
        "ButtonID": "riskBankCard",
        "AssociatedRejectionLabel": "riskBankCard",
        "Description": "The provided bank card has a high risk score."
    },
    {
        "Button": "Regulations Violations - Spam",
        "GroupID": "regulationsViolations",
        "ButtonID": "spam",
        "AssociatedRejectionLabel": "spam",
        "Description": "The applicant uploaded irrelevant images or failed to provide the required documents 6 or more times."
    },
    {
        "Button": "Regulations Violations - High Risk Profile",
        "GroupID": "regulationsViolations",
        "ButtonID": "highRiskProfile",
        "AssociatedRejectionLabel": "highRiskProfile",
        "Description": "The system has detected suspicious applicant behavior such as high-risk IP, using multiple devices, etc."
    },
    {
        "Button": "Regulations Violations - Gender",
        "GroupID": "regulationsViolations",
        "ButtonID": "gender",
        "AssociatedRejectionLabel": "gender",
        "Description": "The gender in the applicant profile does not match the gender in the document uploaded by the applicant."
    },
    {
        "Button": "Regulations Violations - Unsupported Language",
        "GroupID": "regulationsViolations",
        "ButtonID": "unsupportedLanguage",
        "AssociatedRejectionLabel": "unsupportedLanguage",
        "Description": "The applicant doesn't speak any of the supported languages."
    },
    {
        "Button": "Data Mismatch - Full Name",
        "key":"PROBLEMATIC_APPLICANT_DATA",
        "GroupID": "dataMismatch",
        "ButtonID": "dataMismatch_fullName",
        "AssociatedRejectionLabel": "fullName",
        "Description": "The full name on the profile is either missing or incorrect."
    },
    {
        "Button": "Data Mismatch - Date of Birth",
        "key":"PROBLEMATIC_APPLICANT_DATA",
        "GroupID": "dataMismatch",
        "ButtonID": "dataMismatch_dateOfBirth",
        "AssociatedRejectionLabel": "dateOfBirth",
        "Description": "The date of birth on the profile is either missing or incorrect."
    },
    {
        "Button": "Data Mismatch - Address",
        "key":"PROBLEMATIC_APPLICANT_DATA",
        "GroupID": "dataMismatch",
        "ButtonID": "dataMismatch_address",
        "AssociatedRejectionLabel": "address",
        "Description": "There was an issue with the address on the profile."
    },
    {
        "Button": "Data Mismatch - Gender",
        "key":"PROBLEMATIC_APPLICANT_DATA",
        "GroupID": "dataMismatch",
        "ButtonID": "dataMismatch_gender",
        "AssociatedRejectionLabel": "gender",
        "Description": "The gender in the applicant profile does not match the gender in the document uploaded by the applicant."
    },
    {
        "Button": "Fraudulent Patterns - Selfie Mismatch",
        "GroupID": "fraudulentPatterns",
        "ButtonID": "selfieMismatch",
        "AssociatedRejectionLabel": "selfieMismatch",
        "Description": "Selfie does not match the picture in the document."
    },
    {
        "Button": "Fraudulent Patterns - Fake",
        "GroupID": "fraudulentPatterns",
        "ButtonID": "fake",
        "AssociatedRejectionLabel": "fake",
        "Description": "Suspected fraudulent account."
    },
    {
        "Button": "Fraudulent Patterns - Blocklist",
        "GroupID": "fraudulentPatterns",
        "ButtonID": "blocklist",
        "AssociatedRejectionLabel": "blocklist",
        "Description": "User account is suspected to be fake. We found it in your blocklist."
    },
    {
        "Button": "Fraudulent Patterns - High Risk Profile",
        "GroupID": "fraudulentPatterns",
        "ButtonID": "highRiskProfile",
        "AssociatedRejectionLabel": "highRiskProfile",
        "Description": "High behavioral risk scoring."
    },
    {
        "Button": "Fraudulent Patterns - Liveness Forced",
        "GroupID": "fraudulentPatterns",
        "ButtonID": "livenessForced",
        "AssociatedRejectionLabel": "livenessForced",
        "Description": "User was forced to pass liveness."
    },
    {
        "Button": "Fraudulent Patterns - Deep Fake",
        "GroupID": "fraudulentPatterns",
        "ButtonID": "deepFake",
        "AssociatedRejectionLabel": "deepFake",
        "Description": "An attempt to bypass liveness check with deep fake."
    },
    {
        "Button": "Fraudulent Patterns - Template",
        "GroupID": "fraudulentPatterns",
        "ButtonID": "template",
        "AssociatedRejectionLabel": "template",
        "Description": "Suspected tampered data."
    },
    {
        "Button": "DB Net Checks - Incorrect TIN",
        "GroupID": "dbNetChecks",
        "ButtonID": "dbNetChecks_incorrectTin",
        "AssociatedRejectionLabel": "incorrectTin",
        "Description": "The tax number provided by the applicant is incorrect."
    },
    {
        "Button": "DB Net Checks - Incorrect SSN",
        "GroupID": "dbNetChecks",
        "ButtonID": "dbNetChecks_incorrectSsn",
        "AssociatedRejectionLabel": "incorrectSsn",
        "Description": "The SSN number provided by the applicant is incorrect."
    },
    {
        "Button": "DB Net Checks - Incorrect BVN",
        "GroupID": "dbNetChecks_dbNetChecks",
        "ButtonID": "incorrectBvn",
        "AssociatedRejectionLabel": "incorrectBvn",
        "Description": "The BVN number provided by the applicant is incorrect."
    },
    {
        "Button": "Compromised Persons - PEP",
        "GroupID": "compromisedPersons",
        "ButtonID": "pep",
        "AssociatedRejectionLabel": "pep",
        "Description": "The applicant is a politically exposed person. This means that they are currently in a position of great control or have been in the past."
    },
    {
        "Button": "Compromised Persons - Sanction List",
        "GroupID": "compromisedPersons",
        "ButtonID": "sanctionList",
        "AssociatedRejectionLabel": "sanctionList",
        "Description": "The applicant was mentioned on sanctions lists."
    },
    {
        "Button": "Compromised Persons - Criminal Records",
        "GroupID": "compromisedPersons",
        "ButtonID": "criminalRecords",
        "AssociatedRejectionLabel": "criminalRecords",
        "Description": "The applicant is a convicted criminal."
    },
    {
        "Button": "Compromised Persons - Incomplete Name",
        "GroupID": "compromisedPersons",
        "ButtonID": "incompleteName",
        "AssociatedRejectionLabel": "incompleteName",
        "Description": "The name the applicant entered is incomplete, so the system could not match it against the watchlists properly."
    },
    {
        "Button": "Compromised Persons - Adverse Media",
        "GroupID": "compromisedPersons",
        "ButtonID": "adverseMedia",
        "AssociatedRejectionLabel": "adverseMedia",
        "Description": "The applicant is mentioned in adverse media."
    },
    {
        "Button": "Company - More Docs",
        "GroupID": "company",
        "ButtonID": "moreDocs",
        "AssociatedRejectionLabel": "moreDocs",
        "Description": "Sumsub needs more documents to process with verification."
    },
    {
        "Button": "Company - Control Structure Not Established",
        "GroupID": "company",
        "ButtonID": "controlStructureIsNotEstablished",
        "AssociatedRejectionLabel": "controlStructureIsNotEstablished",
        "Description": "Sumsub could not establish the verified entity's control structure."
    },
    {
        "Button": "Company - Not Validated Beneficiaries",
        "GroupID": "company",
        "ButtonID": "notValidatedBeneficiaries",
        "AssociatedRejectionLabel": "notValidatedBeneficiaries",
        "Description": "Sumsub could not validate Beneficiaries."
    },
    {
        "Button": "Company - Not Determined Representatives",
        "GroupID": "company",
        "ButtonID": "notDeterminedRepresentatives",
        "AssociatedRejectionLabel": "notDeterminedRepresentatives",
        "Description": "Sumsub could not validate the verified entity's representatives."
    },
    {
        "Button": "Company - Not Validated Representatives",
        "GroupID": "company",
        "ButtonID": "notValidatedRepresentatives",
        "AssociatedRejectionLabel": "notValidatedRepresentatives",
        "Description": "Sumsub could not identify or properly verify the verified entity's representatives."
    },
    {
        "Button": "Additional Pages - Main Page ID",
        "GroupID": "additionalPages",
        "ButtonID": "mainPageId",
        "AssociatedRejectionLabel": "mainPageId",
        "Description": "The uploaded document is missing the biodata page."
    },
    {
        "Button": "Additional Pages - Next Page",
        "GroupID": "additionalPages",
        "ButtonID": "nextPage",
        "AssociatedRejectionLabel": "nextPage",
        "Description": "The uploaded document is missing a page."
    },
    {
        "Button": "Additional Pages - Registration Stamp",
        "GroupID": "additionalPages",
        "ButtonID": "registrationStamp",
        "AssociatedRejectionLabel": "registrationStamp",
        "Description": "The uploaded document is missing the address page."
    },
    {
        "Button": "Additional Pages - Expiration Page",
        "GroupID": "additionalPages",
        "ButtonID": "expirationPage",
        "AssociatedRejectionLabel": "expirationPage",
        "Description": "The uploaded document is missing the expiration date page."
    },
    {
        "Button": "Additional Pages - Another Side",
        "GroupID": "additionalPages",
        "ButtonID": "anotherSide",
        "AssociatedRejectionLabel": "anotherSide",
        "Description": "The uploaded document is double-sided and only one side was uploaded."
    },
    {
        "Button": "Bad Document - Expired ID",
        "GroupID": "badDocument",
        "ButtonID": "expiredId",
        "AssociatedRejectionLabel": "expiredId",
        "Description": "The provided identity document has expired."
    },
    {
        "Button": "Bad Document - Invalid ID",
        "GroupID": "badDocument",
        "ButtonID": "invalidId",
        "AssociatedRejectionLabel": "invalidId",
        "Description": "The provided identity document is invalid."
    },
    {
        "Button": "Bad Document - Damaged ID",
        "GroupID": "badDocument",
        "ButtonID": "damagedId",
        "AssociatedRejectionLabel": "damagedId",
        "Description": "The provided ID document is damaged to the extent that it makes verification data, such as name or MRZ, unreadable (e.g. the ID is stained or scratched)."
    },
    {
        "Button": "Bad Document - Wrong Type",
        "GroupID": "badDocument",
        "ButtonID": "wrongType",
        "AssociatedRejectionLabel": "wrongType",
        "Description": "The uploaded document can not be accepted for verification."
    },
    {
        "Button": "Bad Document - Not Full Name or DOB",
        "GroupID": "badDocument",
        "ButtonID": "notFullNameOrDob",
        "AssociatedRejectionLabel": "notFullNameOrDob",
        "Description": "Applicant full name or date of birth is not present in the provided document."
    },
    {
        "Button": "Bad Document - Without Face",
        "GroupID": "badDocument",
        "ButtonID": "withoutFace",
        "AssociatedRejectionLabel": "withoutFace",
        "Description": "The face of the document’s owner is poorly visible, making face match impossible."
    },
    {
        "Button": "Bad Document - Data Not Visible",
        "GroupID": "badDocument",
        "ButtonID": "dataNotVisible",
        "AssociatedRejectionLabel": "dataNotVisible",
        "Description": "The information on the uploaded photo is illegible. The image may be blurred or cropped, making it impossible to read the needed data (for example name or MRZ)."
    },
    {
        "Button": "Bad Document - Copy of ID Doc",
        "GroupID": "badDocument",
        "ButtonID": "copyOfIdDoc",
        "AssociatedRejectionLabel": "copyOfIdDoc",
        "Description": "The applicant has uploaded a photo of a paper copy of their document instead of the original document, which is not acceptable."
    },
    {
        "Button": "Bad Document - No Stamp",
        "GroupID": "badDocument",
        "ButtonID": "noStamp",
        "AssociatedRejectionLabel": "noStamp",
        "Description": "The applicant uploaded a document that does not have the stamp of the issuing organization."
    },
    {
        "Button": "Bad Document - Unsigned",
        "GroupID": "badDocument",
        "ButtonID": "unsigned",
        "AssociatedRejectionLabel": "unsigned",
        "Description": "The applicant uploaded a document without relevant signatures and stamps."
    },
    {
        "Button": "Bad Document - Need Translation",
        "GroupID": "badDocument",
        "ButtonID": "needTranslation",
        "AssociatedRejectionLabel": "needTranslation",
        "Description": "The applicant uploaded a document in a language that is not supported by Sumsub, so the notarized English translation has been requested."
    },
    {
        "Button": "Bad Document - Expiration Date",
        "GroupID": "badDocument",
        "ButtonID": "expirationDate",
        "AssociatedRejectionLabel": "expirationDate",
        "Description": "The provided identity document expires in less than one month."
    },
    {
        "Button": "Bad Document - Digital ID",
        "GroupID": "badDocument",
        "ButtonID": "digitalId",
        "AssociatedRejectionLabel": "digitalId",
        "Description": "The applicant uploaded a digital version of the document."
    },
    {
        "Button": "Bad Photo - Low Quality",
        "GroupID": "badPhoto",
        "ButtonID": "lowQuality",
        "AssociatedRejectionLabel": "lowQuality",
        "Description": "The information on the ID document is not visible or can not be read."
    },
    {
        "Button": "Bad Photo - Edited PoA",
        "GroupID": "badPhoto",
        "ButtonID": "editedPoa",
        "AssociatedRejectionLabel": "editedPoa",
        "Description": "The uploaded photo displays signs of tampering via a digital editor."
    },
    {
        "Button": "Bad Photo - Screenshot",
        "GroupID": "badPhoto",
        "ButtonID": "screenshot",
        "AssociatedRejectionLabel": "screenshot",
        "Description": "The applicant uploaded screenshots instead of photos or scans."
    },
    {
        "Button": "Bad Photo - Black and White",
        "GroupID": "badPhoto",
        "ButtonID": "blackAndWhite",
        "AssociatedRejectionLabel": "blackAndWhite",
        "Description": "The applicant uploaded black and white photos of a document instead of color photos."
    },
    {
        "Button": "Bad Photo - Image Editor",
        "GroupID": "badPhoto",
        "ButtonID": "imageEditor",
        "AssociatedRejectionLabel": "imageEditor",
        "Description": "The photo displays signs of tampering via a digital editor. The purpose of the editor could be anything from resizing to forgery."
    },
    {
        "Button": "Bad Photo - Incomplete",
        "GroupID": "badPhoto",
        "ButtonID": "incomplete",
        "AssociatedRejectionLabel": "incomplete",
        "Description": "Part of the document (document corners) is not visible."
    },
    {
        "Button": "Bad Photo - Data Not Visible",
        "GroupID": "badPhoto",
        "ButtonID": "dataNotVisible",
        "AssociatedRejectionLabel": "dataNotVisible",
        "Description": "The information on the uploaded photo is illegible. The image may be blurred or cropped, making it impossible to read the needed data (for example name or MRZ)."
    },
    {
        "Button": "Bad Photo - Sticker",
        "GroupID": "badPhoto",
        "ButtonID": "sticker",
        "AssociatedRejectionLabel": "sticker",
        "Description": "The uploaded photo displays signs of tampering via a digital editor."
    },
    {
        "Button": "Selfie - Low Quality",
        "GroupID": "selfie",
        "ButtonID": "lowQuality",
        "AssociatedRejectionLabel": "lowQuality",
        "Description": "The information on the ID document is not visible or can not be read."
    },
    {
        "Button": "Selfie - Selfie with ID",
        "GroupID": "selfie",
        "ButtonID": "selfieWithId",
        "AssociatedRejectionLabel": "selfieWithId",
        "Description": "The applicant uploaded a selfie without the ID document."
    },
    {
        "Button": "Selfie - Webcam Selfie",
        "GroupID": "selfie",
        "ButtonID": "webcamSelfie",
        "AssociatedRejectionLabel": "webcamSelfie",
        "Description": "The applicant failed the Liveness check."
    },
    {
        "Button": "Selfie - Selfie with Another ID",
        "GroupID": "selfie",
        "ButtonID": "selfieWithAnotherId",
        "AssociatedRejectionLabel": "selfieWithAnotherId",
        "Description": "The ID document in the selfie is different from the ID document uploaded by the applicant."
    },
    {
        "Button": "Selfie - Selfie with New ID",
        "GroupID": "selfie",
        "ButtonID": "selfieWithNewId",
        "AssociatedRejectionLabel": "selfieWithNewId",
        "Description": "The ID document in the selfie can not be detected or read."
    },
    {
        "Button": "Selfie - Bad Face Comparison",
        "GroupID": "selfie",
        "ButtonID": "badFaceComparison",
        "AssociatedRejectionLabel": "badFaceComparison",
        "Description": "The applicant's face on the selfie is not clearly visible and can not be matched against the photo in the ID document."
    },
    {
        "Button": "Selfie - Selfie Liveness",
        "GroupID": "selfie",
        "ButtonID": "selfieLiveness",
        "AssociatedRejectionLabel": "selfieLiveness",
        "Description": "The applicant failed the Liveness check."
    },
    {
        "Button": "Selfie - Liveness Watermark",
        "GroupID": "selfie",
        "ButtonID": "livenessWatermark",
        "AssociatedRejectionLabel": "livenessWatermark",
        "Description": "There is camera text or a watermark on the Liveness record originating from the proprietary software."
    },
    {
        "Button": "Video Selfie - Low Quality",
        "GroupID": "videoSelfie",
        "ButtonID": "lowQuality",
        "AssociatedRejectionLabel": "lowQuality",
        "Description": "The information on the ID document is not visible or can not be read."
    },
    {
        "Button": "Video Selfie - Video Without Face",
        "GroupID": "videoSelfie",
        "ButtonID": "videoWithoutFace",
        "AssociatedRejectionLabel": "videoWithoutFace",
        "Description": "The pronounced phrase does not match the provided text."
    },
    {
        "Button": "Video Selfie - Spoken Phrase Mismatch",
        "GroupID": "videoSelfie",
        "ButtonID": "spokenPhraseMismatch",
        "AssociatedRejectionLabel": "spokenPhraseMismatch",
        "Description": "The applicant's face can not be detected on the selfie."
    },
    {
        "Button": "Proof of Address - Full Name",
        "GroupID": "proofOfAddress",
        "ButtonID": "fullName",
        "AssociatedRejectionLabel": "fullName",
        "Description": "The full name of the applicant has not been provided on the document or can’t be read."
    },
    {
        "Button": "Proof of Address - Full Address",
        "GroupID": "proofOfAddress",
        "ButtonID": "fullAddress",
        "AssociatedRejectionLabel": "fullAddress",
        "Description": "The full address of the applicant has not been provided on the document or can’t be read."
    },
    {
        "Button": "Proof of Address - Issue Date",
        "GroupID": "proofOfAddress",
        "ButtonID": "issueDate",
        "AssociatedRejectionLabel": "issueDate",
        "Description": "There is no issue date on the document uploaded by the applicant."
    },
    {
        "Button": "Proof of Address - List of Docs",
        "GroupID": "proofOfAddress",
        "ButtonID": "listOfDocs",
        "AssociatedRejectionLabel": "listOfDocs",
        "Description": "The applicant uploaded a document that is not supported by Sumsub."
    },
    {
        "Button": "Proof of Address - Data Mismatch",
        "GroupID": "proofOfAddress",
        "ButtonID": "dataMismatch",
        "AssociatedRejectionLabel": "dataMismatch",
        "Description": "The address on the document uploaded by the applicant does not match the address entered by the applicant."
    },
    {
        "Button": "Proof of Address - Same Doc",
        "GroupID": "proofOfAddress",
        "ButtonID": "sameDoc",
        "AssociatedRejectionLabel": "sameDoc",
        "Description": "The applicant uploaded the same document as PoA and PoI."
    },
    {
        "Button": "Proof of Address - Certified Form",
        "GroupID": "proofOfAddress",
        "ButtonID": "certifiedForm",
        "AssociatedRejectionLabel": "certifiedForm",
        "Description": "There is no stamp/signature of the issuing organization on the document uploaded by the applicant."
    },
    {
        "Button": "Proof of Address - Not Enough Data",
        "GroupID": "proofOfAddress",
        "ButtonID": "notEnoughData",
        "AssociatedRejectionLabel": "notEnoughData",
        "Description": "The account/card number on the bank statement is missing or can not be read."
    },
    {
        "Button": "Proof of Payment - Bank Card",
        "GroupID": "proofOfPayment",
        "ButtonID": "bankCard",
        "AssociatedRejectionLabel": "bankCard",
        "Description": "The information on the bank card is missing or can’t be read."
    },
    {
        "Button": "Proof of Payment - Bank Statement",
        "GroupID": "proofOfPayment",
        "ButtonID": "bankStatement",
        "AssociatedRejectionLabel": "bankStatement",
        "Description": "The information on the bank statement is missing or can’t be read."
    },
    {
        "Button": "Proof of Payment - E-wallet",
        "GroupID": "proofOfPayment",
        "ButtonID": "e-wallet",
        "AssociatedRejectionLabel": "e-wallet",
        "Description": "The e-wallet needs to be confirmed."
    },
    {
        "Button": "Proof of Payment - Wire Transfer",
        "GroupID": "proofOfPayment",
        "ButtonID": "wireTransfer",
        "AssociatedRejectionLabel": "wireTransfer",
        "Description": "The wire transfer needs to be confirmed."
    },
    {
        "Button": "Proof of Payment - Bank Account",
        "GroupID": "proofOfPayment",
        "ButtonID": "bankAccount",
        "AssociatedRejectionLabel": "bankAccount",
        "Description": "The information about the bank account is missing."
    },
    {
        "Button": "Proof of Payment - Data Mismatch",
        "GroupID": "proofOfPayment",
        "ButtonID": "dataMismatch",
        "AssociatedRejectionLabel": "dataMismatch",
        "Description": "The address on the document uploaded by the applicant does not match the address entered by the applicant."
    },
    {
        "Button": "Bank Card - Full Name Issue",
        "GroupID": "bankCard",
        "ButtonID": "fullNameIssue",
        "AssociatedRejectionLabel": "fullNameIssue",
        "Description": "The name on the bank card is missing."
    },
    {
        "Button": "Bank Card - Expiration Date",
        "GroupID": "bankCard",
        "ButtonID": "expirationDate",
        "AssociatedRejectionLabel": "expirationDate",
        "Description": "The provided bank card has expired or expires soon."
    },
    {
        "Button": "Bank Card - No Card",
        "GroupID": "bankCard",
        "ButtonID": "noCard",
        "AssociatedRejectionLabel": "noCard",
        "Description": "The applicant did not upload a bank card."
    },
    {
        "Button": "Bank Card - Copy",
        "GroupID": "bankCard",
        "ButtonID": "copy",
        "AssociatedRejectionLabel": "copy",
        "Description": "The applicant has uploaded a photo of a copy of their bank card instead of the original card, which is not acceptable."
    },
    {
        "Button": "Bank Card - Poor Quality",
        "GroupID": "bankCard",
        "ButtonID": "poorQuality",
        "AssociatedRejectionLabel": "poorQuality",
        "Description": "The photo of the bank card is of poor quality, making the information unreadable."
    },
    {
        "Button": "Bank Card - No Bank Name",
        "GroupID": "bankCard",
        "ButtonID": "noBankName",
        "AssociatedRejectionLabel": "noBankName",
        "Description": "The name of the issuing bank is missing on the bank card."
    }
];

export const imageRequest = {
  "Bad Photo": {
    key: "SCREENSHOTS",
    type: "RETRY",
    items: [
      { label: "Full name issue", message: "Enter Your Full Name", internalMessage: "Full Name is required" },
      { label: "Date of birth issue", message: "Enter Your Date of Birth", internalMessage: "Date of Birth is required" },
      { label: "Gender mismatch", message: "Enter Your Gender", internalMessage: "Gender is required" },
      { label: "Address issue", message: "Enter Your Address", internalMessage: "Address is required" },
    ],
  },
  "Additional checks": {
    key: "UNSATISFACTORY_PHOTOS",
    type: "RETRY",
    items: [
      { label: "Unsuported langauge", message: "You entered Unsuported langauge", internalMessage: "Unsuported langauge" },
      { label: "3rd Force Involvement", message: "3rd Force Involvement", internalMessage: "3rd Force Involvement" },
      { label: "Sponsered Registration", message: "Sponsered Registration", internalMessage: "Sponsered Registration" },
    ],
  },
  "Addditional Pages": {
    key: "DOCUMENT_PAGE_MISSING",
    type: "RETRY",
    items: [
      { label: "Age", message: "Enter Your Age", internalMessage: "Age is required" },
      { label: "Duplicate", message: "Duplicate", internalMessage: "Duplicate" },
      { label: "Wrong Region", message: "Wrong Region", internalMessage: "Wrong Region" },
      { label: "Wrong residency", message: "Wrong residency", internalMessage: "Wrong residency" },
    ],
  },
  "Bad Document": {
    key: "BAD_PROOF_OF_IDENTITY",
    type: "RETRY",
    items: [
      { label: "Incorrect TIM", message: "Enter Your TIM", internalMessage: "TIM is required" },
    ],
  },
  "Different Docs": {
    key: "ID_INVALID",
    type: "FINAL",
    items: [
      { label: "Incorrect TIM", message: "Enter Your TIM", internalMessage: "TIM is required" },
    ],
  },
  "Regulation violation": {
    key: "REGULATIONS_VIOLATIONS",
    type: "FINAL",
    items: [
      { label: "Different people", message: "Different people", internalMessage: "Different people" },
      { label: "Bypass attempts", message: "Bypass attempts", internalMessage: "Bypass attempts" }
    ],
  },
  "Suspected Fraud": {
    key: "BLACKLIST",
    type: "FINAL",
    items: [
      { label: "Compromised persons", message: "Enter Your Compromised persons", internalMessage: "Compromised persons is required" }
    ],
  },
  "Fake": {
    key: "INCONSISTENT_PROFILE",
    type: "FINAL",
    items: [
      { label: "Different docs", message: "Enter Your Different docs", internalMessage: "Different docs is required" }
    ],
  },
  "Spam(5)": {
    key: "SPAM",
    type: "RETRY",
    items: [
      { label: "Spam(5)", message: "Spam(5)", internalMessage: "Spam(5)" }
    ],
  },
  "UnSigned document": {
    key: "INCONSISTENT_PROFILE",
    type: "RETRY",
    items: [
      { label: "E-KYC", message: "Enter Your E-KYC", internalMessage: "E-KYC is required" }
    ],
  },
};

export const resubmissionRequest = {
  "Data mismatch": {
    key: "PROBLEMATIC_APPLICANT_DATA",
    type: "RETRY",
    items: [
      { label: "Full name issue", message: "Enter Your Full Name", internalMessage: "Full Name is required" },
      { label: "Date of birth issue", message: "Enter Your Date of Birth", internalMessage: "Date of Birth is required" },
      { label: "Gender mismatch", message: "Enter Your Gender", internalMessage: "Gender is required" },
      { label: "Address issue", message: "Enter Your Address", internalMessage: "Address is required" },
    ],
  },
  "Additional checks": {
    key: "INCORRECT_SOCIAL_NUMBER",
    type: "RETRY",
    items: [
      { label: "Unsuported langauge", message: "You entered Unsuported langauge", internalMessage: "Unsuported langauge" },
      { label: "3rd Force Involvement", message: "3rd Force Involvement", internalMessage: "3rd Force Involvement" },
      { label: "Sponsered Registration", message: "Sponsered Registration", internalMessage: "Sponsered Registration" },
    ],
  },
  "Videoident": {
    key: "UNSUITABLE_ENV",
    type: "FINAL",
    items: [
      { label: "Age", message: "Enter Your Age", internalMessage: "Age is required" },
      { label: "Duplicate", message: "Duplicate", internalMessage: "Duplicate" },
      { label: "Wrong Region", message: "Wrong Region", internalMessage: "Wrong Region" },
      { label: "Wrong residency", message: "Wrong residency", internalMessage: "Wrong residency" },
    ],
  },
  "VideoIdent-final": {
    key: "UNSUPPORTED_LANGUAGE",
    type: "RETRY",
    items: [
      { label: "Incorrect TIM", message: "Enter Your TIM", internalMessage: "TIM is required" },
      { label: "Incorrect SSN", message: "Enter Your SSN", internalMessage: "SSN is required" },
      { label: "Incorrect BVN", message: "Enter Your BVN", internalMessage: "BVN is required" },
    ],
  },
  "Regulation violation": {
    key: "AGE_REQUIREMENT_MISMATCH",
    type: "FINAL",
    items: [
      { label: "Incorrect TIM", message: "Enter Your TIM", internalMessage: "TIM is required" },
      { label: "Incorrect SSN", message: "Enter Your SSN", internalMessage: "SSN is required" },
      { label: "Incorrect BVN", message: "Enter Your BVN", internalMessage: "BVN is required" },
    ],
  },
  "Suspected fraud": {
    key: "FORGERY",
    type: "RETRY",
    items: [
      { label: "Incorrect TIM", message: "Enter Your TIM", internalMessage: "TIM is required" },
      { label: "Incorrect SSN", message: "Enter Your SSN", internalMessage: "SSN is required" },
      { label: "Incorrect BVN", message: "Enter Your BVN", internalMessage: "BVN is required" },
    ],
  },
  "Selfie issue": {
    key: "FRAUDULENT_LIVENESS",
    type: "FINAL",
    items: [
      { label: "Different people", message: "Different people", internalMessage: "Different people" },
      { label: "Bypass attempts", message: "Bypass attempts", internalMessage: "Bypass attempts" }
    ],
  },
  "Compromised persons": {
    key: "COMPROMISED_PERSONS",
    type: "RETRY",
    items: [
      { label: "Compromised persons", message: "Enter Your Compromised persons", internalMessage: "Compromised persons is required" }
    ],
  },
  "Different docs": {
    key: "INCONSISTENT_PROFILE",
    type: "FINAL",
    items: [
      { label: "Different docs", message: "Enter Your Different docs", internalMessage: "Different docs is required" }
    ],
  },
  "Spam(5)": {
    key: "SPAM",
    type: "RETRY",
    items: [
      { label: "Spam(5)", message: "Spam(5)", internalMessage: "Spam(5)" }
    ],
  },
  "E-KYC": {
    key: "CHECK_UNAVAILABLE",
    type: "FINAL",
    items: [
      { label: "E-KYC", message: "Enter Your E-KYC", internalMessage: "E-KYC is required" }
    ],
  },
  "E KYC RETRY": {
    key: "PEP",
    type: "RETRY",
    items: [
      { label: "E KYC RETRY", message: "Enter Your E KYC RETRY", internalMessage: "E KYC RETRY is required" }
    ],
  },
  "Eternal database FINALs": {
    key: "FRAUDULENT_PATTERNS",
    type: "FINAL",
    items: [
      { label: "External database FINALs", message: "Enter Your Eternal database FINALs", internalMessage: "Eternal database FINALs is required" }
    ],
  },
};

