import React, { useEffect, useState } from 'react';
import Sun from '../../images/sun.png'
import { LuUsers2 } from 'react-icons/lu';
import Chart from '../Chart/LineChart'
import BarChart from '../Chart/BarChart'
import { IoMdAdd } from "react-icons/io";
import { FaArrowTrendDown } from "react-icons/fa6";
import { TbClockX } from "react-icons/tb";
import { RxStopwatch } from "react-icons/rx";
import { BiErrorAlt } from "react-icons/bi";
import { IoMoonOutline } from "react-icons/io5";
import { LuCalendarClock } from "react-icons/lu";
import axios from 'axios';
import { Spin } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
function Dashboard() {
  const [candidates, setCandidates] = useState(null)
  const [time, setTime] = useState(new Date());
  const navigate = useNavigate()
  const now = new Date();

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    let formattedDate = date.toLocaleDateString('en-US', options);
    const day = date.getDate();
    let suffix = 'th';
    if (day < 11 || day > 20) {
      switch (day % 10) {
        case 1:
          suffix = 'st';
          break;
        case 2:
          suffix = 'nd';
          break;
        case 3:
          suffix = 'rd';
          break;
        default:
          break;
      }
    }

    return formattedDate.replace(/(\d+)(th)?/, `$1${suffix}`);
  };
  useEffect(() => {
    const timerID = setInterval(
      () => tick(),
      1000 // Update time every second
    );

    return () => {
      clearInterval(timerID); // Clear interval on component unmount
    };
  }, []);

  const tick = () => {
    setTime(new Date());
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    });
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('authToken')
    getCandidates(accessToken)
  }, [])

  const getCandidates = async (accessToken) => {
    try {

      const response = await axios.get('/applicant/get-all-applicants', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      setCandidates(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  const allApplicants = (statusType, subtype) => {
    const queryParams = new URLSearchParams();
    if (statusType) {
      queryParams.set('status', statusType);
    }
    if (subtype) {
      queryParams.set('subtype', subtype);
    }
    navigate(`/applicant?${queryParams.toString()}`);
  };
  
  
  return (
    <div className='bg-[#F1F2F6] dashboard'>
      <div className='grid grid-cols-4 max-sm:grid-cols-1 max-md:grid-cols-1 gap-6 mb-10'>

        <div className='grid gap-10 bg-white p-5 px-10 rounded-2xl'>
          <div className='flex gap-3 items-center pt-5'>
            <img src={Sun} alt=".." />
            <div>
              <p className='text-2xl text-[#C8CAD5]'>{formatTime(time)}</p>
              <p className='text-[#C8CAD5] text-xs'>Realtime Insight</p>
            </div>
          </div>
          <div>
            <p className='text-lg'>Today:</p>
            <p className='text-lg'>{formatDate(now)}</p>
          </div>
          <div className='flex justify-center'>
            <button onClick={()=> allApplicants()} className='bg-gradient-to-b from-[#8103CE] to-[#9F90FA] flex items-center justify-center py-2 text-white w-10/12 rounded-lg mb-4'>
              View Applicants
            </button>
          </div>
        </div>
        <div className='grid gap-5 '>
          <div className='bg-[#902dc8] text-white p-5 rounded-2xl h-full' onClick={()=> allApplicants()}>
            <div className='flex justify-between items-center'>
              <h1 className='text-[38px] font-extralight'> {candidates ? candidates.totalCount : <Spin />}</h1>
              <div className='bg-white bg-opacity-40 p-2 h-fit rounded-full cursor-pointer'>
                <LuUsers2 className='h-6 w-6 text-white' />
              </div>
            </div>
            <p className='mb-2'>Total applicants</p>
            <div className='flex items-center gap-3'>
              <div className='w-6 h-6 bg-white bg-opacity-20 text-white flex items-center justify-center rounded-full'>
                <span><IoMdAdd className='text-lg w-4 h-4 font-thin' /></span>
              </div>
              <span>0 new employees added!</span>
            </div>
          </div>
          <div className='bg-[#899E2B] text-white p-5 rounded-2xl h-full' onClick={() => allApplicants('completed', 'Accepted')}>
            <div className='flex justify-between items-center'>
              <h1 className='text-[38px] font-extralight'>  {candidates ? candidates.GREEN : <Spin />} </h1>
              <div className='bg-white bg-opacity-40 p-2 h-fit rounded-full cursor-pointer'>
                <TbClockX className='h-6 w-6 text-white' />
              </div>
            </div>
            <p className='mb-2'>Accepted</p>
            <div className='flex items-center gap-3'>
              <div className='w-6 h-6 bg-white bg-opacity-20 text-white flex items-center justify-center rounded-full'>
                <span><FaArrowTrendDown className='text-lg w-3 h-3 font-thin' /></span>
              </div>
              <span>0% Increase than yesterday</span>
            </div>
          </div>
        </div>
        <div className='grid gap-5 '>
          <div className='bg-[#675bd8] text-white p-5 rounded-2xl h-full' onClick={() => allApplicants('init')}>
            <div className='flex justify-between items-center'>
                <h1 className='text-[38px] font-extralight'>
                {candidates ? candidates.init : <Spin />} 
                 {/* {candidates ? (candidates.totalCount !== 0 ? `${((candidates.GREEN / candidates.totalCount) * 100).toFixed(0)}%` : '0%') : <Spin />} */}
                </h1>
              <div className='bg-white bg-opacity-40 p-2 h-fit rounded-full cursor-pointer'>
                <RxStopwatch className='h-6 w-6 text-white' />
              </div>
            </div>
            <p className='mb-2'>Initialized</p>
            <div className='flex items-center gap-3'>
              <div className='w-6 h-6 bg-white bg-opacity-20 text-white flex items-center justify-center rounded-full'>
                <span><IoMdAdd className='text-lg w-4 h-4 font-thin' /></span>
              </div>
              <span>0% Less than yesterday</span>
            </div>
          </div>
          <div className='bg-[#AA0000] text-white p-5 rounded-2xl h-full' onClick={() => allApplicants('completed','Rejected')}>
            <div className='flex justify-between items-center'>
              <h1 className='text-[38px] font-extralight'>  {candidates ? candidates.RED : <Spin />}</h1>
              <div className='bg-white bg-opacity-40 p-2 h-fit rounded-full cursor-pointer'>
                <IoMoonOutline className='h-6 w-6 text-white' />
              </div>
            </div>
            <p className='mb-2'>Rejected</p>
            <div className='flex items-center gap-3'>
              <div className='w-6 h-6 bg-white bg-opacity-20 text-white flex items-center justify-center rounded-full'>
                <span><IoMdAdd className='text-lg w-4 h-4 font-thin' /></span>
              </div>
              <span>0% Less than yesterday</span>
            </div>
          </div>
        </div>
        <div className='grid gap-5 '>
          <div className='bg-[#a8497b] text-white p-5 rounded-2xl h-full' onClick={() => allApplicants('onHold')}>
            <div className='flex justify-between items-center'>
              <h1 className='text-[38px] font-extralight'> {candidates ? candidates.onHold : <Spin />}</h1>
              <div className='bg-white bg-opacity-40 p-2 h-fit rounded-full cursor-pointer'>
                <BiErrorAlt className='h-6 w-6 text-white' />
              </div>
            </div>
            <p className='mb-2'>Error Analysis</p>
            <div className='flex items-center gap-3'>
              <div className='w-6 h-6 bg-white bg-opacity-20 text-white flex items-center justify-center rounded-full'>
                <span><FaArrowTrendDown className='text-lg w-3 h-3 font-thin' /></span>
              </div>
              <span>0% Increase than yesterday</span>
            </div>
          </div>
          <div className='bg-[#FA8F1E] text-white p-5 rounded-2xl h-full' onClick={() => allApplicants('pending')}>
            <div className='flex justify-between items-center'>
              <h1 className='text-[38px] font-extralight'> {candidates ? candidates.pending : <Spin />}</h1>
              <div className='bg-white bg-opacity-40 p-2 h-fit rounded-full cursor-pointer'>
                <LuCalendarClock className='h-6 w-6 text-white' />
              </div>
            </div>
            <p className='mb-2'>Pending</p>
            <div className='flex items-center gap-3'>
              <div className='w-6 h-6 bg-white bg-opacity-20 text-white flex items-center justify-center rounded-full'>
                <span><IoMdAdd className='text-lg w-4 h-4 font-thin' /></span>
              </div>
              <span>0% Increase than yesterday</span>
            </div>
          </div>
        </div>
      </div>
      <div className='grid items-center grid-cols-2 gap-6 max-sm:flex-col max-md:grid-cols-1'>
      {candidates ? (
        <>
          <Chart candidates={candidates} />
          <BarChart candidates={candidates} />
        </>
      ) : (
        <Spin size="large" />
      )}
    </div>
    </div>
  );
}
export default Dashboard
