// import React, { useState } from 'react';
// import Header from './Header/Header';
// import SidebarNav from './Sidebar/SidebarNav';
// import Dashboard from './Dashboard/Dashboard';
// import Applicant from './Applicant/Applicant';
// import Settings from './Settings/Settings';
// import ApplicantInfo from './ApplicantInfo/ApplicantInfo';

// function Layout() {
//     const [activeComponent, setActiveComponent] = useState('Dashboard');
//     const [applicantData, setApplicantData] = useState()

//     const handleMenuClick = (itemName) => {
//         setActiveComponent(itemName);
//     };
//     const handleData = (data) => {
//         setApplicantData(data);
//     };

//     return (
//         <div className='bg-[#F1F2F6] p-16 min-h-screen h-full'>
//             <Header />
//             <div className='gap-10' style={{ display: 'flex' }}>
//                 <SidebarNav onMenuClick={handleMenuClick} />
//                 <div style={{ flex: 1 }}>
//                     {activeComponent === 'Dashboard' && <Dashboard />}
//                     {activeComponent === 'Applicant' && <Applicant id={handleMenuClick} applicData={handleData} />}
//                     {activeComponent === 'ApplicantInfo' && <ApplicantInfo userInfo={applicantData} />}
//                     {activeComponent === 'Settings' && <Settings />}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Layout;


import React, { useState } from 'react';
import Header from './Header/Header';
import SidebarNav from './Sidebar/SidebarNav';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Applicant from './Applicant/Applicant';
import Settings from './Settings/Settings';
import ApplicantInfo from './ApplicantInfo/ApplicantInfo';

function Layout() {
    const [applicantData, setApplicantData] = useState()
    const handleData = (data) => {
        setApplicantData(data);
    };

    return (
        <div className='bg-[#F1F2F6] p-16 max-sm:p-5 max-md:p-8 min-h-screen h-full'>
            <Header />
            <div className='gap-10' style={{ display: 'flex' }}>
                <SidebarNav />
                <div style={{ flex: 1 }}>
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/applicant" element={<Applicant applicData={handleData} />} />
                        <Route path="/applicantinfo" element={<ApplicantInfo />} />
                        <Route path="/settings" element={<Settings />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default Layout;
