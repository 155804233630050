import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   setLogin: false,
//   setAccessToken: ''
// };
const initialState= ''

const tokenSlice = createSlice({
  name: 'accessToken',
  initialState,
  reducers: {
 
    accessToken: (state, action) => {
      state = action.payload;
      return state;
    }
  }
});

export const { accessToken } = tokenSlice.actions;
export default tokenSlice.reducer;
