import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Tabs } from "antd";
import Individual from "./Individual";
import { FiPlus } from "react-icons/fi";
import ApplicantInfo from "../ApplicantInfo/ApplicantInfo";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  useDisclosure,
  Select,
  SelectItem,
  Button,
  Snippet,
} from "@nextui-org/react";
import axios from "axios";
// import { levels } from './data'

const Applicant = ({ applicData }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [externalId, setExternalId] = useState("");
  const [showLink, setShowLink] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [link, setLink] = useState();
  const [level, setLevel] = useState(null);

  useEffect(() => {
    getApplicantLevel();
  }, []);
  const handleApplicantClick = async (data) => {
    applicData(data);
  };
  const handleSelectChange = (value) => {
    setSelectedValue(value);
    createApplicant(value);
  };
  const getApplicantLevel = async () => {
    try {
      const accessToken = localStorage.getItem("authToken");
      const response = await axios.get("/applicant/get-levels", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = response?.data?.list?.items?.map((item) => {
        return item.name;
      });
      setLevel(data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGenerateLink = () => {
    if (externalId) {
      try {
        const SDKlink = `${process.env.REACT_APP_SDK_LINK}/${externalId}/${selectedValue}`;
        setLink(SDKlink);
        setShowLink(true);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const createApplicant = async (level) => {
    const accessToken = localStorage.getItem("authToken");
    const response = await axios.post(
      `/applicant/create-applicant`,
      { levelName: level },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    setExternalId(response.data.externalUserId);
    return;
  };
  const handleNewApplicantClick = () => {
    setShowLink(false);
    setLink("");
    onOpen();
  };
  const operations = (
    <button
      onClick={handleNewApplicantClick}
      className="flex items-center py-3 px-5 bg-purple-600 text-white gap-2 font-bold text-sm rounded-lg max-md:py-2 max-md:px-3"
    >
      <FiPlus className="text-lg" /> New Applicant
    </button>
  );

  const items = [
    {
      key: "1",
      label: "Individual",
      children: (
        <Individual applicID={handleApplicantClick} levelValue={level} />
      ),
    },
    {
      key: "2",
      label: "Actions",
      children: "Action Page",
    },
  ];
  return (
    <div className="relative applicant-table">
      <Tabs tabBarExtraContent={operations} items={items} />

      <Modal
        placement="top"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="max-w-xl"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Create new applicant
              </ModalHeader>
              <ModalBody>
                <p className="mb-6">
                  Create new applicant manually or send the generated
                  varification link to the applicant
                </p>
                <Input
                  type="text"
                  size="s"
                  className=""
                  placeholder="Full Name"
                />
                <Select
                  labelPlacement="outside"
                  label="Select verification level"
                  placeholder="Select level"
                  className="max-w-full border rounded-lg"
                  size="sm"
                  value={selectedValue}
                  onChange={(e) => handleSelectChange(e.target.value)}
                  onClick={getApplicantLevel}
                >
                  {level ? (
                    level.map((value) => (
                      <SelectItem key={value}>{value}</SelectItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
                <p className="mb-4 text-sm">
                  To create or edit verification levels with adding extra steps
                  visit{" "}
                  <span className="text-[#259AEA] cursor-pointer">Levels</span>
                </p>
                {selectedValue ? (
                  <div>
                    <div className="mb-4">
                      <div className="flex items-center gap-2">
                        <div className="bg-[#aaa] p-2 rounded-full cursor-pointer"></div>
                        <span className="font-bold text-sm">
                          Identity document
                        </span>
                      </div>
                      <p className="text-xs text-[#aaa]">
                        ID card • Passport • Residence permit • Driver's license
                      </p>
                    </div>
                    <div>
                      <div className="flex items-center gap-2">
                        <div className="bg-[#aaa] p-2 rounded-full cursor-pointer"></div>
                        <span className="font-bold text-sm">Selfie</span>
                      </div>
                      <p className="text-xs text-[#aaa]">Selfie</p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="flex gap-8 justify-between mb-2">
                  <Button
                    isDisabled={!selectedValue}
                    onClick={handleGenerateLink}
                    color="primary"
                    radius="sm"
                  >
                    Generate WebSDK applicant link
                  </Button>
                  <Button className="border bg-white" radius="sm">
                    Create applicant manually
                  </Button>
                </div>
                {showLink ? (
                  <div className="w-full pb-4">
                    <Snippet className="w-full snippet-scroll">{link}</Snippet>
                  </div>
                ) : (
                  <></>
                )}
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Applicant;
