import React, { useEffect, useState } from "react";
import Logo from "../../images/trumeidtextlogo.png";
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { IoSearchOutline, IoMenu, IoNotifications } from "react-icons/io5";
import { Empty, Dropdown as AntDropdown } from "antd";
import { useDebouce } from "../Applicant/customHook";
import { Link, useNavigate } from "react-router-dom";
import { isLogin } from "../../Redux/reducers/slices/loginSlice";
import { FaArrowRight } from "react-icons/fa6";
import { useDispatch } from "react-redux";

const componentData = [
  {
    name: "Dashboard",
    subComponent: [
      {
        name: "total applicant",
      },
      {
        name: "accepted applicant",
      },
      {
        name: "rejected applicant",
      },
      {
        name: "pending",
      },
      {
        name: "verification distribution",
      },
      {
        name: "verification time trends",
      },
    ],
  },
  {
    name: "Applicant",
    subComponent: [
      {
        name: "individual",
      },
      {
        name: "applicant overview",
      },
    ],
  },
  {
    name: "Settings",
    subComponent: [
      {
        name: "Account",
      },
      {
        name: "business information",
      },
      {
        name: "legal Information",
      },
      {
        name: "change password",
      },
      {
        name: "dashboard language",
      },
    ],
  },
];
const Header = () => {
  const email = localStorage.getItem("user");
  const [quickSearch, setQuickSearch] = useState("");
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [suggestionData, setSuggestionData] = useState([]);
  const debounceSearch = useDebouce(quickSearch);
  const storedShowName = localStorage.getItem("name");
  const [showName, setShowName] = useState(storedShowName || "");
  const [showEmpty, setShowEmpty] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let timeoutId;

    if (quickSearch) {
      timeoutId = setTimeout(() => {
        setShowEmpty(true);
      }, 1000);
    } else {
      clearTimeout(timeoutId);
      setShowEmpty(false);
    }
    return () => clearTimeout(timeoutId);
  }, [quickSearch]);
  useEffect(() => {
    if (!showName && email) {
      setShowName(email?.split("@")[0]);
      localStorage.setItem("showName", email?.split("@")[0]);
    }
  }, [email, showName]);

  const navigate = useNavigate();

  useEffect(() => {
    const filterData = filterSearch(componentData, debounceSearch);
    setSuggestionData([]);

    if (filterData.length > 0) {
      setFilteredComponents(filterData);
    }
    if (debounceSearch !== "") {
      setSuggestionData(filterData);
    } else {
    }
    const handleBlur = () => {
      setSuggestionData([]);
    };

    document.addEventListener("click", handleBlur);

    return () => {
      document.removeEventListener("click", handleBlur);
    };
  }, [debounceSearch]);
  const handlePressEnter = (value, isSubMenu) => {
    setSuggestionData([]);

    if (value === "Dashboard") {
      navigate(`/`);
    } else {
      navigate(`/${value}`);
      setQuickSearch("");
    }
  };
  const filterSearch = (data, keyword) => {
    const lowerKeyword = keyword.toLowerCase();
    let filter;
    filter = data.filter((component) => {
      if (component.name.includes(lowerKeyword)) {
        return true;
      }

      const filterSubComponents = component.subComponent.filter(
        (subComponent) => {
          return subComponent.name.includes(lowerKeyword);
        }
      );
      return filterSubComponents.length > 0;
    });
    filter = filter.map((obj) => {
      const subComponent = obj.subComponent.filter((sub) =>
        sub.name.includes(lowerKeyword)
      );
      return {
        name: obj.name,
        subComponent: subComponent,
      };
    });
    return filter;
  };
  const handleLogout = () => {
    localStorage.clear();
    dispatch(isLogin(false));
    navigate("/");
  };
  const items = [
    {
      key: "1",
      label: (
        <div>
          <div className="relative flex items-center">
            <input
              type="text"
              value={quickSearch}
              onChange={(e) => setQuickSearch(e.target.value)}
              placeholder="Quick Search.."
              className="bg-[#F6F6F6] p-2 px-5 pl-10 rounded-lg outline-none"
              onClick={(e) => e.stopPropagation()}
            />
            <IoSearchOutline className="absolute left-3 text-[#9295AB]" />
          </div>
          {suggestionData.length > 0 ? (
            <ul className="absolute z-50 bg-white shadow-lg shadow-[#4d4d4d] max-h-64 overflow-y-scroll rounded-lg ">
              {suggestionData.map((component, index) => (
                <li
                  key={component.name}
                  className={`cursor-pointer p-4 ${index > 0 ? "mt-2" : ""}`}
                  onClick={() => handlePressEnter(component.name)}
                >
                  <span className="mb-2">{component.name}</span>
                  {component.subComponent && (
                    <ul className="ml-4 mt-2">
                      {component.subComponent.map((sub) => (
                        <li
                          key={sub.name}
                          className="text-gray-500 hover:text-blue-500 cursor-pointer pb-1 flex items-center gap-2"
                          onClick={() => handlePressEnter(sub.name, true)}
                        >
                          <FaArrowRight /> {sub.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <>
              {showEmpty && (
                <Empty className="absolute z-50 mt-2 bg-white border p-3 shadow-lg rounded-lg" />
              )}
            </>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: <span onClick={() => navigate("/")}>Dashboard</span>,
    },
    {
      key: "3",
      label: <span onClick={() => navigate("/applicant")}>Applicants</span>,
    },
    {
      key: "4",
      label: <span onClick={() => navigate("/settings")}>Settings</span>,
    },
    {
      key: "5",
      label: (
        <span className="text-[#AA0000]" onClick={handleLogout}>
          Logout
        </span>
      ),
    },
  ];
  return (
    <div className="flex justify-between items-center p-5 max-sm:p-4 max-md:p-4 bg-white rounded-lg mb-10 px-10 max-sm:px-7 max-md:px-7">
      <img src={Logo} alt=".." className="h-7 w-32 max-sm:w-18 max-md:w-24" />
      <div className="md:hidden">
        <AntDropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <IoMenu className="text-2xl" />
          </a>
        </AntDropdown>
      </div>
      <div className="flex items-center max-sm:hidden max-md:hidden">
        <div>
          <div>
            <div className="relative flex items-center">
              <input
                type="text"
                value={quickSearch}
                onChange={(e) => setQuickSearch(e.target.value)}
                placeholder="Quick Search.."
                className="bg-[#F6F6F6] p-2 px-5 pl-10 rounded-lg outline-none"
              />
              <IoSearchOutline className="absolute left-3 text-[#9295AB]" />
            </div>
            {suggestionData.length > 0 ? (
              <ul className="absolute z-50 bg-white shadow-lg shadow-[#4d4d4d] max-h-64 overflow-y-scroll rounded-lg ">
                {suggestionData.map((component, index) => (
                  <li
                    key={component.name}
                    className={`cursor-pointer p-4 ${index > 0 ? "mt-2" : ""}`}
                    onClick={() => handlePressEnter(component.name)}
                  >
                    <span className="mb-2">{component.name}</span>
                    {component.subComponent && (
                      <ul className="ml-4 mt-2">
                        {component.subComponent.map((sub) => (
                          <li
                            key={sub.name}
                            className="text-gray-500 hover:text-blue-500 cursor-pointer pb-1 flex items-center gap-2"
                            onClick={() => handlePressEnter(sub.name, true)}
                          >
                            <FaArrowRight /> {sub.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <>
                {showEmpty && (
                  <Empty className="absolute z-50 mt-2 bg-white border p-3 shadow-lg rounded-lg" />
                )}
              </>
            )}
          </div>
        </div>
        <div className="h-[30px] w-0 border border-[#F6F6F6] mx-5"></div>
        <div className="flex items-center gap-3">
          <Dropdown className="max-w-[130px] ">
            <DropdownTrigger>
              <div className="bg-[#CFD7FDA8] p-2 rounded-full cursor-pointer">
                <IoNotifications className="h-4 w-4 text-[#7182B6]" />
              </div>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              <DropdownItem key="empty">
                <Empty />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <div className="flex gap-2 items-center">
            <Avatar
              className="h-8 w-8"
              src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
            />
            <div className="text-sm">
              {showName ? (
                <>
                  <p>{showName}</p>
                  <p className="overflow-hidden">{email}</p>
                </>
              ) : (
                <>
                  <p>{email?.split("@")[0]}</p>
                  <p className="overflow-hidden">{email}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
