import { Spin, message } from "antd";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLogin } from "../../Redux/reducers/slices/loginSlice";

const OTPInput = ({ secret, qrUrl, email, setVerifyTwoFactor }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputs = useRef([]);
  // const [email, setEmail] = useState();
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);

  const handleChange = (index, value) => {
    if (value.length > 1) {
      let newOtp = [...otp];
      for (let i = 0; i < value.length; i++) {
        newOtp[i] = value.charAt(i);
        if (value.charAt(i) && i < newOtp.length - 1) {
          inputs.current[i + 1].focus();
        }
      }
      setOtp(newOtp);
    } else {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < newOtp.length - 1) {
        inputs.current[index + 1].focus();
      }

      if (!value && index && index < newOtp.length) {
        inputs.current[index - 1].focus();
      }
    }
  };
  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const sucessMsg = (msg) => {
    messageApi.open({
      type: "sucess",
      content: msg,
    });
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/auth/login/verify", {
        secret: secret,
        token: otp.join(""),
        email: email,
      });

      if (response?.data) {
        sucessMsg(response?.data?.data.message);
        dispatch(isLogin(true));
        localStorage.setItem("authToken", response.data.data.accessToken);
        localStorage.setItem("email", email);
        navigate("/");
        setVerifyTwoFactor(false);
      }
    } catch (error) {
      errorMsg("Authentication Failed!! Please Try Again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center gap-5 h-screen justify-center ">
      {contextHolder}

      <h1 className="text-3xl font-extrabold ">Two-factor Authentication</h1>
      <span className="text-[#525252] ">
        {qrUrl
          ? "Scan QR Code through Google Authenticator to get code'"
          : "Enter the code generated by Google Authenticator"}
      </span>

      {qrUrl && <img className=" mt:5 mb:5 " src={qrUrl} alt="qrcode" />}
      <div className="flex justify-center items-center mt:5 mb:5 space-x-4">
        {otp.map((_, index) => (
          <input
            key={index}
            type="text"
            maxLength="6"
            value={otp[index]}
            onChange={(e) => handleChange(index, e.target.value)}
            ref={(el) => (inputs.current[index] = el)}
            className="w-16 h-16 text-center text-4xl border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
        ))}
      </div>
      {Loading ? (
        <div className="flex justify-center mb-5">
          <Spin />
        </div>
      ) : (
        <button
          className="bg-purple-600 w-fit text-white rounded-md p-2 px-4 "
          onClick={handleSubmit}
        >
          Verify
        </button>
      )}
    </div>
  );
};

export default OTPInput;
