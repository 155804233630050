import React, { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Tooltip,
} from "@nextui-org/react";
import { Empty, Spin, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { FaSort } from "react-icons/fa6";
import { useDebouce } from "./customHook";
import { HiOutlineDocumentText, HiOutlineUser, HiPhone } from "react-icons/hi";
import { AiOutlineFile } from "react-icons/ai";
import { columns } from "./data";

const statusColorMap = {
  init: "primary",
  onHold: "secondary",
  pending: "warning",
  "Complete: Accepted": "success",
  "Complete: Rejected": "danger",
};

const documentIconMap = {
  PHONE_VERIFICATION: {
    icon: <HiPhone className="w-6 h-6" />,
    tooltip: "Phone Verification",
  },
  IDENTITY: {
    icon: <AiOutlineFile className="w-6 h-6" />,
    tooltip: "Identity Document",
  },
  SELFIE: {
    icon: <HiOutlineUser className="w-6 h-6" />,
    tooltip: "Selfie",
  },
  EMAIL_VERIFICATION: {
    icon: <HiOutlineDocumentText className="w-6 h-6" />,
    tooltip: "Email Verification",
  },
};

export default function App({
  applicantData,
  totalPages,
  getPageInfo,
  getLoading,
}) {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const debounceData = useDebouce(totalPages);
  const debounceApplicant = useDebouce(applicantData);
  const navigate = useNavigate();
  const handleApplicantSelect = (applicant) => {
    navigate("/applicantinfo", { state: { applicant } });
  };
  const [loading, setLoading] = useState(true);

  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    getPageInfo(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (getLoading || debounceApplicant.length === 0) {
      setLoading(true);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [getLoading, debounceApplicant]);

  useEffect(() => {
    if (debounceData < currentPage) {
      setCurrentPage(1);
    }
  }, [debounceData]);

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(columnName);
      setSortOrder("asc");
    }
  };

  const sortedData = () => {
    let sorted = [...debounceApplicant];

    if (sortedColumn) {
      sorted = sorted.sort((a, b) => {
        const valueA = a[sortedColumn];
        const valueB = b[sortedColumn];

        if (sortOrder === "asc") {
          return typeof valueA === "string"
            ? valueA.localeCompare(valueB)
            : valueA - valueB;
        } else {
          return typeof valueB === "string"
            ? valueB.localeCompare(valueA)
            : valueB - valueA;
        }
      });
    }

    return sorted;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderCell = React.useCallback((user, columnKey) => {
    let cellValue = columnKey === "createdDate" ? new Date(user.createdDate).toLocaleDateString() : user[columnKey];
    if (columnKey === "status" && cellValue === "init") {
      cellValue = `Document Requested ${user.time}`;
    }
    if (columnKey === "status" && cellValue === "onHold") {
      cellValue =  `Requires Action ${user.time}`;
    }if (columnKey === "status" && cellValue === "pending") {
      cellValue =  `Pending ${user.time}`;
    }
    if (columnKey === "status" && cellValue === "Complete: Accepted") {
      cellValue =  `Approved ${user.time}`;
    }
    if (columnKey === "status" && cellValue === "Complete: Rejected") {
      cellValue =  `Rejected ${user.time}`;
    }

    switch (columnKey) {
      case "name":
        return (
          <div>
            {user.name && user.name !== "unknown" ? (
              <>
                <p className="font-bold">{user.name}</p>
                <p className="text-gray-500 text-[12px]">ID: {user.id}</p>
                <p className="text-gray-500 text-[12px]">
                  Created Date: {user.date}
                </p>
              </>
            ) : (
              <>
                <p className="font-bold">ID: {user.id}</p>
                <p className="text-gray-500 text-[12px]">
                  Created Date: {user.date}
                </p>
              </>
            )}
          </div>
        );
      case "document":
        const documents = user.document.split(", ").map(doc => doc.trim());
        return (
          <div className="flex flex-col gap-2">
              <div className="flex gap-3">
              {documents.map((document, index) => (
                    <React.Fragment key={index}>
                      {documentIconMap[document] ? (
                        <Tooltip content={documentIconMap[document].tooltip}>
                          {documentIconMap[document].icon}
                        </Tooltip>
                      ) : (
                        <>
                          <HiOutlineDocumentText className="w-6 h-6" />
                          <span className="ml-2">{document}</span>
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </div>
              <div className="">
                Level: {user.level}
              </div>
          </div>
        );
      case "status":
        return (
          <Chip
            className="capitalize"
            color={statusColorMap[user.status]}
            size="sm"
            variant="flat"
          >
            {cellValue}
          </Chip>
        );

      default:
        return cellValue;
    }
  }, []);

  return (
    <div className="bg-white p-4 rounded-lg">
      <h1 className="p-2 px-4 text-xl">Applicant Overview</h1>
      {loading ? (
        <div className="py-20 flex justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {debounceApplicant.length === 0 ? (
            <div className="py-20 flex justify-center">
              <Empty />
            </div>
          ) : (
            <>
              <Table
                disabledKeys={sortedData()
                  .filter((item) => item.deleted)
                  .map((item) => item.id)}
                shadow="none"
                aria-label="Example table with custom cells"
                className="overflow-x-scroll max-w-[85vw]"
              >
                <TableHeader columns={columns}>
                  {(column) => (
                    <TableColumn
                      onClick={() => handleSort(column.uid)}
                      className="bg-white border-y-1 border-b-3 border-[#e9ebed] cursor-pointer"
                      key={column.uid}
                    >
                      <div className="flex gap-1 items-center">
                        <span>{column.name}</span>{" "}
                        <span className="">
                          <FaSort />
                        </span>
                      </div>
                    </TableColumn>
                  )}
                </TableHeader>
                <TableBody>
                  {sortedData()
                    .slice(0)
                    .reverse()
                    .map((item) => (
                      <TableRow
                        className={`border-b-1 border-[#D5D9DD] cursor-pointer last:border-b-3 last:border-[#e9ebed] hover:bg-[#f9f2fc]`}
                        key={item.id}
                        onClick={() => handleApplicantSelect(item)}
                        disabled={item.deleted}
                      >
                        {(columnKey) => (
                          <TableCell className="py-4 bg-transparent">
                            {renderCell(item, columnKey)}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </>
          )}
        </>
      )}

      <div className="flex justify-between mt-4">
        <span>
          Page {currentPage} of {totalPages !== 0 ? Math.ceil(totalPages) : 1}
        </span>
        <div className="flex gap-4 items-center ">
          <span className="font-bold">Page:</span>
          <Pagination
            defaultCurrent={1}
            current={currentPage}
            total={totalPages * 10}
            pageSize={pageSize}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}