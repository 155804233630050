
const columns = [
  { name: "Name", uid: "name" },
  { name: "Required Document", uid: "document" },
  { name: "STATUS", uid: "status" },
  { name: "Tags", uid: "tags" },
];

const users = [
  {
    id: 2341421,
    name: "Ali Alhamdan",
    date: "29 July 2023",
    document: "Document",
    level: "Basic-kyc-level",
    status: 'Require_Action',
    tags: "documents",
  },
  {
    id: 2341421,
    name: "Ali ",
    date: "29 July 2023",
    document: "Document",
    level: "Basic-kyc-level",
    status: 'Rejected',
    tags: "documents",
  },
  {
    id: 2341421,
    name: "Hunzala",
    date: "29 July 2023",
    document: "Document",
    level: "Basic-kyc-level",
    status: 'Document_Requested',
    tags: "documents",
  },
  {
    id: 2341421,
    name: "Ali Alhamdan",
    date: "29 July 2023",
    document: "Document",
    level: "Basic-kyc-level",
    status: 'Require_Action',
    tags: "documents",
  },
  {
    id: 2341421,
    name: "Ali Alhamdan",
    date: "29 July 2023",
    document: "Document",
    level: "Basic-kyc-level",
    status: 'pending',
    tags: "documents",
  },
  {
    id: 2341421,
    name: "Ali Alhamdan",
    date: "29 July 2023",
    document: "Document",
    level: "Basic-kyc-level",
    status: 'Require_Action',
    tags: "documents",
  },
];
const levels = [
  { label: "basic-kyc-level", value: "basic" },
  { label: "US BACKGROUND", value: "us" },
  { label: "TruMeiD Main", value: "trumeid" },
  { label: "PAYPR-TEST", value: "payrp" },
  { label: "CIBC DEMO", value: "cibc" },
  { label: "TT UTC", value: "utc" },
  { label: "TF Demo", value: "tf" },
]

export { columns, users, levels };
