import React, { useEffect, useState } from "react";
import { IoDocumentTextOutline, IoPhonePortraitOutline } from "react-icons/io5";
import { LuClock3 } from "react-icons/lu";
import {
  BsFillCheckCircleFill,
  BsFillQuestionCircleFill,
} from "react-icons/bs";
import Logo from "../../img/logo.avif";
import { useLocation } from "react-router";
import html2pdf from "html2pdf.js";
import { RxCross1, RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import { MdError } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import { FaRegCheckCircle } from "react-icons/fa";
const Summary = () => {
  const [date, setDate] = useState("");
  const location = useLocation();
  const { applicant, events } = location.state;
  const [faceLiveness, setFaceLiveness] = useState("");
  const [faceMatch, setFaceMatch] = useState("");
  const [status, setStatus] = useState("");
  const [rejectionLabels, setRejectionLabels] = useState([]);
  const [docStatus, setDocStatus] = useState(false);
  useEffect(() => {
    console.log("rejectionLabels", docStatus);
  }, [docStatus]);
  useEffect(() => {
    getCurrentDate();
    getSummaryData();
  }, []);
  const reviewCheck = [
    "Images quality",
    "Security fetures",
    "Template",
    "Font",
  ];

  const getSummaryData = async () => {
    try {
      const summaryData = await axios.get(`/applicant/summary/${applicant.id}`);
      console.log(summaryData.data);

      summaryData.data.checks.FACE_LIVELINESS
        ? setFaceLiveness(summaryData.data.checks.FACE_LIVELINESS.answer)
        : setFaceLiveness("");
      summaryData.data.checks.FACE_MATCH
        ? setFaceMatch(summaryData.data.checks.FACE_MATCH.answer)
        : setFaceMatch("");
      if (summaryData.data.applicant.review.reviewStatus) {
        if (summaryData.data.applicant.review.reviewResult) {
          setStatus(
            summaryData.data.applicant.review.reviewResult.reviewAnswer
          );
          if (summaryData.data.applicant.review.reviewResult.rejectLabels) {
            setRejectionLabels(
              summaryData.data.applicant.review.reviewResult.rejectLabels
            );
          } else {
            setRejectionLabels([]);
          }
        } else {
          setStatus(summaryData.data.applicant.review.reviewStatus);
        }
      }
      if (
        summaryData.data.requiredIdDocsStatus.IDENTITY ||
        summaryData.data.requiredIdDocsStatus.SELFIE
      ) {
        setDocStatus(true);
      } else {
        setDocStatus(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentDate = () => {
    let dateNow = `${new Date()}`;
    dateNow = dateNow?.split(" ");
    const d = dateNow.slice(1, 4).join(" ");
    setDate(d);
  };

  const generatePDF = () => {
    const element = document.getElementById("elementToConvert"); // Replace 'elementToConvert' with the ID of the element you want to convert to PDF
    const opt = {
      margin: 0.5,
      filename: `${applicant.id}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(element).set(opt).save();
  };

  return (
    <div className="bg-[#F1F2F6] flex justify-center">
      <div className="bg-white w-[60%] h-full p-5">
        <div className="flex items-center justify-between mb-4">
          <img src={Logo} alt="Logo.." className="w-16" />
          <span>Online Screening Report</span>
        </div>
        <div className="flex justify-between">
          <div></div>
          <button
            className=" flex items-center gap-1 bg-[#19bc9b] py-2 px-3 text-white rounded text-sm"
            onClick={generatePDF}
          >
            <IoDocumentTextOutline /> Get PDF
          </button>
        </div>
        <div id="elementToConvert">
          <div className="mb-8">
            <div className="flex gap-3 items-center">
              <span className="font-thin italic text-xl">
                Inspection report for {date}
              </span>
            </div>
            <p className="text-[#909090]">Created for TruMeiD Trust Inc.</p>
          </div>

          <div className="grid grid-cols-3 gap-3 items-center pb-2 mb-4">
            <span className="col-span-1 font-bold">Verification Status</span>
            {status === "GREEN" ? (
              <div className="col-span-2 pb-4 border-b-1 border-[#e5e5e5] flex gap-2 items-center">
                <FaRegCheckCircle className="text-green-500 text-5xl" />
                <span className="font-bold text-lg">ACCEPTED</span>
              </div>
            ) : status === "RED" ? (
              <div className="col-span-2 pb-4 border-b-1 border-[#e5e5e5] flex gap-2 items-center">
                <VscError className="text-red-500 text-5xl" />
                <span className="font-bold text-lg">DECLINE</span>
              </div>
            ) : (
              <div className="col-span-2 pb-4 border-b-1 border-[#e5e5e5] flex gap-2 items-center">
                <LuClock3 className="text-[#eea70d] text-5xl" />
                <span className="font-bold text-lg">In Progress...</span>
              </div>
            )}
          </div>
          <div className="grid grid-cols-3 gap-3 mb-8">
            <span className="col-span-1 font-bold">Key findings</span>
            <div className="col-span-2 border-b-1 border-[#e5e5e5] pb-4">
              <div className="flex mb-2 items-center gap-1">
                {status === "GREEN" ? (
                  <FaRegCheckCircle className="text-green-500 " />
                ) : status === "RED" ? (
                  <FaRegCheckCircle className="text-green-500 " />
                ) : (
                  <LuClock3 className="text-[#eea70d]" />
                )}
                <span className="font-semibold">Document Review Check</span>
              </div>
              {reviewCheck.map((data) => {
                return (
                  <div className="flex ml-4 mb-1 items-center gap-1">
                    {status === "GREEN" ? (
                      <FaRegCheckCircle className="text-green-500 " />
                    ) : status === "RED" ? (
                      <FaRegCheckCircle className="text-green-500 " />
                    ) : (
                      <LuClock3 className="text-[#eea70d]" />
                    )}
                    <span className="">{data}</span>
                  </div>
                );
              })}

              {/* <div className="flex ml-4 mb-1 items-center gap-1">
                <BsFillQuestionCircleFill className="text-[#259aea]" />
                <span className="">Security features</span>
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                <BsFillQuestionCircleFill className="text-[#259aea]" />
                <span className="">Template</span>
              </div>
              <div className="flex ml-4 mb-2 items-center gap-1">
                <BsFillQuestionCircleFill className="text-[#259aea]" />
                <span className="">Fonts</span>
              </div> */}
              <div className="flex mb-6 items-center gap-1">
                {rejectionLabels.includes("FRAUDULENT_PATTERNS") ? (
                  <RxCrossCircled className="text-red-500 font-extrabold" />
                ) : (
                  <BsFillQuestionCircleFill className="text-[#259aea]" />
                )}{" "}
                <span className="font-semibold">Fraudulent Patterns</span>
              </div>

              <div className="flex mb-2 items-center gap-1">
                {rejectionLabels.includes("PROBLEMATIC_APPLICANT_DATA") ||
                rejectionLabels.includes("INCONSISTENT_PROFILE") ? (
                  <RxCrossCircled className="text-red-500 font-extrabold" />
                ) : (
                  <BsFillQuestionCircleFill className="text-[#259aea]" />
                )}
                <span className="font-semibold">Data Validation</span>
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                {docStatus ? (
                  <>
                    <BsFillCheckCircleFill className="text-[#19bc9b]" />
                    <span className="">
                      Document has not been recorded as expired, lost, stolen or
                      compromised
                    </span>
                  </>
                ) : (
                  <>
                    <BsFillQuestionCircleFill className="text-[#259aea]" />
                    <span>Document has not been verified yet</span>
                  </>
                )}
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                {/* {rejectionLabels.includes("BLACKLIST") ? (
                  <RxCrossCircled className="text-red-500 font-extrabold" />
                ) : (
                  <BsFillQuestionCircleFill className="text-[#259aea]" />
                )} */}

                {docStatus ? (
                  rejectionLabels.includes("BLACKLIST") ? (
                    <>
                      <RxCrossCircled className="text-red-500 font-extrabold" />
                      <span className="">
                        Document hasn't been found on our blocklist
                      </span>
                    </>
                  ) : (
                    <>
                      <BsFillCheckCircleFill className="text-[#19bc9b]" />
                      <span className="">
                        Document hasn't been found on our blocklist
                      </span>
                    </>
                  )
                ) : (
                  <>
                    <BsFillQuestionCircleFill className="text-[#259aea]" />
                    <span>Document has not been verified yet</span>
                  </>
                )}
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                {rejectionLabels.includes("PROBLEMATIC_APPLICANT_DATA") ||
                rejectionLabels.includes("INCONSISTENT_PROFILE") ? (
                  <RxCrossCircled className="text-red-500 font-extrabold" />
                ) : (
                  <BsFillQuestionCircleFill className="text-[#259aea]" />
                )}
                <span className="">Data comparison</span>
              </div>

              <div className="flex mb-2 items-center gap-1">
                {rejectionLabels.includes("COMPROMISED_PERSONS") ? (
                  <RxCrossCircled className="text-red-500 font-extrabold" />
                ) : (
                  <BsFillQuestionCircleFill className="text-[#259aea]" />
                )}

                <span className="font-semibold">
                  Compromised persons / organizations
                </span>
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                <BsFillQuestionCircleFill className="text-[#259aea]" />
                <span className="">Warnings</span>
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                {rejectionLabels.includes("SANCTIONS") ? (
                  <RxCrossCircled className="text-red-500" />
                ) : (
                  <BsFillQuestionCircleFill className="text-[#259aea]" />
                )}
                <span className="">Sanctions</span>
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                {rejectionLabels.includes("PEP") ? (
                  <RxCrossCircled className="text-red-500" />
                ) : (
                  <BsFillQuestionCircleFill className="text-[#259aea]" />
                )}
                <span className="">Politically exposed persons (PEP)</span>
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                {rejectionLabels.includes("ADVERSE_MEDIA") ? (
                  <RxCrossCircled className="text-red-500" />
                ) : (
                  <BsFillQuestionCircleFill className="text-[#259aea]" />
                )}
                <span className="">Adverse media</span>
              </div>

              <div className="flex mb-2 items-center gap-1">
                <BsFillQuestionCircleFill className="text-[#259aea]" />
                <span className="font-semibold">Facial Check</span>
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                <BsFillQuestionCircleFill className="text-[#259aea]" />
                <span className="">Face detection</span>
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                {faceMatch === "GREEN" ? (
                  <BsFillCheckCircleFill className="text-[#19bc9b]" />
                ) : rejectionLabels.includes("SELFIE_MISMATCH") ? (
                  <RxCrossCircled className="text-red-500" />
                ) : faceMatch === "ERROR" ? (
                  <MdError className="text-black" />
                ) : (
                  <BsFillQuestionCircleFill className="text-[#259aea]" />
                )}
                <span className="">Face match</span>
              </div>
              <div className="flex ml-4 mb-1 items-center gap-1">
                {faceLiveness === "GREEN" ? (
                  <BsFillCheckCircleFill className="text-[#19bc9b]" />
                ) : faceLiveness === "RED" ? (
                  <RxCrossCircled className="text-red-500" />
                ) : faceLiveness === "ERROR" ? (
                  <MdError className="text-black" />
                ) : (
                  <BsFillQuestionCircleFill className="text-[#259aea]" />
                )}{" "}
                <span className="">Face Liveness</span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3 mb-8">
            <span className="col-span-1 font-bold">Profile data</span>
            <div className="col-span-2 border-b-1 border-[#e5e5e5] pb-4">
              <div className="flex items-center gap-1">
                <span className="text-[#909090]">ID:</span>
                <p>{applicant.id}</p>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-[#909090]">Created for:</span>
                <p>TruMeiD Trust Inc.</p>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-[#909090]">Profile created:</span>
                <p>{applicant.date}</p>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-[#909090]">Last screened:</span>
                <p>January 01, 2024</p>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-[#909090]">Ongoing monitoring:</span>
                <p>Not active</p>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-[#909090]">Requested documents:</span>
                {applicant.document.map((data) => {
                  return <p>{data}</p>;
                })}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-3 mb-8">
            <span className="col-span-1 font-bold">Events</span>
            <div className="col-span-2 border-b-1 border-[#e5e5e5] pb-4">
              {events ? (
                events.map((event) => {
                  event.type =
                    event.type === "applicantCreated"
                      ? "Applicant Initialized"
                      : event.type;
                  event.type =
                    event.type === "applicantPending" ? "Pending" : event.type;
                  event.type =
                    event.type === "applicantReset"
                      ? "Reset Applicant"
                      : event.type;

                  return (
                    <div className="flex gap-8 items-center justify-between px-10 pb-8">
                      <div className="flex gap-2 items-center">
                        <div className="w-3 h-3 bg-[#AAAAAA] rounded-full"></div>
                        <div>
                          <span className="font-semibold">{event.type}</span>
                          <p className="text-[#6b758b99]">
                            Level name: event.levelName
                          </p>
                        </div>
                      </div>
                      <div className="text-[#6b758b99]">
                        <span>Applicant:</span>
                        <p>{event.date}</p>
                      </div>
                      <div className="flex gap-2">
                        <IoPhonePortraitOutline className="mt-1" />
                        <div>
                          <p>iOS — Safari</p>
                          <p>Trinidad and Tobago,Chaguanas</p>
                          <p>200.7.90.148</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
