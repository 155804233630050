import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Login from "./components/Login/Login";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { isLogin } from "./Redux/reducers/slices/loginSlice";
import Summary from "./components/summary/Summary";
import { Spin } from "antd";
import SignUp from "./components/Login/SignUp";

const checkAuth = async () => {
  const authToken = localStorage.getItem("authToken");
  try {
    const verifyAuth = await axios.get("/session", {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return verifyAuth.data?.email ? true : false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

function App() {
  const [loading, setLoading] = useState(true);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, [dispatch]);

  const getData = async () => {
    const check = await checkAuth();
    dispatch(isLogin(check));
    setLoading(false);
  };

    if (loading) {
      return (
        <div className="flex items-center justify-center h-screen">
          <Spin size="large" />
        </div>
      );
    }

  return (
    <div className="App">
      <Router>
        <Routes>
          {!login && <Route path="/*" element={<Login />} />}
          {!login &&   <Route path="/signup" element={<SignUp />} />}
          {login && (
            <>
              <Route path="/*" element={ <Layout />} />
              <Route path="/summary" element={<Summary />} />
            </>
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
