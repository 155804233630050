import React from 'react';
import ReactApexChart from 'react-apexcharts';

const FunctionalApexChart = ({ candidates }) => {
  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758']
      }
    },
    xaxis: {
      categories: ['Total', 'Rejected', 'Accepted', 'Pending', 'Initial', 'OnHold'],
    },
    colors: ["#8103CE", "#FF4560", "#008FFB", "#00E396", "#FEB019", "#FF8D72", "#414141"],
    yaxis: {
      title: {
        text: 'Counts',
      },
    },
  };

  const seriesData = [{
    data: [candidates.totalCount, candidates.RED, candidates.GREEN, candidates.pending, candidates.init, candidates.onHold]
  }];

  return (
    <div className='bg-white max-sm:w-full max-md:w-full rounded-2xl p-5' id="chart">
      <div className='flex justify-between px-5 pt-5 mb-5 items-center'>
        <h1 className='text-lg max-sm:text-base max-md:text-base'>Bar Chart of Appliants Detail</h1>
      </div>
      <ReactApexChart options={chartOptions} series={seriesData} type="bar" height={350} />
    </div>
  );
};

export default FunctionalApexChart;
