import React from 'react'

const Legal = () => {
  return (
    <div className='bg-white p-5 min-h-full'>
        <p className='font-bold mb-5'>THIRD-PARTY PROCESSORS AND AFFILIATES</p>
        <p className='font-bold mb-5'>Service Delivery</p>
        <p className='font-bold mb-5'>October 26, 2023</p>
        <p className='mb-5'>TrumeiD and its affiliates and subsidiaries (collectively forming the Group of Companies) may involve third-party service providers to facilitate TrumeiD with limited data processing activities in connection with TrumeiD Services and Business operations.</p>
        <p className='mb-5'>When TrumeiD works with these service providers in its capacity as a data processor, the third-party service provider is a sub-processor of TrumeiD ("Sub-processor"). These Sub-processors may have access to personal data provided directly by TrumeiD Clients' applicants or by Clients themselves or to which TrumeiD may have access in order to perform the contracted services and business activities.</p>
        <p className='mb-5'>The List below contains the currently engaged Sub-processors entities and what activity each entity performs and indicates if an entity is only relevant to a specific Service. More information about each activity is provided directly below. This explains the limited processing of personal data the entity is authorised to perform.</p>
        <p className='font-bold mb-5'>Service delivery and support</p>
        <p className='mb-2'>Not all of them will be relevant to how the Client accesses and uses our Services, and we've set out some details below. If you're unsure, please check the terms of your agreement with us or get</p>
        <p className='mb-5'>in touch with your account manager. If you don't have one, reach us via 
        {/* <span className='text-[#259AEA] cursor-pointer'>business-support@TrumeiD.com.</span> */}
        </p>
    </div>
  )
}

export default Legal